<form #Xform="ngForm" class="m-auto auth auth-background">
  <div class="container-fluid">
    <div class="row pi-login  justify-content-center align-items-center">
      <div class="col-md-12 d-flex justify-content-center mt-5">
        <div class="header-w3l">
          <div class="logo ml-2">
            <h3><a href="#">{{ 'COMMON.TITLE' | translate }}</a></h3>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        
         
        <!-- <div class=" d-flex align-items-center back-arrow cursor-pointer"  >
         
          <div class="m3u-header d-flex justify-content-start" >
          </div>
      </div> -->
          <div class="col-md-12 d-flex arrs">
            <a [routerLink]="'/add-playlist'" class="d-flex back justify-content-start">
              <i class="fa fa-arrow-left" ></i>
            </a> 
              <div class="m3u-header d-flex justify-content-end">
                  <h3>{{'COMMON.XSTREAM_LOGIN' | translate}}</h3>
              </div>
          </div>

          <div class="col-md-12 col-sm-3 col-lg-12">
              <div class="playlist-name mt-3 " [ngClass]="{'mb-0': Xform.submitted && xplaylist.invalid}" >
                  <label for="">Playlist Name</label>
                  <input type="text" class="form-control in" placeholder="enter your playlist name" #xplaylist="ngModel"
                  [(ngModel)]="playListModal.name" name="xplaylist" >
              </div>
              <div *ngIf="Xform.submitted && xplaylist.invalid" class="">
                <p class="error-msg mb-0 fs-14">{{ 'FORMERROR.PLAYLIST_NAME' | translate }}</p>
              </div>

              <div class="playlist-name mt-3" [ngClass]="{'mb-0': Xform.submitted && xuname.invalid}">
                  <label for="">{{'COMMON.XSTREAM_USER_NAME' | translate}}<sup>*</sup></label>
                  <input type="text" class="form-control in" id="uname" placeholder="enter your user name" #xuname="ngModel" [(ngModel)]="playListModal.username" name="xuname" required>
              </div>
              <div *ngIf="Xform.submitted && xuname.invalid" class="">
                <p class="error-msg mb-0 fs-14">{{ 'FORMERROR.USERNAME' | translate }}</p>
              </div>
              <div class="playlist-name mt-3" [ngClass]="{'mb-0': Xform.submitted && xpassword.invalid}">
                <label for="">{{'COMMON.XSTREAM_USER_PASSWORD' | translate}}<sup>*</sup></label>
                <input [type]="type" class="form-control in" id="password" placeholder="enter your password" #xpassword="ngModel" [(ngModel)]="playListModal.password" name="xpassword" maxlength="16" required>
                <div class="input-append cursor-pointer text-white" (click)="hideShowPassword()">
                  <i class="fas fa-eye" *ngIf="type == 'password'"></i>
                  <i class="fas fa-eye-slash" *ngIf="type!== 'password'"></i>
                </div>
              </div>
              <div *ngIf="Xform.submitted && xpassword.invalid" class="">
                <p class="error-msg mb-0 fs-14">{{ 'FORMERROR.PASSWORD' | translate }}</p>
              </div>
              <div class="playlist-name mt-3" [ngClass]="{'mb-0': Xform.submitted && url.invalid}">
                  <label for="">{{'COMMON.Xstream_URL' | translate}}<sup>*</sup></label>
                  <input type="text" class="form-control in" id="url" placeholder="enter your URL" placeholder="http://url_here.com:port" #url="ngModel"
                  [(ngModel)]="playListModal.url" name="url" [pattern]="url_pattern" required>
              </div>
              <div *ngIf="Xform.submitted && url.invalid" class="">
                <p class="error-msg mb-0 fs-14" *ngIf="url.errors.required">{{ 'FORMERROR.PLAYLIST_URL' | translate }}</p>
                <p class="error-msg mb-0 fs-14" *ngIf="Xform.controls.url.errors.pattern">
                  {{ 'FORMERROR.VALID_URL' | translate }}</p>
              </div>
              <div class="row mt-5">
                
                <div class="col-md-12 d-flex justify-content-center">
                  <div class="add-btn justify-content-center">
                    <!-- (click)="addPlaylistXstream(Xform)" -->
                      <button class="btn btn-color justify-content-center" (click)="addPlaylistXstream(Xform)" >
                       Submit
                      </button>
                  </div>
                    <!-- <div class="add-btn ml-5">
                        <button class="btn btn-color">
                          {{'BUTTON.SUBMIT_BTN' | translate}}
                        </button>
                    </div> -->
              </div>
              
              </div>
          </div>
      </div>
      
    </div>
  </div>
</form>