import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Constant } from 'src/app/Core/services/data';
import { SeriesService } from 'src/app/Core/services/series.service';
import { UtilService } from 'src/app/Core/services/util.service';

@Component({
  selector: 'app-search-series',
  templateUrl: './search-series.component.html',
  styleUrls: ['./search-series.component.css']
})
export class SearchSeriesComponent implements OnInit {
  @Input() seriesShowList: any;
  @Input() searchSeriesTerm: any;

  isLoading: any;
  isCategory = true;
  categories: any;
  selectedcategory: any;
  filterTerm: string;
  searchedSeries: any;
  filteredSeriesDetailList: any = [];
  seasonId: any = 1;
  slideConfig = {
    "slidesToShow": 7, "slidesToScroll": 7, responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  apiIndex = 0;
  isSort: boolean = true;
  ascDesc: string = 'asc';

  constructor(
    private seriesService: SeriesService,
    private utilService: UtilService,
    private route: Router,
    private changeDetectRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

  }
  searchSeriesList() {
    if (this.seriesShowList && this.searchSeriesTerm) {
      this.searchedSeries = _.cloneDeep(this.seriesShowList);
      this.searchedSeries = _.filter(this.searchedSeries, ((search: any) => {
        return search.name.toLowerCase().indexOf(this.searchSeriesTerm.toLowerCase()) > -1
      }));

      this.getSeason(this.searchedSeries);
    }
  }



  getSeries(series, index) {
    this.filteredSeriesDetailList = series.map(data => {
      return {
        info: data
      };
    });
    // this.seriesService.getSeriesDetail(series[index].series_id).subscribe((res: any) => {
    //   res.series_id = series[index].series_id;
    //   this.filteredSeriesDetailList.push(res);
    //   this.manageIndex(series, index);
    //   // if ((index + 1) < series.length) {
    //   //   this.getSeries(series, index + 1);
    //   // } else {
    //   //   this.favouriteManage();
    //   // }
    // }, err => {
    //   this.manageIndex(series, index);
    // });
  }

  gotoDetail(id) {

    let passModal: any = {
      type: 'Series',
      sub_category: 'default'
    };
    this.utilService.addSorting(passModal);
    this.route.navigate(['/series/info/' + id], { queryParams: { seriesBy: 'series' } });
  }

  manageIndex(series, index) {
    if ((index + 1) < series.length) {
      this.getSeries(series, index + 1);
    } else {
      this.favouriteManage();
    }

  }
  getSeason(series: any) {
    this.filteredSeriesDetailList = [];
    this.apiIndex = 1;
    if (series.length > 0) {
      this.getSeries(series, 0);
    }
  }

  favouriteManage() {
    let savedArray;
    savedArray = JSON.parse(localStorage.getItem(Constant.SERIES_FAV)) || [];
    if (this.filteredSeriesDetailList.length > 0) {
      this.filteredSeriesDetailList.map((ele: any, index) => {
        // if (ele.seasons.length > 0) {
        //   ele.seasons[0].selected = true;
        // }

        savedArray.map((eleStorage: any) => {
          if (eleStorage) {
            if (ele.series_id == eleStorage.id) {
              ele.seasons[0].isFavourit = true;
            }
          }
        })
        return ele;
      });

    }
  }
  // onSelectSeason(seasonId: any) {
  //   this.filteredSeriesDetailList.map((ele: any) => {
  //     ele.seasons.map((ses: any) => {
  //       if (ses.id == seasonId) {
  //         ses.selected = true;
  //         this.seasonId = ses.season_number
  //       }
  //       else {
  //         ses.selected = false;
  //       }
  //       return ses;
  //     });
  //     return ele;
  //   });
  //   this.changeDetectRef.detectChanges();
  // }

  sortSeries() {
    this.isSort = !this.isSort;
    (this.isSort) ? this.ascDesc = 'desc' : this.ascDesc = 'asc';
    this.utilService.tabWiseSearchSorting('', 'only_series', this.filteredSeriesDetailList, (callback) => {
      this.filteredSeriesDetailList = callback;
    });
  }
}
