import { Component, Input, OnInit } from '@angular/core';
import { event } from 'jquery';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-all-settings',
  templateUrl: './all-settings.component.html',
  styleUrls: ['./all-settings.component.css']
})
export class AllSettingsComponent implements OnInit {
  @Input() color: any

  settingType: string = 'user_account';
  listOfSettings = [
    { name: 'User A/C Info', value: 'user_account', icon: 'fa fa-user', selected: false },
    { name: 'Change Language', value: 'change_language', icon: 'fa fa-language', selected: false },
    { name: 'Change Color', value: 'color', selected: false }
  ]
  colorTheme = [{
    'color': 'black',
    selected: false
  },
  {
    'color': 'purple',
    selected: true
  },
  {
    'color': 'brown',
    selected: false
  }
  ]
  themeColor: string = 'purple'
  constructor(private themeService: ThemeService) {
    // this.color=localStorage.getItem('color')
  }

  ngOnInit(): void {
    this.themeService.themeColorChanged.subscribe((color: string) => {
      this.themeColor = color;
    });
    this.onChangeColor(event)
    this.listOfSettings.map((item, index) => {
      (index == 0) ? item.selected = true : item.selected = false;
    });
  }

  changeSettingType(type: string, id) {
    this.settingType = type;
    this.listOfSettings.map((item, index) => {
      (index == id) ? item.selected = true : item.selected = false;
    });
  }

  onChangeColor(event: any) {
    if (event.target.value) {
      event.target.style.background = event.target.value
      this.color = event.target.value
      // localStorage.setItem('color', event.target.value);

      // let sidebar = document.querySelector(".sidebar");
      // color.style.background(".active")
      // sidebar.classList.toggle("active");
    }
  }

  changeColor(color: string): void {
    this.themeService.setSelectedColor(color);
  }
}
