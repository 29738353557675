import { Component, OnInit } from '@angular/core';
import { LivestreamService } from 'src/app/Core/services/livestream.service';
import { MovieService } from 'src/app/Core/services/movie.service';
import { SeriesService } from 'src/app/Core/services/series.service';
import { UtilService } from 'src/app/Core/services/util.service';
import * as _ from 'lodash';
import { data } from 'jquery';
import { ViewChild } from '@angular/core';
import { SearchLivetvComponent } from '../search-livetv/search-livetv.component';
import { SearchMovieComponent } from '../search-movie/search-movie.component';
import { SearchSeriesComponent } from '../search-series/search-series.component';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @ViewChild('updateSearchLiveTv', { static: false }) updateSearchLiveTv: SearchLivetvComponent;
  @ViewChild('updateSearchMovies', { static: false }) updateSearchMovies: SearchMovieComponent;
  @ViewChild('updateSearchSeries', { static: false }) updateSearchSeries: SearchSeriesComponent;
  isLoading: boolean = false;
  isLiveLoading: boolean = false;
  isChannelLoading: boolean = false;
  movieShowList: any;
  seriesShowList: any;
  liveStream: any;
  liveCategoryList: any;
  movieCategoryList: any;
  isSorting = false;
  // searchForm.value.search: any;
  selectedMenuItem: any = 'Live Tv';
  searchKeyword: any = '';
  recentSearches: any = [];
  slideConfigLive = {
    "slidesToShow": 6, "slidesToScroll": 6, responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  slideConfig = {
    "slidesToShow": 7, "slidesToScroll": 7, responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  subscription: Subscription;
  sortType: any;
  latestSort!: boolean;
  nameSort!: boolean;
  isSort: boolean = true;
  ascDesc: any = 'asc';
  searchForm: FormGroup;
  translations = ['NAV.NAV3','NAV.NAV4', 'NAV.NAV5'];
  constructor(
    private movieservice: MovieService,
    private seriesService: SeriesService,
    private liveStreamService: LivestreamService,
    private utilService: UtilService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    
    this.searchForm = this.formBuilder.group({
      search: ['']
    });
    if (localStorage.getItem('recentSearch')) {
      this.recentSearches = JSON.parse(localStorage.getItem('recentSearch')).reverse();
    }
    this.getSeries();
    this.getLiveStramAllList();
    this.getLiveCategory();
    this.getMoviesCategory();
    this.getAllMovie();
    this.isLoading =true
    this.searchForm.get('search').valueChanges.pipe(debounceTime(100)).subscribe(value => {
      
      if(value.length >=3){
        this.isLoading=false
        this.onSearch(value);
      }
    });
  }

  onSearch(value) {
    if (value) {
      this.searchKeyword = value;
      this.recentSearchHandle(value);
      this.tabWiseSearch();
    }
  }

  tabWiseSearch() {
    setTimeout(() => {
      if (this.selectedMenuItem === 'Live Tv') {
        // this.getLiveStramAllList();
        // this.getLiveCategory();
        this.updateSearchLiveTv.searchByLiveTv();
      } else if (this.selectedMenuItem === 'Movies') {
        this.updateSearchMovies.getMovieList();
      } else {
        // this.getSeries();
        this.updateSearchSeries.searchSeriesList();
      }
    }, 100);
  }

  recentSearchHandle(key) {
    let searchData: any = [];
    if (localStorage.getItem('recentSearch')) {
      if (this.recentSearches.length == 25) {
        const maxCap = _.cloneDeep(this.recentSearches);
        maxCap.reverse();
        maxCap.splice(0, 1);
        maxCap.push(key);
        localStorage.setItem('recentSearch', JSON.stringify(maxCap));
        this.recentSearches = maxCap.reverse();
      } else {
        const allRecentSearch = JSON.parse(localStorage.getItem('recentSearch'));
        allRecentSearch.push(key);
        this.recentSearches = allRecentSearch.reverse();
        localStorage.setItem('recentSearch', JSON.stringify(allRecentSearch.reverse()));
      }
    } else {
      searchData.push(key);
      this.recentSearches = searchData;
      localStorage.setItem('recentSearch', JSON.stringify(searchData));
    }
  }

  searchByRecentKey(item) {
    this.searchForm.value.search = item;
    this.searchKeyword = item;
    setTimeout(() => {
      this.tabWiseSearch();
    }, 500);
  }

  removeSearchKey(index: any) {
    this.recentSearches.splice(index, 1);
    const updateKey = _.cloneDeep(this.recentSearches);
    localStorage.setItem('recentSearch', JSON.stringify(updateKey.reverse()))
  }
  removeSearchKeys(){
    localStorage.removeItem('recentSearch')
    this.removeSearchKey
    
    this.recentSearches = [];
    // this.recentSearchHandle(key);

  }
  
  getMoviesCategory() {
    this.isLoading = true;
    this.movieservice.getVodCategories().subscribe((res: any) => {
      this.isLoading = false;
      this.movieCategoryList = res;
    }, err => {
      this.isLoading = false;
      this.utilService.showErrorCall(err);
    });
  }
  
  getAllMovie() {
    this.isLoading = true;
    this.utilService.showSpinner()
    this.movieservice.getMovieStreamList().subscribe((res: any) => {
      this.utilService.hideSpinner()
      this.isLoading = false;
      this.movieShowList = res;
    }, err => {
      this.isLoading = false;
      this.utilService.showErrorCall(err);
    });
  }
  
  onMenuselect(item: any) {
    this.selectedMenuItem = item;
    this.searchKeyword = item;
    this.tabWiseSearch();
  }
  
  getLiveCategory() {
    this.isLiveLoading = true;
    this.liveStreamService.getliveCategories().subscribe((res: any) => {
      this.isLiveLoading = false;
      this.liveCategoryList = res;
      
    }, err => {
      this.isLiveLoading = false;
      this.utilService.showErrorCall(err);
    });
  }
  
  getSeries() {
    this.isLoading = true;
    this.seriesService.getSeriesList().subscribe((res: any) => {
      this.isLoading = false;
      if (res) {
        this.seriesShowList = res;
      }
    }, err => {
      this.isLoading = false;
      this.utilService.showErrorCall(err);
    });
  }
  
  getLiveStramAllList() {
    // this.isChannelLoading = true;
    this.isLoading =true;
    this.isLiveLoading = true
    this.liveStreamService.getLivestreamAll().subscribe((res: any) => {
      // this.isChannelLoading = false;
      this.isLiveLoading = false
      this.isLoading = false
      this.liveStream = res;
    }, err => {
      this.isLoading = false
      this.isChannelLoading = false;
      this.utilService.showErrorCall(err);
    });
  }
  
  sortSearch(sortType) {
    this.isSorting = true;
    if (sortType == 'sortByCate') {
      this.nameSort = true;
      this.latestSort = false;
      if (this.selectedMenuItem == 'Movies') {
        this.updateSearchMovies.sortMovies('category_name');
      } else if (this.selectedMenuItem == 'Series') {
        this.updateSearchSeries.sortSeries();
      } else {
        if (this.searchForm.value.search) {
          this.updateSearchLiveTv.sortLiveTv();
        } else {
          this.isSort = !this.isSort;
          (this.isSort) ? this.ascDesc = 'desc' : this.ascDesc = 'asc';
          this.isLoading = true;
          setTimeout(() => {
            this.utilService.tabWiseSearchSorting(this.ascDesc, 'name', this.movieShowList, (callback) => {
              this.movieShowList = callback;
            });
            this.utilService.tabWiseSearchSorting(this.ascDesc, 'name', this.liveStream, (callback) => {
              this.liveStream = callback;
            });
            this.utilService.tabWiseSearchSorting(this.ascDesc, 'name', this.seriesShowList, (callback) => {
              this.seriesShowList = callback;
            });
            this.isLoading = false;
          }, 100);
        }
      }
    } else if (sortType == 'sortByNew') {
      this.nameSort = false;
      this.latestSort = true;
      if (this.selectedMenuItem == 'Movies') {
        this.updateSearchMovies.sortMovies('new_realease');
      } else {
        if (this.selectedMenuItem !== 'Live Tv' || this.selectedMenuItem !== 'Series') {
          this.isSort = !this.isSort;
          this.isLoading = true;
          const currentYear: string = JSON.stringify(new Date().getFullYear());
          setTimeout(() => {
            this.movieShowList = _.orderBy(this.movieShowList, { year: currentYear }, ['desc']);
            this.seriesShowList = _.orderBy(this.seriesShowList, { year: currentYear }, ['desc']);
            this.isLoading = false;
          }, 100);
        }
      }
    }
    setTimeout(() => {
      this.isSorting = false;
    }, 1500);
  }
  
  // language json function 
  getTranslation(index: number): string {
    return this.translations[index];
  }

}