import { Component, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { AfterViewInit, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import videojs from 'video.js';
import { TranslateService } from '@ngx-translate/core';
import { LivestreamService } from 'src/app/Core/services/livestream.service';
import { UtilService } from 'src/app/Core/services/util.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Constant } from '../../services/data';
import * as _ from 'lodash';
import { CategoryService } from '../../services/category.service';
import { HttpClient } from '@angular/common/http';


// const shaka = require('shaka-player');
declare let shaka: any;

const Plyr = require('plyr');
declare var require: any;
// declare let shaka: any;
// declare var jwplayer: any;
declare var Hls;
// const registry = require('windows-registry');
// require('videojs-contrib-quality-levels');
// require('videojs-hls-quality-selector');

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  @ViewChild('channelbox', { static: false }) private channelboxScroll: ElementRef;
  // videoType: string;
  isCategory: boolean = false;
  isChannels: boolean = false;
  isLoading: boolean = false;
  isChannelLoading: boolean = false;
  isDefaultButton: boolean = false;
  categoryId: any;
  selectedchannel: any;
  sortType: any;
  defaultCategoryList: any;
  liveCategoryList: any;
  channels: any;
  subscription: Subscription;
  records: Array<any>;
  // isDesc: boolean = false;
  epgdata: any;
  displayEpg: any = [];
  channelname: any;
  hello: any;
  start: any;
  end: any;
  dash = ' ';
  MOVIEDatas = [
  ]
  CHANNELDatas = [
  ]
  public player: videojs.Player;
  // private plyrInstance: Plyr;
  hls: any;
  Url: any;
  url: string;
  filterTerm: string;
  initial_Url: string;
  basetitle: any;
  basedesciption: any;
  timedata: any;
  video;
  videoPlaying: boolean;
  percentage;
  displayURL;
  vlcUrl: any
  newUrl: any
  epgid:any
  constructor(private http: HttpClient, private categoriesService: CategoryService, private liveStreamService: LivestreamService, private route: ActivatedRoute, private utilService: UtilService, private sanitizer: DomSanitizer, public translate: TranslateService) {
    this.subscription = this.utilService.getSorting().subscribe((res: any) => {
      this.sortType = res;
      if (this.sortType.type == 'Livetv') {
        this.sort('category_name', this.sortType.sub_category);
      }
    });
    this.route.queryParams.subscribe((param: any) => {
      if (param) {
        if (param.stream) {
          this.selectedchannel = param.stream;
        }
        if (param.category)
          this.categoryId = param.category;
      }
      this.updateChannelEpg(this.selectedchannel, this.categoryId)
    });

  }

  ngOnInit(): void {
    shaka.polyfill.installAll();
    console.log("shaka player",shaka);
    const shakaPlayer = document.getElementById('video') as HTMLObjectElement;
    if(shakaPlayer){
      shakaPlayer.onplay
    }else{
      console.log('shaka player');
      
    }

    
    // this.getCategoriesList();
    this.getLiveCategory();
    console.log("categories not found", this.categoryId);
    if (!this.categoryId) {
      // this.getChannelList(131);
      // this.getNewChannelList('',null);
    }
    this.video = document.getElementsByTagName("video")[0];
    this.initial_Url = 'https://vmp.one/live/jum/quin/191835.m3u8';
  }
  getCategoriesList() {
    this.isLoading = true
    this.categoriesService.get().subscribe((res: any) => {
      this.isLoading = false
      console.log(res)
      // this.liveCategoryList = res;
      // this.defaultCategoryList = res;
      if (this.liveCategoryList) {
        this.liveCategoryList.map((ele: any, index) => {
          if (this.categoryId) {

            if (ele.category_id == this.categoryId) {

              ele.select = true;
              setTimeout(() => {
                //   document.getElementById("button" + ele.category_id).scrollIntoView();
                $(".category-box").animate({ scrollTop: $("#button" + ele.category_id).position().top }, 200, 'swing');
              }, 300);

              this.getChannelList(this.categoryId)
            }
          } else {
            if (index == 0) {
              ele.select = true;
              this.getChannelList(ele.category_id);
              console.log("categories id", ele.category_id);
            } else {
              ele.select = false;
            }
          }
        });
      }
    }, err => {
      this.isLoading = false;
      this.utilService.showErrorCall(err);
    })

  }

  getLiveCategory() {
    this.isLoading = true;
    this.utilService.showSpinner()
    this.liveStreamService.getliveCategories().subscribe((res: any) => {
      this.isLoading = false;
      this.utilService.hideSpinner()
      console.log(res)
      this.liveCategoryList = res;
      this.defaultCategoryList = res;

      if (this.liveCategoryList) {
        this.liveCategoryList.map((ele: any, index) => {
          if (this.categoryId) {
            if (ele.category_id == this.categoryId) {
              ele.select = true;
              setTimeout(() => {
                //   document.getElementById("button" + ele.category_id).scrollIntoView();
                $(".category-box").animate({ scrollTop: $("#button" + ele.category_id).position().top }, 200, 'swing');
              }, 300);
              this.getChannelList(this.categoryId)

            }
          } else {
            if (index == 0) {
              ele.select = true;
              this.getChannelList(ele.category_id);
            } else {
              ele.select = false;
            }
          }
        });
      }
      else {
        this.getNewChannelList('')
      }
    }, err => {
      this.isLoading = false;
      this.utilService.showErrorCall(err);
    });

  }

  getNewChannelList(categoryId: any, isChange = null) {

    this.isChannelLoading = true;
    this.isLoading =true;
    this.utilService.showSpinner()
    this.liveStreamService.getCategorywiseChannelList('').subscribe((res: any) => {
      this.utilService.hideSpinner()
      this.isChannelLoading = false;
      this.isLoading =false
      this.channels = res;
      console.log("channels----", res);
      if (this.channels) {
        this.liveCategoryList.map((elecat: any, indexcat) => {
          if (elecat.category_id == categoryId)
            elecat.channelCount = this.channels.length;
        });//for channel count

        let index, savedArray;
        savedArray = JSON.parse(localStorage.getItem(Constant.LIVETV_FAV)) || [];
        savedArray.map((ele: any) => {
          index = _.findIndex(this.channels, { stream_id: ele.id });
          if (index >= 0) {
            this.channels[index].isFavourit = true;
          }
        });//for favourit


        this.channels.map((ele: any, index) => { //during route channel

          if (this.selectedchannel && !isChange) {
            if (this.selectedchannel == ele.stream_id) {
              ele.select = true;
              setTimeout(() => {
                $("#channel-box").animate({
                  scrollTop:
                    $("#channel" + ele.stream_id).offset().top - $("#channel-box").offset().top
                }, 500, 'swing');
              }, 100);

              this.updateChannel(ele.stream_id);
            }
          }
          else {
            if (index == 0) {
              ele.select = true;
              this.updateChannel(ele.stream_id);
            } else {
              ele.select = false;
            }
          }
          // (index == 0) ? ele.select = true : ele.select = false;
          // if (ele.num == 1)
          //   this.updateChannel(ele.stream_id);
        });
      }
    }, err => {
      this.isChannelLoading = false;
      this.utilService.showErrorCall(err);
    });
  }


  getChannelList(categoryId: any, isChange = null) {
    this.channelboxScroll.nativeElement.scrollTop = 0;
    this.utilService.showSpinner()
    
    this.isChannelLoading = true;
    this.liveStreamService.getCategorywiseChannelList(categoryId).subscribe((res: any) => {
      this.isChannelLoading = false;
    this.utilService.hideSpinner()

      this.channels = res;
      console.log("channel", res);
      if (this.channels) {
        this.liveCategoryList.map((elecat: any, indexcat) => {
          if (elecat.category_id == categoryId)
            elecat.channelCount = this.channels.length;
        });//for channel count

        let index, savedArray;
        savedArray = JSON.parse(localStorage.getItem(Constant.LIVETV_FAV)) || [];
        savedArray.map((ele: any) => {
          index = _.findIndex(this.channels, { stream_id: ele.id });
          if (index >= 0) {
            this.channels[index].isFavourit = true;
          }
        });//for favourit


        this.channels.map((ele: any, index) => { //during route channel

          if (this.selectedchannel && !isChange) {
            if (this.selectedchannel == ele.stream_id) {
              ele.select = true;
              setTimeout(() => {
                //   document.getElementById("channel" + ele.stream_id).scrollIntoView();
                $("#channel-box").animate({
                  scrollTop:
                    $("#channel" + ele.stream_id).offset().top - $("#channel-box").offset().top
                }, 500, 'swing');
              }, 100);

              this.updateChannel(ele.stream_id);
            }
          }
          else {
            if (index == 0) {
              ele.select = true;
              this.updateChannel(ele.stream_id);
            } else {
              ele.select = false;
            }
          }
          // (index == 0) ? ele.select = true : ele.select = false;
          // if (ele.num == 1)
          //   this.updateChannel(ele.stream_id);
        });
      }
    }, err => {
      this.isChannelLoading = false;
      this.utilService.showErrorCall(err);
    });
  }
  onCategoryChange(id: any, ischange: any) {
    // let btnId = "button" + id;
    // this.utilService.filterResetObservable.next(true);
    this.liveCategoryList.map((ele: any, index) => {
      (ele.category_id == id) ? ele.select = true : ele.select = false;
      // console.log("on change category",ele.category_id,"sdfad",id);
    });

    this.getChannelList(id, ischange);
    // this.updateChannelEpg(id,id)
  }

  updateChannel(streamid) {
    console.log("update chanel", streamid);

    this.channels.map((ele: any, index) => {
      (ele.stream_id == streamid) ? ele.select = true : ele.select = false;
    });

    this.Url = this.liveStreamService.getStreamURL(streamid);
    localStorage.setItem("url", this.Url);
    this.url = localStorage.getItem("url");
    // this.url ='https://cdn.bitmovin.com/content/demos/4k/38e843e0-1998-11e9-8a92-c734cd79b4dc/manifest.m3u8'
    // this.url ='https://moctobpltc-i.akamaihd.net/hls/live/571329/eight/playlist.m3u8'
    // if(this.url){
    //   setTimeout(() => {
    //     videojs("example-videoMovie").src([
    //       { type: "application/x-mpegURL", src: this.url,label: 'FHD' },
    //     ]);
    //     var player = videojs("example-videoMovie");
    //     player.on('ended', function () {
    //       this.dispose();
    //       window.close();
    //     });
    //     // const liveButton = player.controlBar.liveTracker.liveButton;
    //     const liveButton =player.controlBar.addChild("vjs-on");

    //     var myButtonDom = liveButton.el();
    //     myButtonDom.innerHTML = "LIVE";


    //     // var myButton = player.controlBar.addChild("button");


    //     // There are many functions available for button component 
    //     // like below mentioned in this docs 
    //     // https://docs.videojs.com/button. 
    //     // You can set attributes and clasess as well.

    //     // Getting html DOM
    //     // var myButtonDom = myButton.el();
    //     // Since now you have the html dom element 
    //     // you can add click events
    //     // exit button 
    //     // myButtonDom.innerHTML = "EXIT";

    //     // myButtonDom.onclick = function () {
    //     //   alert("Are you sure?");
    //     //   window.location.href = "/movies";
    //     // }
    //     // this.router.navigate(['/movies/watch/' + this.id]);
    //   }, 200);
    // }
    // alert(this.url)
    // this.url = 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8';
    // this.url ='http://xtremity.tv:2052/live/qyf9ax/p7au3w/3401181.m3u8'
    // if (this.url){
    //   const player = jwplayer("myElementLive").setup({
    //     "playlist": [{         
    //       "file": this.url
    //     }]
    //   });
    // }

    // videojs("example-video").src([
    //   { type: "application/x-mpegURL", src: this.url },
    // ]);

    let video: any = document.getElementById('video');
    // var videoSrc = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
    // console.log(videoSrc);

    // var vlc = document.getElementById("vlc");
    if (Hls.isSupported()) {
      this.hls = new Hls();
      this.hls.attachMedia(video);
      // hls.on(Hls.Events.MANIFEST_PARSED, () => {
      //   video.play();
      // });
      this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        this.hls.loadSource(this.url);
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
      });
    }


    // var hls = new Hls();
    // // bind them together
    // hls.attachMedia(video);
    // hls.on(Hls.Events.MEDIA_ATTACHED, ()=> {
    //    console.log('video and hls.js are now bound together !');
    //   hls.loadSource(this.url);
    //   hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
    //     console.log(
    //       'manifest loaded, found ' + data.levels.length + ' quality level'
    //     );
    //   });
    // });
    // }else{
    //   document.getElementById('video').setAttribute('src',this.url);
    // }


  }


  updateChannelEpg(epgid, name) {
    this.displayEpg = [];
    this.channelname = name;
    this.liveStreamService.getshortEpg(epgid).subscribe((res: any) => {
      // this.api.getcepg(epgid).subscribe(res => {
      if (res) {
        this.epgdata = res.epg_listings;
        const offset = -1 * new Date().getTimezoneOffset()
        for (let i = 0; i < this.epgdata.length; i++) {
          this.basetitle = atob(this.epgdata[i].title);
          this.basedesciption = atob(this.epgdata[i].description);
          // var d2 = moment(this.epgdata[i].start).add(offset, 'minutes').format('HH:mm');
          // var d3 = moment(this.epgdata[i].end).add(offset, 'minutes').format('HH:mm');
          const start = moment.utc(this.epgdata[i].start).add(offset, 'minutes');
          const end = moment.utc(this.epgdata[i].stop).add(offset, 'minutes');
          
          const d2 = start.format('HH:mm');
          const d3 = end.format('HH:mm');
          console.log("start",d2)
          console.log("end",d3);
          
          // (this.utilService.getValueStorage(Constant.XSTREAMURL_KEY) ? (d3 = moment(this.epgdata[i].stop).add(offset, 'minutes').format('HH:mm')) : (d3 = moment(this.epgdata[i].end).add(offset, 'minutes').format('HH:mm')));
          const mjm = {
            title: this.basetitle,
            start: d2,
            end: d3,
            desc: this.basedesciption
          }
          this.displayEpg.push(mjm);
          //this.epgdata.push({endepg:this.epgdata[i].end})
          // var d5 = this.displayEpg[0].start;
          // d5 = d5.split(' ')[1];
          // var hms = d5;   // your input string
          // var a2 = hms.split(':'); // split it at the colons


          // var seconds2 = (+a2[0]) * 60 * 60 + (+a2[1]) * 60 + (+a2[2]);
          // console.log(seconds2);
          // localStorage.setItem('start', JSON.stringify(seconds2));


          // var d6 = this.epgdata[0].end;
          // d6 = d6.split(' ')[1];


          // var hms = d6;   // your input string
          // var a3 = hms.split(':'); // split it at the colons


          // var seconds3 = (+a3[0]) * 60 * 60 + (+a3[1]) * 60 + (+a3[2]);
          // console.log(seconds3);
          // localStorage.setItem('end', JSON.stringify(seconds3));
          // var duratione = seconds3 - seconds2;

          // console.log(duratione);
          // localStorage.setItem('duration', JSON.stringify(duratione));




        }
      }
    });
  }

  sort(property, subtype: any) {
    let ascDesc;
    if (subtype == 'default') {
      this.liveCategoryList = this.defaultCategoryList;
    } else {
      (subtype == 'atoz') ? ascDesc = 'asc' : ascDesc = 'desc';
    }
    if (ascDesc) {
      this.utilService.sortCommonCategorywise(property, ascDesc, this.liveCategoryList, (callback) => {
        this.liveCategoryList = callback;
      });
    }
  }

  onSave(item: any, ev: any) {
    ev.stopPropagation();
    let savedArray = [];
    let isExist: any;
    let index;
    const msg=this.translate.instant('COMMON.ADDED_SUCCESS_MSG')

    savedArray = JSON.parse(localStorage.getItem(Constant.LIVETV_FAV)) || [];
    if (savedArray.length > 0) {
      isExist = _.findIndex(savedArray, { id: item.stream_id });
      if (isExist == -1) {
        item.isList = true;
        item.id = item.stream_id;
        item.fullUrl = localStorage.getItem(Constant.FULLURL_KEY);
        savedArray.push(item);
        localStorage.setItem(Constant.LIVETV_FAV, JSON.stringify(savedArray));
        index = _.findIndex(this.channels, { stream_id: item.stream_id });
        if (index >= 0) {
          this.channels[index].isFavourit = true;
        }

        this.utilService.showErrorSuccess(`${msg}`);
      }
      if (isExist >= 0) {
        this.utilService.showErrorInfo('Item alreadey added to favourite')
      }

    }
    else { //first
      item.isList = true;
      item.id = item.stream_id;
      item.fullUrl = localStorage.getItem(Constant.FULLURL_KEY);
      savedArray.push(item);
      localStorage.setItem(Constant.LIVETV_FAV, JSON.stringify(savedArray));
      index = _.findIndex(this.channels, { stream_id: item.stream_id });
      if (index >= 0) {
        this.channels[index].isFavourit = true;
      }
      this.utilService.showErrorSuccess(msg);

    }
  }
  deleteFavourite(item: any, ev: any) {
    ev.stopPropagation();
    item.id = item.stream_id;
    const msg=this.translate.instant('COMMON.REMOVE_SUCCESS_MSG')
    this.utilService.deleteFavourit(Constant.LIVETV_FAV, item, (result: any) => {
      if (result) {
        this.utilService.showErrorSuccess(`${msg}`);
        let index = _.findIndex(this.channels, { stream_id: item.id });
        if (index >= 0) {
          this.channels[index].isFavourit = false;
        }
      }
    });
  }
  /** 
  togglevideo(){
      if(!this.videoPlaying){
        this.video.play();
        this.videoPlaying = true;
      }else{
        this.video.pause();
        this.videoPlaying = false;
      }*/

  onTimeUpdate() {
    //console.log(this.video.currentTime);
    //console.log(this.video.duration);

    var today = new Date();

    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + ' ' + time;
    this.timedata = dateTime;
    //console.log(dateTime);

    this.start = localStorage.getItem('start');
    this.end = localStorage.getItem('end');
    this.hello = localStorage.getItem('duration');
    // console.log(this.end);
    this.percentage = (this.video.currentTime * 100 / this.hello);
    // console.log(this.percentage);
  }
  ngOnDestroy() {
    this.hls.destroy();
    this.video.pause();
    if (this.sortType) {
      this.subscription.unsubscribe();
    }
  }

  //test video 
  videoSource = "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4";

  @ViewChild('videoPlayer') videoplayer: any;
  public startedPlay: boolean = false;
  public show: boolean = false;
  pauseVideo(videoplayer) {
    videoplayer.nativeElement.play();
    // this.startedPlay = true;
    // if(this.startedPlay == true)
    // {
    setTimeout(() => {
      videoplayer.nativeElement.pause();
      if (videoplayer.nativeElement.paused) {
        this.show = !this.show;
      }
    }, 5000);
    // }
  }

  closebutton(videoplayer) {
    this.show = !this.show;
    videoplayer.nativeElement.play();
  }

  // private setupPlyr() {
  //   const videoElement = this.elementRef.nativeElement.querySelector('video');

  //   this.plyrInstance = new Plyr(videoElement, {
  //     controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'pip', 'airplay', 'fullscreen'],
  //     tooltips: { controls: false },
  //     playlist: {
  //       enabled: true,
  //       loop: { active: true },
  //       autoadvance: { autoplay: true, delay: 5000 },
  //       items: [
  //         {
  //           title: 'Video 1',
  //           sources: [
  //             {
  //               src: this.url,
  //               type: 'application/x-mpegURL'
  //             }
  //           ]
  //         },
  //       ]
  //     }
  //   });
  // }

  openInVLC(urlForVlc) {
    if (urlForVlc) {
      if (window.navigator.platform.startsWith('Win')) {
      // this.vlcUrl = `vlc:open/${urlForVlc}`;
     this.vlcUrl=`vlc://open?url=${encodeURIComponent(urlForVlc)}`
      window.location.href = this.vlcUrl
      // window.open(this.vlcUrl)
    }
      else if (window.navigator.platform.startsWith('Mac')) {
        this.vlcUrl = `open -a "VLC" "${urlForVlc}"`;
      }else{
        this.vlcUrl=`vlc"${urlForVlc}"`
      }
      // window.open(this.vlcUrl);
    }
    else {
      this.utilService.showErrorCall("Url Not Found")
    }

  }

  copyUrl(url) {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    const success = 'Url copied successfully ,After opening VLC Player paste Url (Ctrl+v)'
    this.utilService.showSuccess(success)
    this.openInVLC(url)
  }

  downloadRegistryFile() {
    const registryFileContent = `Windows Registry Editor Version 5.00
    [HKEY_CLASSES_ROOT\vlc]
    @="URL:VLC Protocol"
    "URL Protocol"=""
    [HKEY_CLASSES_ROOT\vlc\shell]
    [HKEY_CLASSES_ROOT\vlc\shell\open]
    [HKEY_CLASSES_ROOT\vlc\shell\open\command]
    @="\"C:\\Program Files\\VideoLAN\\VLC\\vlc.exe\" \"%1\""
    `;
    const blob = new Blob([registryFileContent], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'vlc.reg';
    link.click();
    window.URL.revokeObjectURL(url);
  }

  onClickOpenVLC(url: string) {

    const serverUrl = 'http://localhost:3000/open-vlc';
    const fullUrl = `${serverUrl}?url=${url}`;
  
    this.http.get(fullUrl).subscribe(
      () => {
        console.log('VLC command sent successfully');
      },
      (error) => {
        window.open('https://www.videolan.org/vlc/', '_blank');
      }
    );
  }
  openVlc(url) {
    // const urls = `https://dazzling-buttercream-4df76e.netlify.app/.netlify/functions/api/play?url=${encodeURIComponent(url)}`;
    // const urls = `    https://quiet-alpaca-a36ba3.netlify.app/.netlify/functions/api?url=${encodeURIComponent(url)}`;
    const urls = `http://localhost:3000/play?url=${encodeURIComponent(url)}`;

    this.http.get(urls).subscribe(
      response => {
        console.log(response);
      },
      error => {
        if (error.status === 500){
        window.open('https://www.videolan.org/vlc/', '_blank');
        }
      }
    );
  }

}
