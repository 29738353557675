import { ChangeDetectorRef, EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '@gorniv/ngx-universal';
import { Constant } from './data';
import { MessageService } from './message.service';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

const TOAST_CONFIG: any = {
    timeOut: 4000,
    positionClass: 'toast-bottom-right',
    toastClass: 'ngx-toastr animated pulse fadeInUp',
    progressBar: true,
    progressAnimation: 'decreasing',
    tapToDismiss: true,
    closeButton: true
};
@Injectable({
    providedIn: 'root'
})
export class UtilService {
    public sortingType = new EventEmitter();
    isSort: boolean = true;

    public static API_TIMEOUT = 90000;
    LOGOUT_MSG = 'There was no activity on the site for a period of time, the session was ended for security reasons. Sign in to return to your account.';

    public loginObservable = new Subject<any>();
    login$ = this.loginObservable.asObservable();


    public filterResetObservable = new Subject<any>();
    filterReset$ = this.filterResetObservable.asObservable();

    public static BSCONFIG = { containerClass: 'theme-dark-blue' };

    accessedTab: any = null;
    remove_from_fav = ['COMMON.REMOVE_FROM_FAVOURITE']

    constructor(
        private toastr: ToastrService,
        private appCookie: CookieService,
        private router: Router,
        private http: HttpClient,
        private cookieService: CookieService,
        private messageService: MessageService,
        private spinner: NgxSpinnerService,
        private translate: TranslateService
    ) {
    }
    addSorting(type: any) {
        this.sortingType.next(type);
    }
    getSorting() {
        return this.sortingType;
    }

    commonSort(isSort: boolean, property: string, array) {
        let direction = isSort ? 1 : -1;
        array.sort((a, b) => {
            if (a[property] < b[property]) {
                return -1 * direction;
            } else if (a[property] > b[property]) {
                return 1 * direction;
            } else {
                return 0;
            }
        });
    }
    sortCommonCategorywise(property: string, sortType: any, array: any, callback: any) {
        callback(_.orderBy(array, [property], [sortType]));
    }

    tabWiseSearchSorting(isSort, searchType, categoriesArray, callback) {
        if (searchType == 'category_name') {
            callback(_.orderBy(categoriesArray, [searchType], [isSort]));
        } else if (searchType == 'new_realease') {
            callback(_.orderBy(categoriesArray, { category_name: 'NEW RELEASES' }, ['desc']));
        } else if (searchType == 'name') {
            callback(_.orderBy(categoriesArray, [searchType], [isSort]));
        } else if (searchType == 'only_series') {
            if (isSort == 'desc') {
                callback(_.sortBy(categoriesArray, (season) => {
                    return season.info.name;
                }));
            } else {
                callback(categoriesArray.reverse())
            }
        }
    }

    sortFromHeader(arrayName: any, property) {
        this.isSort = !this.isSort;
        let direction = this.isSort ? 1 : -1;
        arrayName.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        });
    }

    showErrorInfo(title: string = '', message: string = '') {
        this.toastr.info(message, title, TOAST_CONFIG);
    }

    showErrorToast(title: string = 'Something went wrong.', message: string = 'Please, try again later.') {
        this.toastr.error(message, title, TOAST_CONFIG);
    }
    showErrorSuccess(title: string = 'Success!', message: string = '') {
        this.toastr.success(message, title, TOAST_CONFIG);
    }
    showSuccess(title: string = 'Success', message: string = '') {
        this.toastr.success(message, title, TOAST_CONFIG)
    }

    showSpinner() {
        this.spinner.show()
    }
    hideSpinner() {
        this.spinner.hide()
    }

    markFormGroupDirty(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(key => {
            formGroup.get(key)?.markAsDirty();
        });
    }


    public showErrorCall(err: any, show: number = 0) {
        try {
            if ((err.status === 401 || err.status === 403) && (this.router.url.indexOf('/auth/') < 0 || this.router.url.indexOf('/final-step') > 0)) {
                this.showErrorInfo('', this.LOGOUT_MSG);
                this.logout();
            } else if (err.error) {
                try {
                    const errBody: any = err.error;
                    const errStr = errBody.message || errBody.title;
                    // Object.keys(errBody).map(val => errStr += errBody[val]);
                    if (err.status === 404) {
                        if (errBody.details) {
                            this.showErrorToast('', errBody.details);
                        }
                        // this.showErrorToast('', errStr);
                    } else if (err.status === 406 && errBody.message) {
                        // if (errBody.message == 'Sorry you have to login first') {
                        //   this.logout()
                        // }
                        if (show === 0) {
                            this.showErrorToast('', errBody.message);
                        }
                    } else {
                        if (show === 0) {
                            this.showErrorToast('', errStr);
                        }
                    }
                    // if (err.status === 401 && localStorage.getItem('isLoggedin')) {
                    //   this.logout();
                    // }
                } catch (error) {
                    this.showErrorToast();
                }
            } else if (err && err.name === 'TimeoutError') {
                this.showErrorToast('', 'Timeout');
            } else {
                if (err.status === 404) {
                    this.showErrorToast();
                    return null;
                }

                if (typeof err === 'string') {
                    this.showErrorToast('', err);
                } else {
                    this.showErrorToast();
                }
            }
        } catch (error) {
            this.showErrorToast();
        }
    }

    public logout(isFromPlaylist = null) {
        const logoutTranslation = this.translate.instant('HOME.LOGOUT');
        const confirmationTranslation = this.translate.instant('HOME.LOGOUT_CONFIRMATION');
        const yes = this.translate.instant('HOME.YES')
        const no = this.translate.instant('HOME.NO')
        // this.messageService.confirm('LogOut!', 'Are you sure you want to Log Out?')
        this.messageService.confirm(logoutTranslation, confirmationTranslation)
            .subscribe((answer) => {
                if (answer === yes) {
                    if (((this.getValueCookie(Constant.ISREMEMBER_FULLKEY))) || ((this.getValueCookie(Constant.ISREMEMBER_XKEY)))) {
                    }
                    else {
                        this.cookieService.removeAll();
                    }
                    if (localStorage.getItem(Constant.FULLUSERID_KEY)) {
                        if (isFromPlaylist) {
                            this.router.navigate(['/login']);
                            localStorage.clear();

                        } else {
                            this.router.navigate(['/playlist']);
                        }
                    }
                    if (localStorage.getItem(Constant.XSTREAMURL_KEY)) {
                        this.router.navigate(['/add-playlist']);
                        const codeData = {
                            code: localStorage.getItem('code'),
                            xtoken: localStorage.getItem(Constant.XTOKEN)
                        }
                        localStorage.clear();
                        // localStorage.setItem('code', codeData.code);
                        // localStorage.setItem(Constant.XTOKEN, codeData.xtoken);
                    }

                    // xstram login with id, pass, url

                    if (localStorage.getItem(Constant.XSTREAMURLs_KEY)) {
                        this.router.navigate(['/Xstream-Login']);
                        const codeData = {
                            code: localStorage.getItem('code'),
                            xtoken: localStorage.getItem(Constant.XTOKEN)
                        }
                        localStorage.clear();
                        localStorage.setItem('code', codeData.code);
                        localStorage.setItem(Constant.XTOKEN, codeData.xtoken);
                    }

                    if (this.router.url.indexOf('playlist') > -1) {
                        this.router.navigate(['/login']);
                        localStorage.clear();
                    }
                }
            });
    }
    saveValueCookie(key: any, value: any) {
        this.cookieService.put(key, value);
    }
    getValueCookie(key: any) {
        return this.cookieService.get(key);
    }
    clearCookieStorage() {
        this.cookieService.removeAll();
    }
    saveValueStorage(key: any, value: any) {
        localStorage.setItem(key, value);
    }
    getValueStorage(key: any) {
        return localStorage.getItem(key);
    }
    removeLocalStorage(key: any) {
        localStorage.removeItem(key);
    }
    removeXsreamStorage() {
        localStorage.removeItem(Constant.XSTREAMURL_KEY);
        localStorage.removeItem(Constant.XSTREAMUSERNAME_KEY);
        localStorage.removeItem(Constant.XSTREAMPASSWORD_KEY);
        localStorage.removeItem(Constant.XTOKEN);
    }
    removeFullModeStorage() {
        localStorage.removeItem(Constant.FULLURL_KEY);
        localStorage.removeItem(Constant.FULLUSERNAME_KEY);
        localStorage.removeItem(Constant.FULLPASSWORD_KEY);
        localStorage.removeItem(Constant.FULLUSERID_KEY);
    }
    getFullUrl() {
        return localStorage.getItem(Constant.FULLURL_KEY);
    }
    getFullUsername() {
        return localStorage.getItem(Constant.FULLUSERNAME_KEY);
    }
    getFullPassword() {
        return localStorage.getItem(Constant.FULLPASSWORD_KEY);
    }
    getFullUserId() {
        return localStorage.getItem(Constant.FULLUSERID_KEY);
    }
    getXstreamUrl() {
        return localStorage.getItem(Constant.XSTREAMURL_KEY);
    }
    getXstreamUsername() {
        return localStorage.getItem(Constant.XSTREAMUSERNAME_KEY);
    }
    getXstreamPassword() {
        return localStorage.getItem(Constant.XSTREAMPASSWORD_KEY);
    }
    // getXstreamUserId() {
    //     return localStorage.getItem(Constant.XSTREAMUSERID_KEY);
    // }
    deleteFavourit(key: any, item: any, callback) {
        const logoutTranslation = this.translate.instant('COMMON.REMOVE_FAV');
        const confirmationTranslation = this.translate.instant('COMMON.FAVOURITE_CONFIRMATION');
        const yes = this.translate.instant('HOME.YES')
        
        // const no = this.translate.instant('HOME.NO')
        this.messageService.confirm(logoutTranslation,confirmationTranslation )
        .subscribe((answer) => {
            if (answer === yes) {
                    let a = [];
                    let array: any;
                    let deledIndex: any;
                    let savedArray;
                    savedArray = JSON.parse(localStorage.getItem(key)) || [];
                    savedArray.map((ele: any, index) => {
                        if (ele.id == item.id) {
                            deledIndex = index;
                        }
                        else {
                            a.push(ele);
                        }
                    });
                    if (a.length > 0 || deledIndex == 0) {
                        localStorage.setItem(key, JSON.stringify(a));
                        callback(1);
                    }
                    else {
                        callback(0);
                    }

                }
            });
    }

    public preventSpace(event: any) {
        if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
            event.preventDefault();
        }
    }

    public NumOnly(event: any) {
        let Numpattern = /^([0-9])*$/;
        let resultNum = Numpattern.test(event.key);
        return resultNum;

    }
}
