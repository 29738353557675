import { NgZone } from '@angular/core';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Constant } from 'src/app/Core/services/data';
import { MovieService } from 'src/app/Core/services/movie.service';
import { UtilService } from 'src/app/Core/services/util.service';

@Component({
  selector: 'app-search-movie',
  templateUrl: './search-movie.component.html',
  styleUrls: ['./search-movie.component.css']
})
export class SearchMovieComponent implements OnInit {
  @Input() searchMovies: any = [];
  movieList: any[];
  isLoading: boolean = true;
  categories: any;
  // movieList: any;
  arrayAll: any = [];
  all: any = [];
  slideConfig = {
    infinite: false,
    "slidesToShow": 7, "slidesToScroll": 7, responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  searchedMovies: any = [];
  subscription: Subscription;
  sortType: any;
  isSort: boolean = true;
  ascDesc: string = 'asc';
  totalMovies = 0;

  constructor(
    private movieservice: MovieService,
    private utilService: UtilService,
    private changeDetectRef: ChangeDetectorRef,
    private router: Router,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
  }

  getMovieList() {
    this.isLoading = true;
    this.totalMovies = 0;
    this.utilService.showSpinner()
    this.movieservice.getMovieStreamList().subscribe((res: any) => {
      // this.isLoading = false;
      this.utilService.hideSpinner()
      this.movieList = res;
      this.getMovieCategory();

    }, err => {
      this.isLoading = false;
      this.utilService.showErrorCall(err);
    });
  }

  getMovieCategory() {
    this.isLoading = true;
    this.movieservice.getVodCategories().subscribe((res: any) => {
      this.isLoading = false;
      this.categories = res;
      this.searchMovieList();
    }, err => {
      this.isLoading = false;
      this.utilService.showErrorCall(err);
    });
  }

  searchMovieList() {
    let indexfind, savedArray;
    let a = [];
    this.all = [];
    this.movieList.map((ele: any) => {
      this.categories.map((category: any) => {
        if (ele.category_id == category.category_id) {
          ele.category_name = category.category_name;
        }
      });
    });

    this.arrayAll = _.groupBy(this.movieList, 'category_name');

    for (let result in this.arrayAll) {
      a = [];
      this.arrayAll[result].map(element => {
        a.push(element);
      });
      this.all.push({ 'category_name': result, 'category_id': a[0].category_id, data: a })
    }

    this.searchedMovies = [];
    let temp = _.cloneDeep(this.all);

    savedArray = JSON.parse(localStorage.getItem(Constant.MOVIES_FAV)) || [];
    this.searchedMovies = temp.map((movie_data, index) => {
      movie_data.data = _.filter(movie_data.data, (search => {
        return search.name && this.searchMovies && search.name.toLowerCase().indexOf(this.searchMovies.toLowerCase()) > -1

        // return search.name.toLowerCase().indexOf(this.searchMovies.toLowerCase()) > -1
      }));

      this.totalMovies = this.totalMovies + (movie_data.data ? movie_data.data.length : 0);

      savedArray.map((ele: any) => {
        if (ele) {
          indexfind = _.findIndex(movie_data.data, { stream_id: ele.id });
          if (indexfind != -1) {
            movie_data.data[indexfind].isFavourit = true;
          }
        }
      });

      return movie_data;
    });
    // console.log('Movies', this.searchedMovies);
  }

  sortMovies(sortType) {
    this.isSort = !this.isSort;
    (this.isSort) ? this.ascDesc = 'desc' : this.ascDesc = 'asc';
    this.utilService.tabWiseSearchSorting(this.ascDesc, sortType, this.searchedMovies, (callback) => {
      this.searchedMovies = callback;
    });
  }

  // openMovieInNewWindow(id) {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['/movies/watch/' + id])
  //   );
  //   window.open(url, '_blank');
  // }
}
