import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';

import { Client } from '../../models/Client';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthentictionService } from '../../services/authentiction.service';
import { UtilService } from '../../services/util.service';
import { catchError, finalize, timeout, } from 'rxjs/operators';
import { of } from 'rxjs';
import { event } from 'jquery';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.css']
})
export class AccessComponent implements OnInit {
  public isLoading: boolean = false;
  public user: Client = {
    username: '',
    code: '',
    username1: ''
  }

  constructor(private route: ActivatedRoute, private authService: AuthentictionService,
    private router: Router, private utilService: UtilService, private changeDetRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {
    if (form.valid) {

      if (this.user.code) {
        this.isLoading = true;
        this.utilService.showSpinner()
        this.authService.loginWithCode(this.user.code)
          .pipe(
            timeout(UtilService.API_TIMEOUT),
            finalize(() => {
              this.isLoading = false;
              this.utilService.hideSpinner()
              this.changeDetRef.detectChanges();
            })
          )
          .subscribe((res: any) => {
            this.isLoading = false;
            if (res.indexOf('{') === 0) {
              const error = JSON.parse(res).error;
              console.log(error);
              if (error == 'App not found') {
                this.utilService.showErrorInfo('', 'Access code is invalid.');
              } else {
                this.utilService.showErrorInfo('', error);
              }
              form.reset();
            } else {
              localStorage.setItem('code', this.user.code);
              localStorage.setItem('Xtoken', res)
              // this.utilService.showErrorSuccess('', "Login Succesfully.");
              this.router.navigate(['/server-login']);
            }
          }, err => {
            this.isLoading = false;
            this.utilService.hideSpinner()
            // this.utilService.showErrorCall(err);
            this.utilService.showErrorInfo('', 'Authentication failed with server.');
          });
      }
    }
  }
  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }
  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }
  numAndCharOnly(event:any){
    let numAndCharPattern = /^[a-zA-Z0-9_.-]*$/;
    let resultNumAndChar = numAndCharPattern.test(event.key);
    return resultNumAndChar;

  }
}
