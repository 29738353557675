
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
const domain=localStorage.getItem('playlisturl');
const username=localStorage.getItem('username');
const password=localStorage.getItem('password');*/
const domain='https://vmp.one';
const username='jum';
const password='quin';
const url=domain+'/player_api.php?username='+username+'&password='+password;

const API_URL = url;
const DECODE_DATA='https://appi.purplesmarttv.com/api.php?code=D4F882';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }
  public get(): Observable<any> {
    return this.http.get(API_URL).pipe(map(res => res));
  }
   public getdecodedata(): Observable<any> {
    return this.http.get(DECODE_DATA, { responseType: 'text' }).pipe(map(res => res));
  }
}
