import { Component, OnInit } from '@angular/core';
import { AuthentictionService } from '../../services/authentiction.service';
import { UtilService } from '../../services/util.service';
import { NgForm } from '@angular/forms';
import { Constant } from '../../services/data';
import { Router } from '@angular/router';
import { PlaylistService } from '../../services/playlist.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from '../../services/message.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-mu3-login',
  templateUrl: './mu3-login.component.html',
  styleUrls: ['./mu3-login.component.css']
})
export class Mu3LoginComponent implements OnInit {

  playListModal: any = {
    type: 'fromUrl',
    name: '',
    userName: '',
    password: '',
    url: ''
  };

  playList: any = [];
  xstreamList: any;
  isLoading: boolean = false;
  // bsModalRef: BsModalRef;
  url_pattern = "([a-z0-9-]+\:\/+)([^\/\s]+)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#\r\n]*)#?([^ \#\r\n]*)";

  // XuserId: any;
  constructor(private utilService: UtilService, private playlistService: PlaylistService, private modalService: BsModalService, private messageService: MessageService, private router: Router) {
  }

  ngOnInit(): void {
    // this.getPlayList()
  }
  getPlayList() {
    this.playList = [];
    let passModel: any = {
      userid: ''
    };
    // this.XuserId = this.utilService.getXstreamUserId();
    // if (this.XuserId) {
    //   passModel.userid = this.XuserId;
    // } else {
    passModel.userid = this.utilService.getFullUserId();
    //}

    this.isLoading = true;
    this.playlistService.getPlayList(passModel).subscribe((res: any) => {
      this.isLoading = false;
      if (res.http_code == 200) {
        if (res.m3u) {
          res.m3u.map((ele: any) => {
            if (ele._id) {
              ele.isM3u = true;
              this.playList.push(ele);
            }
          });
        }
        if (res.xstream) {
          res.xstream.map((ele: any) => {
            ele.isXstream = true;
            this.playList.push(ele);
          });

        }
        console.log(this.playList);
      }
    }, err => {
      this.isLoading = false;
      this.utilService.showErrorCall(err);
    });

  }

  checkDuplicateName(name) {
    let found = false;
    for (let index = 0; index < this.playList.length; index++) {
      if (this.playList[index].name === name) {
        found = true;
        this.utilService.showErrorToast('', 'Playlist name must be unique');
        break;
      }
    }
    return !found;
  }
  // onSubmit(form: NgForm) {
  //   if (form.valid && this.user.otp.length > 3) {
  //     this.isLoading = true;

  //     let param: any = {
  //       userid: this.user.username,
  //       pwd: this.user.otp
  //     };

  //     this.authService.login(param).subscribe((res: any) => {
  //       this.isLoading = false;
  //       if (res.http_code === 200) // userid
  //       {


  //         if (res.xstream || res.m3u) {
  //           if (res.xstream)
  //             this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, res.xstream[0].userid);
  //           else
  //             this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, res.m3u[0].userid);
  //         }
  //         if (this.user.rememberMe) {
  //           this.utilService.clearCookieStorage();
  //           this.utilService.saveValueCookie(Constant.ISREMEMBER_FULLKEY, true);
  //           this.utilService.saveValueCookie(Constant.FULLUSERNAME_KEY, this.user.username);
  //           this.utilService.saveValueCookie(Constant.FULLPASSWORD_KEY, this.user.otp);
  //         }
  //         else {
  //           this.utilService.clearCookieStorage();
  //         }

  //         if (!res.xstream && !res.m3u) {
  //           this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, this.user.username);
  //         }

  //         this.router.navigate(['playlist'], { queryParams: { isRemember: this.user.rememberMe } });
  //         this.utilService.showErrorSuccess('', "Login Succesfully.");


  //         // let uid = res.xstream[0].userid;
  //         // let uname = res.xstream[0].username;
  //         // let pin = res.xstream[0].pwd;
  //         // let url = res.xstream[0].url;
  //         // this.utilService.removeXsreamStorage();



  //         // this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, uid);
  //         // this.utilService.saveValueStorage(Constant.FULLUSERNAME_KEY, uname);
  //         // this.utilService.saveValueStorage(Constant.FULLPASSWORD_KEY, pin);
  //         // this.utilService.saveValueStorage(Constant.FULLURL_KEY, url);
  //         // this.router.navigate(['/home']);
  //         // }
  //         // else {
  //         //  // this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, this.user.username);
  //         //   this.router.navigate(['playlist']);
  //         // }
  //         // this.router.navigate(['playlist']);
  //         // this.utilService.showErrorSuccess('', "Login Succesfully.");

  //       }
  //       if (res.error) {
  //         this.utilService.showErrorInfo(res.status);
  //       }
  //     }, err => {
  //       this.isLoading = false;
  //       this.utilService.showErrorCall(err);
  //     });

  //   }
  // }
  addPlaylistM3u(form: NgForm) {
    if (form.valid && this.checkDuplicateName(this.playListModal.name)) {
      let passModel: any = {
        url: this.playListModal.url,
        name: this.playListModal.name,
        userid: this.utilService.getFullUserId()
      };
      this.isLoading = true;
      this.playlistService.addPlaylistM3u(passModel).subscribe((res: any) => {
        this.isLoading = false;
        if (res.http_code == 200) {
          this.utilService.showErrorSuccess('', res.status);
          this.getPlayList();
          this.playListModal.url = '';
          this.playListModal.name = '';
          // this.closeModal();
        }
      }, err => {
        this.isLoading = false;
        this.utilService.showErrorCall(err);
      });
    }
  }
}
