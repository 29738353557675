<div class="container-fluid mb-0 pb-0 mt-2 movie">
  <div class="row mb-0 ">

    <div class="col-12 mx-0 mx-md-4 mt-2">
      <h3 class="text-left mb-0" *ngIf="liveTvData?.length>0">{{ 'NAV.NAV3' | translate }}</h3>
    </div>
    <div class="col-12 mx-0 mx-md-4 mt-2 pr-5 responsive">
      <div class="row p-0  mx-0">
        <div class="col-md-6 col-lg-4 col-12 px-2 bd-highlight  mt-2" *ngFor="let channel of liveTvData; index as i"
          style="position: relative;">
          <div class="d-flex over1 mb-3">
            <span class="p-2 py-3">{{channel.stream_id}}</span>
            <span class="p-2">
              <img [src]="channel.stream_icon" width="80px" height="40px" class="mb-0 img-res" loading=lazy>
            </span>
            <span class="p-2 py-3 search-title cursor-pointer" title="{{channel.name}}">
              {{channel.name}}
            </span>
            <div class="ml-auto d-flex">
              <span class=" icon-color mr-3 fs-20" routerLink="/livetv"
                [queryParams]="{ category: channel.category_id, stream:channel.stream_id}"> <i
                  class="fas fa-play-circle text-purple  cursor-pointer"></i>
              </span>
              <span class=" icon-color fs-20 mr-1" (click)="deletefav(channel,'livetv')"> <i
                  class="fas fa-heart  cursor-pointer"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="col-12 mx-0 mx-md-4 mt-2 ">
      <h3 class="text-left mb-0" *ngIf="moviesData?.length>0">{{ 'NAV.NAV4' | translate }}</h3>
    </div>
  </div>
  <div class="row mx-md-3 mx-0 mt-2">
    <!-- <div class="co-12">
      <div class="row"> -->

    <div class="col-md-4 col-lg-4  col-xl-2 col-6 px-2 " *ngFor="let select of moviesData; index as i">
      <div class="mt-0">
        <div class="imageContainer-search">
          <div type="button" data-toggle="modal" data-target="#exampleModalCenter" data-backdrop="false">
            <div class="image-overlay" [routerLink]="['/movies/info/'+select.id]">
              <img [src]="select.img" class="search-img" 
              [queryParams]="{movieBy: 'favourites'}" loading=lazy>
            </div>
          </div>
          <div class="fav-icon icon-color" (click)="deletefav(select,'movie')"> <i
              class="fas fa-heart  cursor-pointer"></i>
          </div>
          <div class="d-flex mt-1">
            <p class="search-title text-left" title="{{select.name}}">{{select.name}}</p>
            <i class="fas fa-play-circle fs-18 text-purple ml-auto cursor-pointer"
              [routerLink]="['/movies/watch/'+select.id]"></i>
          </div>
        </div>
        <!-- </div>
        </div> -->
      </div>
    </div>
    <div class="col-12 mt-2">
      <h3 class="text-left mb-0" *ngIf="seriesData?.length>0">{{ 'NAV.NAV5' | translate }}</h3>
    </div>

    <div class="col-md-4 col-lg-4  col-xl-2 col-6 px-2 pt-2 bd-highlight" *ngFor="let select of seriesData; index as i">
      <div class="mt-0">
        <div class="imageContainer-search">
          <div type="button" data-toggle="modal" data-target="#exampleModalCenter" data-backdrop="false">
            <div class="image-overlay" [routerLink]="['/series/info/'+select.id]">
           
            <img [src]="select.img" class="search-imgseries" 
              [queryParams]="{seriesBy: 'favourites'}" loading=lazy>
            </div>
            </div>
          <div class="fav-icon icon-color" (click)="deletefav(select,'series')"> <i
              class="fas fa-heart  cursor-pointer"></i>
          </div>
          <div class="d-flex mt-1">
            <p class="search-title text-left" title="{{select.name}}">{{select.name}}</p>
            <i class="fas fa-play-circle fs-18 text-purple ml-auto cursor-pointer"
              [routerLink]="['/series/list/'+select.id]"></i>
          </div>
        </div>
      </div>
    </div>




  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div *ngIf="(moviesData?.length<1) && (seriesData?.length<1) && (liveTvData?.length<1)"
      class="col-12 empty-div d-flex-center ">
      <div class="text-center"> <img src="assets/images/empty_watchlist.png" class="empty-img">
        <h5 class="pt-4">{{ 'NAV.FAVOURITES_NOT_FOUND' | translate }} </h5>
        <p></p>
      </div>
    </div>
  </div>
</div>
