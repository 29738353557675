import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonAPIService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthentictionService implements OnInit {
  // url: any = environment.apiUrl;
  url: any 
  //codeUrl: any = 'https://panel.purpletv.app/api';
  // codeUrl: any = environment.cdnurl + 'https://panel.purpletv.app/api';
  codeUrl: any =  'https://cors.b-cdn.net/https://panel.purpletv.app/api';
  // 'https://cors.guidemefast.com
  // xstreamUrl:any
  loginDetails:any=[]
  urlList:any
  reg:any
  list_m3u_update:any
  list_xstream_update:any
  delete_url:any
  constructor(private commonService: CommonAPIService, public http: HttpClient) {

    this.newPlayList()
   }

  ngOnInit(): void {
    
  }

  newPlayList(){
    this.http.get('https://endpoint.purpletv.app/purpleweb/main.json').subscribe(res=>{
      this.loginDetails=res
      this.url=this.loginDetails.login
      console.log("url",this.url);
      this.list_m3u_update=this.loginDetails.list_m3u_update
      this.list_xstream_update = this.loginDetails.list_xstream_update
      this.urlList=this.loginDetails.list_get
      this.reg = this.loginDetails.register
      this.delete_url = this.loginDetails.deleteurl
      localStorage.setItem('list_get',this.urlList)
      localStorage.setItem('list_m3u_update',this.list_m3u_update)
      localStorage.setItem('list_xstream_update',this.list_xstream_update)
      localStorage.setItem('delete_url',this.delete_url)

    })
  }


  login(params: any) {
    console.log(params);

    const body = new HttpParams()
      .set(`userid`, params.userid)
      .set(`pwd`, params.pwd)
    // return this.callURLEncodedApi(this.url + '/login.php', body);
    return this.callURLEncodedApi( 'https://cors.b-cdn.net/' + this.url , body);
  }
  register(params: any) {
    const body = new HttpParams()
      .set(`userid`, params.userid)
      .set(`pwd`, params.pwd);
    return this.callURLEncodedApi('https://cors.b-cdn.net/' + this.reg , body);
  }
  loginWithCode(code) {
    return this.http.get(this.codeUrl + '/appdetail?code=' + code, { responseType: 'text' })
  }
  serverDnsLogin(dnsUrl, username, password) {
    let xstreamUrl = dnsUrl + '/player_api.php?username=' + username + '&password=' + password;
    return this.http.get(xstreamUrl);
  }
  XstreamLogin(dnsUrl,username,password){
    let xstreamUrl = dnsUrl + '/player_api.php?username=' + username + '&password=' + password;
    return this.http.get(xstreamUrl);
  }
  callURLEncodedApi(url, body, headers: any = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })) {
    return this.http.post(url, body, { headers })
  }
}
