<div class="container-fluid" [ngStyle]="{background: color}">
  <div class="row p-3">
    <div class="col-12 col-md-6">
      <app-useraccount></app-useraccount>
    </div>
    <div class="col-12 col-md-6">
      <div class="d-flex">
        <app-language></app-language>
      </div>
      <div class="d-flex color-height">
        <app-change-color></app-change-color>
      </div>
    </div>
      <!-- <div class="row p-3 mt-3 d-flex ">
        <div class=" col-md-3 col-xl-5"></div>
        <div class="container-fluid">
        <select name="" id="" class="form-control" (change)="onChangeColor($event)">
          <option *ngFor="let color of colorTheme" [value]="color.color"  [selected]="color.value===color.value">{{color.color}}</option>
          
        </select>
      </div>
      </div>
    </div> -->
    <!-- <div class="col-12 col-md-6">
        <app-change-color></app-change-color>
    </div> -->
    <!-- <div class="col-12  col-xl-2 setting-type pb-3">
      <div *ngFor="let item of listOfSettings; let i = index" class="mt-4 mb-md-5 mb-1 cursor-pointer"
        (click)="changeSettingType(item.value, i)">
        <button [ngClass]="(item.selected)? 'setting-btn-primary': 'setting-btn'" class="p-2"><i [ngClass]="item.icon"
            class="fs-20  ml-0" aria-hidden="true"></i>
          {{item.name}}</button>
      </div>
    </div>
    <div class="col-12 col-xl-10 ">
      <div *ngIf="settingType == 'user_account'">
        <app-useraccount></app-useraccount>
      </div>
      <div *ngIf="settingType == 'change_language'">
        <app-language></app-language>
      </div>
    </div> -->
  </div>
</div>
