<div class="container-fluid">
  <div class="row ml-lg-1 ">
    <div class="col-2 mt-4 d-none d-lg-block">
      <div class="row">
        <div class="col-4 mb-2 ml-1 p-0 mt-1 pr-1 d-flex mr-3">
          <h4 class="categories-res">{{'NAV.NAV7' | translate}}</h4>
        </div>
        <div class="col-3 mb-2 ml-auto mt-0 pr-1 d-flex mr-1">

          <!-- <div class="sort-back border-dark text-white fs-22 px-2" (click)="sort('category_name')">
            <i class="fas fa-sort-alt"></i></div> -->
          <button type="button" [popover]="popTemplateCat" class="sort-back border-dark text-white fs-22 px-2 "
            placement="right">
            <i class="fas fa-sort-alt"></i></button>
          <ng-template #popTemplateCat>
            <div *ngFor="let item of sortButton; let i = index" class="mt-2">
              <button [ngClass]="{'btn-purple': item.selected}" (click)="sort('category_name',item.val)" type="button"
                class="btn sort-btn btn-dark">
                {{translateFilter(i) | translate}} </button>
            </div>
          </ng-template>
        </div>
        <div class="col-12 text-center p-0 mt-1">
          <div class="d-flex flex-column bd-highlight category-box" *ngFor="let category of categories; index as i" [ngClass]="{'bd-highlight-selected': category.select}" >
            <a class="p-2 bd-highlight over mt-2" (click)="updateCategory(category.category_id)"
            style="cursor: pointer;text-decoration: none;">{{category.category_name}}</a>
          </div>
          <!-- [ngClass]="{'btn-selected': category.select}"  -->
        </div>
      </div>
    </div>
    <div class="col-10 mb-0 pb-0 d-none d-lg-block">
      <app-series-grid [selectedcategory]="selectedcategory" [isLoading]="isLoading"></app-series-grid>
    </div>
    <div class="col-12 mt-4 d-lg-none d-block p-res">
      <div class="row">
        <div class="col-12 text-center" (click)="isCategory=!isCategory">
          <p class="sub-text border p-2">{{ 'COMMON.CATEGORIES' | translate }}
            <i class="fa fa-chevron-down ml-2"></i></p>
        </div>
        <div class="col-12 px-0" *ngIf="isCategory">

          <!-- <div class="sort-back border-dark text-white fs-22 ml-auto mr-2" (click)="sort('category_name')">
              <i class="fas fa-sort-alt"></i></div> -->
          <div class="d-flex mb-2 ml-auto mt-1 mr-3 px-3 mr-3">
            <button type="button" [popover]="popTemplateCat" class="sort-back border-dark text-white fs-22 ml-auto ">
              <i class="fas fa-sort-alt"></i></button>
          </div>
          <ng-template #popTemplateCat>
            <div *ngFor="let item of sortButton" class="mt-2">
              <button [ngClass]="{'btn-purple': item.selected}" (click)="sort('category_name',item.val)" type="button"
                class="btn sort-btn btn-dark">
                {{ item.label }}
              </button>
            </div>
          </ng-template>
          <div class="col-12 ">
            <div class="d-flex flex-column bd-highlight1 category-box">
              <a class="p-2 bd-highlight over mt-2" (click)="updateCategory(category.category_id)"
                *ngFor="let category of categories; index as i" [ngClass]="{'bd-selected': category.select}"
                style="cursor: pointer;text-decoration: none;">{{category.category_name}}</a>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-12 m-0 p-0 d-lg-none d-block">
      <app-series-grid [selectedcategory]="selectedcategory"></app-series-grid>
    </div>
  </div>
</div>
