<form #urlForm="ngForm" class="m-auto auth auth-background">
    <div class="container-fluid">
      <div class="row pi-login  justify-content-center align-items-center">
        <div class="col-md-12 ">
          <div class="header-w3l">
            <div class="logo ml-2">
              <h3 class="text-center"><a href="#">{{ 'COMMON.TITLE' | translate }}</a></h3>
            </div>
          </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12 ">
                <div class="m3u-header d-flex justify-content-center">
                    <h3>{{'COMMON.M3U_LOGIN' | translate}}</h3>
                </div>
            </div>
            <div class="col-md-12  col-lg-12 pl-4 pr-4">
                <div class="playlist-name mt-2">
                    <label for="">{{'LABEL.PLAYLIST_NAME' | translate}}</label>
                    <input type="text" class="form-control in" #playlistname="ngModel"
                    [(ngModel)]="playListModal.name" name="playlistname" required>
                </div>
                <div *ngIf="urlForm.submitted && playlistname.invalid" class="">
                    <p class="error-msg mb-0 fs-14">{{ 'FORMERROR.PLAYLIST_NAME' | translate }}</p>
                  </div>
                <div class="playlist-name mt-3">
                    <label for="">{{'LABEL.PLAYLIST_URL' | translate}}</label>
                    <input type="text" class="form-control in" id="url" placeholder="http://url_here.com:port" required
                    [(ngModel)]="playListModal.url" name="url" #url="ngModel" [pattern]="url_pattern">
                </div>
                <div *ngIf="urlForm.submitted && url.invalid" class="">
                    <p class="error-msg mb-0 fs-14" *ngIf="url.errors.required">{{ 'FORMERROR.PLAYLIST_URL' | translate }}</p>
                    <p class="error-msg mb-0 fs-14" *ngIf="urlForm.controls.url.errors.pattern">
                      {{ 'FORMERROR.VALID_URL' | translate }}</p>
                  </div>
                <div class="playlist-name mt-3">
                    <label for="">{{'LABEL.EPG_URL' | translate}}</label>
                    <input type="text" class="form-control in">
                </div>
                <div class="row mt-4">
                <div class="col-md-1 d-flex align-items-center justify-content-center">

                        <i class="fa fa-arrow-left" aria-hidden="true" routerLink="/add-playlist"></i>     
                </div>
                <div class="col-md-6 d-flex align-items-start">
                    <div class="add-btn">
                        <!-- (click)="addPlaylistM3u(urlForm)" -->
                        <button class="btn btn-color" >
                            {{'BUTTON.ADDPLAYLIST_BTN' | translate}}

                        </button>
                    </div>
                    <div class="add-btn ml-3">
                        <button class="btn btn-color">
                            {{'BUTTON.SUBMIT_BTN' | translate}}
                        </button>
                    </div>
                </div>
                <div class="col-md-5 d-flex justify-content-end">
                </div>
                </div>
            </div>
        </div>






      
        </div>
    </div>
</form>
