import { Component, OnInit ,Inject } from '@angular/core';
import { PlaylistService } from '../../services/playlist.service';
import { MessageService } from '../../services/message.service';
import { UtilService } from '../../services/util.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { throwError ,pipe} from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { DOCUMENT } from '@angular/common';
import { Constant } from '../../services/data';

import { Platform } from '@angular/cdk/platform';
import { AuthentictionService } from '../../services/authentiction.service';



@Component({
  selector: 'app-xstream-login',
  templateUrl: './xstream-login.component.html',
  styleUrls: ['./xstream-login.component.css']
})
export class XstreamLoginComponent implements OnInit {

  playListModal: any = {
    type: 'fromUrl',
    name: '',
    username: '',
    password: '',
    url: '',
    code:'',
    epg:'',
    pin:'',
    confirm_pin:'',
  };
  xcode:boolean=false;
  playList: any = [];
  xstreamList: any;
  isLoading: boolean = false;
  url_pattern = "([a-z0-9-]+\:\/+)([^\/\s]+)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#\r\n]*)#?([^ \#\r\n]*)";
  codeData:any =[];
  code:any;
  urlValue = '';
  platformInfo: string;
  browserInfo:any
  type='password'
  constructor(private platform:Platform,private http:HttpClient ,private utilService: UtilService, private playlistService: PlaylistService,private authService:AuthentictionService, private messageService: MessageService, private router: Router)
   {
   
    }

  ngOnInit(): void {

    
    // this.getPlayList()
    
  }
  

  getPlayList() {
    this.playList = [];
    let passModel: any = {
      userid: ''
    };
    // this.XuserId = this.utilService.getXstreamUserId();
    // if (this.XuserId) {
    //   passModel.userid = this.XuserId;
    // } else {
    passModel.userid = this.utilService.getFullUserId();
    //}

    this.isLoading = true;
    this.playlistService.getPlayList(passModel).subscribe((res: any) => {
      this.isLoading = false;
      if (res.http_code == 200) {
        if (res.m3u) {
          res.m3u.map((ele: any) => {
            if (ele._id) {
              ele.isM3u = true;
              this.playList.push(ele);
            }
          });
        }
        if (res.xstream) {
          res.xstream.map((ele: any) => {
            ele.isXstream = true;
            this.playList.push(ele);
          });

        }
        console.log(this.playList);
      }
    }, err => {
      this.isLoading = false;
      this.utilService.showErrorCall(err);
    });

  }

  checkDuplicateName(name) {
    let found = false;
    for (let index = 0; index < this.playList.length; index++) {
      if (this.playList[index].name === name) {
        found = true;
        this.utilService.showErrorToast('', 'Playlist name must be unique');
        break;
      }
    }
    return !found;
  }
  

  addPlaylistXstream(form: NgForm) {
  if (form.valid && this.checkDuplicateName(this.playListModal.name)) {
  let passModel = new FormData();
  passModel.append('name', this.playListModal.name);
  passModel.append('url', this.playListModal.url);
  passModel.append('password', this.playListModal.password);
  passModel.append('username', this.playListModal.username);

      this.isLoading = true;
      this.authService.XstreamLogin(passModel.get('url'),passModel.get('username'),passModel.get('password')).subscribe((res: any) => {
        console.log("playlistdata",res.user_info);
        this.isLoading = false;
        if (res.user_info.status === 'Active') {
          this.utilService.saveValueStorage(Constant.XSTREAMUSERNAME_KEY, res.user_info.username);
          this.utilService.saveValueStorage(Constant.XSTREAMPASSWORD_KEY, res.user_info.password);
          this.utilService.saveValueStorage(Constant.XSTREAMURL_KEY, this.playListModal.url);
          this.router.navigate(['/home']);
          this.utilService.showErrorSuccess('', "Login Succesfully.");
        }
      }, err => {
        this.isLoading = false;
        this.utilService.showErrorCall("Please enter correct useid password or url",err);
      });
    }
  }
  addPlaylistXstreams(form: NgForm) {
    if (form.valid && this.checkDuplicateName(this.playListModal.name)) {
      let passModel: any = {
        url: this.playListModal.url,
        name: this.playListModal.name,
        password: this.playListModal.password,
        username: this.playListModal.username
      };
      this.isLoading = true;
      this.playlistService.addPlaylistXstreamNew(passModel).subscribe((res: any) => {
        console.log("playlistdata",res.user_info);
        this.isLoading = false;
        if (res.user_info.status === 'Active') {
          this.utilService.saveValueStorage(Constant.XSTREAMUSERNAME_KEY, res.user_info.username);
          this.utilService.saveValueStorage(Constant.XSTREAMPASSWORD_KEY, res.user_info.password);
          this.utilService.saveValueStorage(Constant.XSTREAMURL_KEY, this.playListModal.url);
          this.router.navigate(['/home']);
          this.utilService.showErrorSuccess('', "Login Succesfully.");
        }
      }, err => {
        this.isLoading = false;
        this.utilService.showErrorCall(err);
        
      });
    }
  }
  
hideShowPassword(){
  if(this.type === 'password'){
    this.type = 'text';
  }
  else{
    this.type='password';
  }
}
  

}
