<h3 class="header-label  text-center my-md-1 my-2">{{ 'HOME.CATEGORY1' | translate }}</h3>
<div class="row pt-3">
  <div class="col-12 col-md-7 offset-md-2 ">
    <div class="user-data-feild mb-3 d-flex py-2">
      <span class="ml-3 field-label">{{ 'USERINFO.USER1' | translate }}</span>
      <div class="pl-md-3 pl-1 field-label">:</div>
      <div class=" pl-md-3 px-2 field-value">{{authuser?.user_info.username}}</div>
    </div>
  </div>
  <div class="col-12 col-md-7 offset-md-2">
    <div class="user-data-feild mb-3 d-flex py-2">
      <span class="ml-3 field-label">{{ 'USERINFO.USER2' | translate }}</span>
      <div class="ml-3 field-label">:</div>
      <div class="pl-md-3 px-2 field-value">{{authuser?.user_info.status}}</div>
    </div>
  </div>
  <div class="col-12 col-md-7 offset-md-2">
    <div class="user-data-feild mb-3 d-flex py-2">
      <span class="ml-3 field-label">{{ 'USERINFO.USER3' | translate }}</span>
      <div class="ml-3 field-label">:</div>
      <div class="pl-md-3 px-2 field-value">{{authuser?.user_info.is_trial}}</div>
    </div>
  </div>
  <div class="col-12 col-md-7 offset-md-2">
    <div class="user-data-feild mb-3 d-flex py-2">
      <span class="ml-3 field-label">{{ 'USERINFO.USER4' | translate }}</span>
      <div class="ml-3 field-label">:</div>
      <div class="pl-md-3 px-2 field-value">{{authuser?.user_info.active_cons}}</div>
    </div>
  </div>
  <div class="col-12 col-md-7 offset-md-2">
    <div class="user-data-feild mb-3 d-flex py-2">
      <span class="ml-3 field-label">{{ 'USERINFO.USER5' | translate }}</span>
      <div class="ml-3 field-label">:</div>
      <div class="pl-md-3 px-2 field-value">{{authuser?.user_info.max_connections}}</div>
    </div>
  </div>
  <div class="col-12 col-md-7 offset-md-2">
    <div class="user-data-feild mb-3 d-flex py-2">
      <span class="ml-3 field-label">{{ 'USERINFO.USER6' | translate }}</span>
      <div class="ml-3 field-label">:</div>
      <div class="pl-md-3 px-2 field-value">{{mjm}}</div>
    </div>
  </div>
  <div class="col-12 col-md-7 offset-md-2">
    <div class="user-data-feild mb-3 d-flex py-2">
      <span class="ml-3 field-label">{{ 'USERINFO.USER7' | translate }}</span>
      <div class="ml-3 field-label">:</div>
      <div class="pl-md-3 px-2 field-value">{{mjm1}}</div>
    </div>
  </div>
</div>
<div class="text-center mt-5">
  <button style="width: 200px;" class="logout-btn" (click)="logout()">
    <i class="fa fa-sign-in text-white fs-20 mr-2 ml-0" aria-hidden="true"></i>
    {{ 'HOME.LOGOUT' | translate }}
  </button>
</div>