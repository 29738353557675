import { NgModule } from '@angular/core';


import { DefaultImageDirective } from './default-image.directive';

@NgModule({
    imports: [],
    exports: [ DefaultImageDirective],
    declarations: [DefaultImageDirective],
    providers: [],
})
export class DirectiveModule { }
