import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class LivestreamService {

  domain: any = '';
  username: any = '';
  password: any = '';
  url: any = '';

  Xurl: any;
  

  constructor(public http: HttpClient, public utilService: UtilService) {
  }
  getDataLcal() {
    this.Xurl = this.utilService.getXstreamUrl();
    console.log("Xurl",this.Xurl);
    
    if (this.Xurl) {
      this.domain = this.utilService.getXstreamUrl();
      this.username = this.utilService.getXstreamUsername();
      this.password = this.utilService.getXstreamPassword();
      console.log("domain",this.domain);
      
    }
    else {
      this.domain = this.utilService.getFullUrl();
      this.username = this.utilService.getFullUsername();
      this.password = this.utilService.getFullPassword();
    }
    this.url =  this.domain + '/player_api.php?username=' + this.username + '&password=' + this.password + '&action=';
    console.log(this.username, this.password);
    return this.url;

  }
  public getliveCategories() {
    return this.http.get(this.getDataLcal() + 'get_live_categories');
  }
  public getCategorywiseChannelList(id: any) {
    return this.http.get(this.getDataLcal() + 'get_live_streams&category_id=' + id);
  }
  public getStreamURL(streamid: any) {
    this.getDataLcal();
    return (this.domain + '/live/' + this.username + '/' + this.password + '/' + streamid + '.m3u8').replace(environment.cdnurl,'');
  }
  public getLivestreamAll(){
    return this.http.get(this.getDataLcal() + 'get_live_streams');
  }
  public getshortEpg(id:any){   
    return this.http.get(this.getDataLcal() + ' get_short_epg&stream_id='+id);
  }
}
