import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import videojs from 'video.js';
import { MovieService } from 'src/app/Core/services/movie.service';
import { UtilService } from 'src/app/Core/services/util.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-movieplayer',
  templateUrl: './movieplayer.component.html',
  styleUrls: ['./movieplayer.component.css']
})
export class MovieplayerComponent implements OnInit {
  id: string;
  movieinfo: any;
  stream_Url: string;
  extension: any;
  catId:any
  constructor(private router: Router,
    private route: ActivatedRoute, private movieService: MovieService, private utilService: UtilService,private location:Location) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getMovieInfo();
    } setTimeout(() => {
      this.playMovie();
    }, 1500);
   this.catId =localStorage.getItem('catId')
  }

  getMovieInfo() {
    this.movieService.getMovieDeatil(this.id).subscribe((res: any) => {
      this.movieinfo = res;
      if (this.movieinfo) {
        this.extension = this.movieinfo.movie_data.container_extension;
        //this.playMovie();
      }
    }, err => {
      this.utilService.showErrorCall(err);
    });
  }

  playMovie() {
    this.stream_Url = this.movieService.getStremMovieUrl(this.id, this.extension);
    console.log("playmovie",this.stream_Url);
    //this.stream_Url = 'https://www.w3schools.com/html/mov_bbb.mp4';
    setTimeout(() => {
      videojs("example-videoMovie").src([
        { type: "video/mp4", src: this.stream_Url },
      ]);
      var player = videojs("example-videoMovie");
      player.on('ended', function () {
        // this.location.back()
        this.dispose();
        window.close();
      });
      // When you pass text in options it just creates a control text,
      // which is displayed as tooltip when hovered on 
      // this button viz the span in you div,
      var myButton = player.controlBar.addChild("button");

      // There are many functions available for button component 
      // like below mentioned in this docs 
      // https://docs.videojs.com/button. 
      // You can set attributes and clasess as well.

      // Getting html DOM
      var myButtonDom = myButton.el();
      // Since now you have the html dom element 
      // you can add click events

      myButtonDom.innerHTML = "EXIT";

      myButtonDom.onclick = function () {
        // alert("Are you sure?");
        window.location.href = "/movies";
      }
      // this.router.navigate(['/movies/watch/' + this.id]);
    }, 200);

  }

  onMouseOver(event:any){
   console.log(event);
   
    
  }
  backToInfo(){
      // this.router.navigate(['/movies/watch/' + this.id]);
      this.router.navigate([`movies/info/${this.id}?movieBy=movies&categoryId=${this.catId}`])

    // this.dispose();
    // window.close();
    // this.router.navigate(['/movies'])
  }

}
