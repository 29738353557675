import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SeriesService } from 'src/app/Core/services/series.service';
import { UtilService } from 'src/app/Core/services/util.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Constant } from 'src/app/Core/services/data';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-series-detail',
  templateUrl: './series-detail.component.html',
  styleUrls: ['./series-detail.component.css']
})
export class SeriesDetailComponent implements OnInit {
  public isLoading: boolean = false;
  isFavourit: boolean = false;
  favSeries: any;
  id: any;
  seriesinfo: any;
  episodeLen: any;
  seasonLen: any;
  seasonId: any = 1;
  episodes: any;
  allepisodes: any = [];
  JSONDatas = [
  ];
  slideConfig = {
    "slidesToShow": 4, "slidesToScroll": 4, responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  seriesFrom: any;
  categoryId: any

  constructor(private location: Location, private router: Router, private seriesService: SeriesService, private utilService: UtilService,
    private route: ActivatedRoute, private toastr: ToastrService,private translate:TranslateService) {
    this.route.queryParams.subscribe((params: any) => {
      this.seriesFrom = params.seriesBy;
      this.categoryId = params.categoryId;
    })
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getSeriesInfo(this.id);
    if (this.id) {
      let isExist: any;
      this.favSeries = JSON.parse(localStorage.getItem(Constant.SERIES_FAV)) || [];
      if (this.favSeries.length > 0) {
        isExist = _.findIndex(this.favSeries, { id: Number(this.id) });
        if (isExist >= 0) {
          this.isFavourit = true;
        }
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      $(".center").slick({
        accessibility: true,
        arrows: true,
        dots: true,
        infinite: true,
        centerMode: true,
        slidesToShow: 5,
        slidesToScroll: 3
      });
    }, 2000);

  }
  getSeriesInfo(id: any) {
    this.utilService.showSpinner()
    this.isLoading = true;
    this.seriesService.getSeriesDetail(id).subscribe((res: any) => {
      this.utilService.hideSpinner()
      this.isLoading = false;
      this.seriesinfo = res;
      
      this.seriesinfo.selectedEpisodes = this.seriesinfo.episodes[this.seasonId];
      this.episodeLen = 0;
      this.episodes = res.episodes;
      if (this.episodes) {
        Object.keys(res.episodes).forEach(key => {
          this.episodeLen = this.episodeLen + res.episodes[key].length;
        });
      }
      this.seasonLen = res.seasons.length;
    }, err => {
      this.isLoading = false;
      this.utilService.hideSpinner()

      this.utilService.showErrorCall(err);
    });
  }
  onSelectSeason(seasonId: any) {
    console.log(this.seasonId,'season');
    this.isLoading = true;
    this.seasonId = seasonId;
    this.seriesinfo.selectedEpisodes = [];
    setTimeout(() => {
        this.isLoading = false;
      this.seriesinfo.selectedEpisodes = this.seriesinfo.episodes[this.seasonId];
    }, 0);
  }
  onSave(item: any) {
    let savedArray = [];
    let isExist: any;
    const msg =this.translate.instant('COMMON.ADDED_SUCCESS_MSG')

    savedArray = JSON.parse(localStorage.getItem(Constant.SERIES_FAV)) || [];
    if (savedArray.length > 0) {
      isExist = _.findIndex(savedArray, { id: Number(this.id) });
      if (isExist == -1) {
        item.isList = false;
        savedArray.push({ id: Number(this.id), img: item.info.backdrop_path, name: item.info.name, fullUrl: localStorage.getItem(Constant.FULLURL_KEY) });
        localStorage.setItem(Constant.SERIES_FAV, JSON.stringify(savedArray));
        this.isFavourit = true;
        this.utilService.showErrorSuccess(`${msg}`);
      }
      if (isExist >= 0) {
        this.utilService.showErrorInfo('Item alreadey added to favourite')
      }

    }
    else { //first
      item.isList = false;
      this.isFavourit = true;
      savedArray.push({ id: this.id, img: item.info.backdrop_path, name: item.info.name, fullUrl: localStorage.getItem(Constant.FULLURL_KEY) });
      localStorage.setItem(Constant.SERIES_FAV, JSON.stringify(savedArray));
      this.utilService.showErrorSuccess(`${msg}`);

    }

  }
  deleteFavourite(item: any) {
    item.id = Number(this.id);
    const msg = this.translate.instant('COMMON.REMOVE_SUCCESS_MSG')

    this.utilService.deleteFavourit(Constant.SERIES_FAV, item, (result: any) => {
      if (result) {
        this.utilService.showErrorSuccess(`${msg}`);
        this.isFavourit = false;
      }
    });
  }

  // playMovies(movieId) {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['/movies/watch/' + movieId])
  //   );
  //   window.open(url, '_blank');
  // }

  back(catId: any) {
    // this.router.navigate(['/series'], { queryParams: { category: catId } });
    if (this.seriesFrom == 'search') {
      this.router.navigate(['search']);
    } else if (this.seriesFrom == 'series') {
      this.router.navigate(['/series'], { queryParams: { category: catId } });
    } else if (this.seriesFrom == 'favourites') {
      this.router.navigate(['/favourites']);
    } else {
      this.router.navigate(['/series'], { queryParams: { category: catId } });
    }
  }
}