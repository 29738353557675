import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthentictionService } from '../../services/authentiction.service';
import { UtilService } from '../../services/util.service';
import { Constant } from '../../services/data';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('ngOtpInput', { static: false }) ngOtpInputRef: any | undefined;
  public isLoading: boolean = false;
  public hidelogin:boolean =true
  public show: boolean = true;
  public user: any = {
    username: '',
    otp: '',
    rememberMe: false,
  };
public passwordTextType: boolean;
  public config: any = {
    length: 4, allowNumbersOnly: true, isPasswordInput: true, disableAutoFocus: true, inputStyles: {
      'margin': '0px 8px', 'background': '#000000',
      'color': 'white', 'font-size': '32px', 'outline': 'none'
    }
  };

  toggleOtp() {
    this.ngOtpInputRef.config.isPasswordInput = this.show;
    (this.show) ? (this.ngOtpInputRef.inputType = 'tel') : (this.ngOtpInputRef.inputType = 'password');
    // this.cdRef.detectChanges();
  }

  type = 'password';

  constructor(
    private router: Router, private authService: AuthentictionService, private utilService: UtilService, private changed: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  fillForm() {
    if (this.utilService.getValueCookie(Constant.ISREMEMBER_FULLKEY)) {
      this.user.username = this.utilService.getValueCookie(Constant.FULLUSERNAME_KEY);
      this.user.otp = this.utilService.getValueCookie(Constant.FULLPASSWORD_KEY);
      this.ngOtpInputRef.setValue(this.user.otp);
      this.changed.detectChanges();
    }
  }
  ngAfterViewInit(): void {
    this.fillForm();
  }
  checkCodeLogin() {
    let xtoken = localStorage.getItem('Xtoken');

    if (xtoken) {
      this.router.navigate(['/server-login']);

    } else {
      this.router.navigate(['/code']);
    }

  }
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }
  onSubmit(form: NgForm) {
    if (form.valid && this.user.otp.length > 3) {
      this.isLoading = true;
      this.utilService.showSpinner()

      let param: any = {
        userid: this.user.username,
        pwd: this.user.otp
      };

      this.authService.login(param).subscribe((res: any) => {
        this.isLoading = false;
       this.utilService.hideSpinner()

        if (res.http_code === 200) // userid
        {


          if (res.xstream || res.m3u) {
            if (res.xstream)
              this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, res.xstream[0].userid);
            else
              this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, res.m3u[0].userid);
          }
          if (this.user.rememberMe) {
            this.utilService.clearCookieStorage();
            this.utilService.saveValueCookie(Constant.ISREMEMBER_FULLKEY, true);
            this.utilService.saveValueCookie(Constant.FULLUSERNAME_KEY, this.user.username);
            this.utilService.saveValueCookie(Constant.FULLPASSWORD_KEY, this.user.otp);
          }
          else {
            this.utilService.clearCookieStorage();
          }

          if (!res.xstream && !res.m3u) {
            this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, this.user.username);
          }

          this.router.navigate(['playlist'], { queryParams: { isRemember: this.user.rememberMe } });
          this.utilService.showErrorSuccess('', "Login Succesfully.");


          // let uid = res.xstream[0].userid;
          // let uname = res.xstream[0].username;
          // let pin = res.xstream[0].pwd;
          // let url = res.xstream[0].url;
          // this.utilService.removeXsreamStorage();
          // this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, uid);
          // this.utilService.saveValueStorage(Constant.FULLUSERNAME_KEY, uname);
          // this.utilService.saveValueStorage(Constant.FULLPASSWORD_KEY, pin);
          // this.utilService.saveValueStorage(Constant.FULLURL_KEY, url);
          // this.router.navigate(['/home']);
          // }
          // else {
          //  // this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, this.user.username);
          //   this.router.navigate(['playlist']);
          // }
          // this.router.navigate(['playlist']);
          // this.utilService.showErrorSuccess('', "Login Succesfully.");

        }
        if (res.error) {
          this.utilService.showErrorInfo(res.status);
        }
      },(err) =>{
        this.utilService.hideSpinner()
        this.isLoading = false
        if(err && err.error && err.error.detail && err.error.detail.status){
          this.utilService.showErrorToast(err.error.detail.status);
  
        }
        else{
          this.utilService.hideSpinner()
          this.utilService.showErrorToast("Something went wrong!")
        }
  
      })

    }
  }

  otpChange(value: any) {
    this.user.otp = value;
  }
  rememberMe(event: any) {
    this.user.rememberMe = event.target.checked;
  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }
  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }
  hideShowPassword() {
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }
}
