import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MovieService } from 'src/app/Core/services/movie.service';
import { UtilService } from 'src/app/Core/services/util.service';
declare var $: any;
@Component({
  selector: 'app-movies',
  templateUrl: './movies.component.html',
  styleUrls: ['./movies.component.css']
})
export class MoviesComponent implements OnInit {
  isLoading: any;
  isCategory = true;
  filterTerm: string;
  categories: any;
  defaultCategoryList: any;
  categoryId: any;


  selectedcategory: any;
  isDesc: boolean = false;
  column: string = 'CategoryName';
  sortButton: any = [{ label: 'Default', val: 'default', selected: true },
  { label: 'A - Z', val: 'atoz', selected: false },
  { label: 'Z - A', val: 'ztoa', selected: false },
  ];
  translate=['COMMON.DEFAULT','COMMON.A-Z','COMMON.Z-A']
  constructor(private movieservice: MovieService, private utilService: UtilService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((param: any) => {
      this.categoryId = param.category;
      if (this.categoryId)
        this.updateCategory(param.category)
    })
  }

  ngOnInit(): void {
    this.getMovieCategory();
    // if (!this.categoryId) {
    //   this.updateCategory(79);
    // }
  }

  getMovieCategory() {
    this.utilService.showSpinner()
    this.isLoading = true;
    this.movieservice.getVodCategories().subscribe((res: any) => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      this.categories = res;
      this.defaultCategoryList = res;
      this.categories.map((ele: any, index) => {
        if (this.categoryId) {
          if (ele.category_id == this.categoryId) {
            //  this.categoryIdPass=ele.category_id;
            ele.select = true;
          }
        } else {
          if (index == 0) {
            ele.select = true;
            // this.categoryIdPass=ele.category_id;
            this.updateCategory(ele.category_id);
          }
          else { ele.select = false; }
        }
      });
    }, err => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      this.utilService.showErrorCall(err);
    });
  }

  updateCategory(id) {
    this.utilService.showSpinner()
    this.isLoading = true;
    //  this.categoryIdPass=id;
    //  console.log(this.categoryIdPass,id);
    this.utilService.filterResetObservable.next(true);
    this.movieservice.selectedCategoryWiseStreamData(id).subscribe((res: any) => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      this.selectedcategory = res;
      setTimeout(() => {
        $(window).scrollTop(0);
      }, 10);
    }, err => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      this.utilService.showErrorCall(err);
    });
    this.categories?.map((ele: any, index) => {
      (ele.category_id == id) ? ele.select = true : ele.select = false;
    });
  }

  // sort(property) {
  //   this.isDesc = !this.isDesc;//change the direction   
  //   this.utilService.commonSort(this.isDesc, property, this.categories);
  // };
  sort(property, subtype: any) {
    let ascDesc;
    this.sortButton.map((ele: any) => {
      (subtype == ele.val) ? ele.selected = true : ele.selected = false;
    });

    if (subtype == 'default') {
      this.categories = this.defaultCategoryList;
    } else {
      (subtype == 'atoz') ? ascDesc = 'asc' : ascDesc = 'desc';
    }
    if (ascDesc) {
      this.utilService.sortCommonCategorywise(property, ascDesc, this.categories, (callback) => {
        this.categories = callback;
      });
    }
  }

  translateFilter(index:any):string{
    return this.translate[index]
  }
}
