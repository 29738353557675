



  
import { Component, OnInit ,Inject, Renderer2 } from '@angular/core';
import { PlaylistService } from '../../services/playlist.service';
import { MessageService } from '../../services/message.service';
import { UtilService } from '../../services/util.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { throwError ,pipe} from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { DOCUMENT } from '@angular/common';
import { Constant } from '../../services/data';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { AuthentictionService } from '../../services/authentiction.service';



@Component({
  selector: 'app-qr-login',
  templateUrl: './qr-login.component.html',
  styleUrls: ['./qr-login.component.css']
})
export class QrLoginComponent implements OnInit {

  playListModal: any = {
    type: 'fromUrl',
    name: '',
    username: '',
    password: '',
    url: '',
    code:'',
    epg:'',
    pin:'',
    confirm_pin:'',
  };
  xcode:boolean=false;
  playList: any = [];
  xstreamList: any;
  url_pattern = "([a-z0-9-]+\:\/+)([^\/\s]+)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#\r\n]*)#?([^ \#\r\n]*)";
  codeData:any =[];
  code:any;
  urlValue = '';
  platformInfo: string;
  browserInfo:any
  disableButton:boolean=true
  intervalId: any;
  qrCodeData:any = []
  public myAngularxQrCode: string = null;
  public QrCodeWithUrl:string = null;
  qrLoginData:any = []
  isLoading:boolean=false
  constructor(private renderer:Renderer2,private platform:Platform,private http:HttpClient ,private utilService: UtilService, private playlistService: PlaylistService,private authService:AuthentictionService, private messageService: MessageService, private router: Router)
    {
   
    }

  ngOnInit(): void {

    this.getQrCode()
    this.startDispatching()
    // this.callApiWithInterval()
  }
  
  getQrCode(){
    let apiUrlData = {
      is_code:true
    }
    this.utilService.showSpinner()
    this.http.post(`https://cors.b-cdn.net/https://cloud.purpletv.app/register`,apiUrlData).subscribe( res =>{
      this.utilService.hideSpinner()
      this.qrCodeData = res
      this.myAngularxQrCode = this.qrCodeData.code;
      this.QrCodeWithUrl = this.qrCodeData.url+this.qrCodeData.code
      console.log("qrcode",this.qrCodeData.code);
      console.log("url+code",this.qrCodeData.url + this.qrCodeData.code);
    })
  }

  checkQrRegister(code:any){
    const formData = new FormData();
    formData.append('code', code.code);
    this.http.post(`https://cors.b-cdn.net/https://cloud.purpletv.app/login`,formData).subscribe( res =>{
      this.qrLoginData = res
      if(this.qrLoginData.xstream[0] != null)
      {
        console.log(this.qrLoginData.xstream[0],"streamdata");
          this.utilService.saveValueStorage(Constant.XSTREAMUSERNAME_KEY, this.qrLoginData.xstream[0].username);
          this.utilService.saveValueStorage(Constant.XSTREAMPASSWORD_KEY, this.qrLoginData.xstream[0].pwd);
          this.utilService.saveValueStorage(Constant.XSTREAMURL_KEY, this.qrLoginData.xstream[0].url);
          this.router.navigate(['/home'])
          clearInterval(this.intervalId);
      }
      // else if(this.qrLoginData.m3u[0] != null){
      //   console.log(this.qrLoginData.m3u[0],"m3u data");
      //  const decodeUrl = encodeURIComponent(this.qrLoginData.xstream[0].url);
      //   this.router.navigate(['/watchlist'], { queryParams: { url: decodeUrl } });
      // }
      else{
        this.utilService.showErrorSuccess('Something went wrong!')
      }
    },err =>{
      this.utilService.showErrorSuccess('Something went wrong!')
    })
  }

  startDispatching() {
    this.utilService.hideSpinner()
    if (this.disableButton) {
      this.intervalId = setInterval(() => {
        this.checkQrRegister({ code: this.myAngularxQrCode });
      }, 5000);
    }
    // Stop dispatching after 90 seconds
    setTimeout(() => {
      this.onTimeoutComplete()
      clearInterval(this.intervalId);
      this.disableButton = false;
    }, 90000);
  }

  onTimeoutComplete() {
    window.location.reload();
    this.disableButton=true
  }
  ngOnDestroy() {
    // clearInterval(this.intervalId);
    // clearTimeout(this.timeoutId);
  }
  backToLogin(){
    clearInterval(this.intervalId);
    this.router.navigate(['/add-playlist'])
  }
  callApiWithInterval(): void {
    const stopSignal$ = new Subject<void>();
    const interval$ = interval(3000)
      .pipe(
        takeUntil(stopSignal$)
      )
      .subscribe(() => {
        // Make API call here
        // Replace `apiCall()` with your actual API call function
        // this.checkQrRegister({ code: this.myAngularxQrCode })
      });
    setTimeout(() => {
      stopSignal$.next();
      interval$.unsubscribe();
    }, 90000);
  }
  

}
