<div class="container-fluid">
  <div class="row mt-md-4 mt-0 ml-lg-4 ">
    <div class="col col-md mb-5 home-responsive">
      <div class="home-card" style="background-image: url('./assets/images/live tv.png')" routerLink="/livetv"></div>
      <div class="head">
        <div class="live-head text-white">{{'NAV.NAV3' | translate}}</div>
      </div>
    </div>
    <div class="col col-md mb-5  home-responsive">
      <div class="home-card" style="background-image: url('./assets/images/movies (1).png')" routerLink="/movies"></div>
      <div class="head">
        <div class="live-head text-white">{{'NAV.NAV4' | translate}}</div>
      </div>
    </div>
    <div class="col col-md mb-5  home-responsive">
      <div class="home-card" style="background-image: url('./assets/images/series (1).png')" routerLink="/series"></div>
      <div class="head">
        <div class="live-head text-white">{{'NAV.NAV5' | translate}}</div>
      </div>
    </div>
    <div class="col col-md mb-5  home-responsive">
      <div class="home-card" style="background-image: url('./assets/images/settings (1).png')" routerLink="/settings">
        <div class="head">
          <div class="live-head text-white">{{'HOME.SETTINGS' | translate}}</div>
        </div>
      </div>

    </div>
    <div class="col col-md mb-5  home-responsive" style="margin-right: 45px;">
      <div class="home-card" style="background-image: url('./assets/images/search (1).png')" routerLink="/search"></div>
      <div class="head">
        <div class="live-head text-white">{{'NAV.NAV1' | translate}}</div>
      </div>

    </div>
    
  </div>
  <div class="row mx-md-4 mt-4">
    <div class="col-12">
      <h5 class="mr-auto">{{'NAV.NAV4' | translate}}</h5>

      <ngx-slick-carousel class="carousel custom-slider" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let select of movieShowList | slice: 0:500 let i=index;" class="slide">
          <div class="imageContainer-search" >
            <img class="search-img cursor-pointer" [src]="select.stream_icon" alt="{{select.name}}" loading=lazy
              [routerLink]="['/movies/info/'+select.stream_id]" [queryParams]="{movieBy: 'search'}">
            <p class="search-title text-left mt-1" title="{{select.name}}">{{select.name}}</p>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
  <div class="row ml-lg-4 ml-0">
    <div class="col-12 text-left d-flex">
      <h5 class="">{{'NAV.NAV5' | translate}}</h5>
      <!-- <div class="spinner-border spinner-border-sm mt-2 mr-2 ml-3 " role="status" *ngIf="isLoading">
        <span class="sr-only">{{ 'COMMON.LOADING' | translate }}</span>
      </div> -->
    </div>
    <div class="col-12 pr-5">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let select of selectedcategory let i=index;" class="slide">
          <!-- <div class="pr-0">
            <div class="pr-md-3 pr-1 cursor-pointer" [routerLink]="['/series/info/'+select.series_id]"> -->
              <div class="pr-3">
                <!-- pr-md-3 pr-2 -->
                <div class=" cursor-pointer" [routerLink]="['/series/info/'+select.series_id]">
                <img class="search-img" [src]="select.backdrop_path" alt="First slide" style="border-radius: 12px;" loading=lazy>
                </div>
            <p class="search-title text-left m-1" title="{{select.name}}">{{select.name}}</p>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
  
</div>
