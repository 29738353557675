<!-- <iframe width="1904" height="900" autoplay [src]="rony" title="YouTube video player" frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
  (onStateChange)=iFrameOnStateChange($event)></iframe> -->
  
  <div  class="cursor-pointer " >
    <div class="back">
      <i class="fas fa-arrow-alt-left" (click)="backToInfo()"><span class="fs-16 fw-500"> {{ 'COMMON.BACK' | translate }} </span> </i>
    </div>
  </div>
  <div id="player"></div>


