import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UtilService } from 'src/app/Core/services/util.service';


@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {
  constructor(
    private router: Router, private utilService: UtilService
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentUser: any; 
    (this.utilService.getValueStorage('XUrl')) ? (currentUser = this.utilService.getValueStorage('XUrl')) : (currentUser = this.utilService.getValueStorage('fullUrl'))
    console.log(currentUser);
    if ((currentUser)) {           // not logged in so redirect to login page with the return url

      this.router.navigate(['/home']).then(() => { });
    }
    return true;
  }

}
