import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { SeriesService } from 'src/app/Core/services/series.service';
import { UtilService } from 'src/app/Core/services/util.service';

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.css']
})
export class SeriesComponent implements OnInit {
  isLoading: any;
  isCategory = true;
  categories: any;
  categoryId: any;
  selectedcategory: any;
  defaultCategoryList: any;
  filterTerm: string;

  isDesc: boolean = true;
  column: string = 'CategoryName';
  sortButton: any = [{ label: 'Default', val: 'default', selected: true },
  { label: 'A - Z', val: 'atoz', selected: false },
  { label: 'Z - A', val: 'ztoa', selected: false },
  ];
  translate = ['COMMON.DEFAULT','COMMON.A-Z','COMMON.Z-A']
  constructor(private utilService: UtilService, private seriesService: SeriesService, private route: ActivatedRoute,private http:HttpClient) {
    this.route.queryParams.subscribe((param: any) => {
      this.categoryId = param.category;
      if (this.categoryId)
        this.updateCategory(param.category)
    })
  }

  ngOnInit(): void {
    this.getSeriesCategory();
    // this.updateCategory(109);
  }

  getSeriesCategory() {
    this.utilService.showSpinner()
    this.isLoading = true;
    this.seriesService.getSeriesCategories().subscribe((res: any) => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      this.categories = res;
      this.defaultCategoryList = res;
      if (this.categories) {
        this.categories.map((ele: any, index) => {
          if (this.categoryId) {
            if (ele.category_id == this.categoryId) {
              ele.select = true;
            }
          } else {
            if (index == 0) {
              ele.select = true;
              this.updateCategory(ele.category_id);
            }
            else { ele.select = false; }
          }
          // if (index == 0) {
          //   ele.select = true;
          //   this.updateCategory(ele.category_id)
          // } else { ele.select = false; }
        });
      }
    }, err => {
      this.isLoading = false;
    this.utilService.hideSpinner()
    this.utilService.showErrorCall(err);
    });
  }

  updateCategory(id) {
    this.isLoading = true;
    this.utilService.showSpinner()
    this.utilService.filterResetObservable.next(true);
    this.seriesService.selectedCategoryWiseSeriesList(id).subscribe((res: any) => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      if (res) {
        this.selectedcategory = res;
      }
    }, err => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      this.utilService.showErrorCall(err);
    });
    this.categories?.map((ele: any, index) => {
      (ele.category_id == id) ? ele.select = true : ele.select = false;
    });
  }

  // sort(property) {
  //   // this.isDesc = !this.isDesc;
  //   // this.utilService.commonSort(this.isDesc, property, this.categories);
  // };
  sort(property, subtype: any) {
    let ascDesc;
    this.sortButton.map((ele: any) => {
      (subtype == ele.val) ? ele.selected = true : ele.selected = false;
    });

    if (subtype == 'default') {
      this.categories = this.defaultCategoryList;
    } else {
      (subtype == 'atoz') ? ascDesc = 'asc' : ascDesc = 'desc';
    }
    if (ascDesc) {
      this.utilService.sortCommonCategorywise(property, ascDesc, this.categories, (callback) => {
        this.categories = callback;
      });
    }

  }

  translateFilter(index:any):string{
    return this.translate[index]
  }
}
