<div class="about">
  <div class="about__head">
    <img src="assets/icons/android-chrome-192x192.png" alt="logo">
    <h2>{{ 'ABOUT.HUB_MOVIES' | translate }}</h2>
    <small>{{ 'COMMON.VERSION' | translate }} 1.0.0</small>
  </div>
  <div class="about__body">
    <div class="about--container">
      <div class="about--info">
        <mat-icon>bug_report</mat-icon>
        <h3>{{ 'ABOUT.SEND_FEEDBACK' | translate }}</h3>
        <p>{{ 'ABOUT.IF_ANY_COMMENTS_SEND' | translate }} <a
            href="mailto:chabox59@gmail.com?subject=[HubMovies] Feedback">{{ 'COMMON.EMAIL' | translate }}</a></p>
      </div>
    </div>
    <div class="about--container">
      <div class="about--info">
        <mat-icon>perm_identity</mat-icon>
        <h3>{{ 'COMMON.DEVELOPER' | translate }}</h3>
        <p>C. Lamarque</p>
      </div>
    </div>
    <div class="about--container">
      <div class="about--info">
        <mat-icon>movie_filter</mat-icon>
        <h3>{{ 'ABOUT.THE_MOVIE_DATABASE' | translate }}</h3>
        <p>{{ 'ABOUT.THIS_APPLICATION_USE_API_FORM_TMDB_BUT_NOT_CERTIFIED_BY_THEM' | translate }}</p>
      </div>
    </div>
    <div class="about--container">
      <div class="about--info">
        <mat-icon>copyright</mat-icon>
        <h3>2019 {{ 'COMMON.COPYRIGHT' | translate }}</h3>
        <p>{{ 'ABOUT.THIS_APP_NOT_REPRODUCED_ANY_WHATSOVER_WITHOUT_WRITTEN_PERMISSION_OF_THE_OWNER' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
