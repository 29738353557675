<div class="container-fluid " [ngClass]="{'auth-background ': auth}">
  <div class="language-main" [ngClass]="{'auth-height': auth}">
    <div class="row">
      <div class="col-12">
        <h3 class="header-label">
          {{ 'HOME.SELECT' | translate }}
        </h3>
      </div>
      <div class="col-12" [ngClass]="{'mt-3': !auth}">
        <div class="mjm">
          <select #langSelect (change)="changeLanguage($event)" class="w-100 text-capitalize">
            <option *ngFor="let lang of this.languages; let i=index" [value]="lang.value" class="text-capitalize"
              [selected]="lang.value === translate.currentLang">
              {{ getTraslation(i) | translate}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
