<div class="container-fluid auth-background">
  <div class="row m-1">
    <div class="col-md-12 mt-3">
      <div class="header-w3l">
        <div class="logo ml-2">
          <!-- <img class="img-fluid" src="/assets/images/purple-web-player2.png" width="500" alt=""> -->
          <h3>
            <a href="#">{{ "COMMON.TITLE" | translate }}</a>
          </h3>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn button-info-playlist justify-content-center"
      (click)="openModal(template)"
      *ngIf="!isLoading && playList.length == 0"
    >
      <svg
        width="50"
        height="50"
        viewBox="0 0 99 99"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="49.1708" cy="49.1708" r="49.1708" fill="#232530" />
        <g filter="url(#filter0_f_17_848)">
          <circle cx="69.8571" cy="64.7739" r="9.6227" fill="#7123F6" />
        </g>
        <circle
          cx="49.0318"
          cy="48.8259"
          r="30.2811"
          fill="#7123F6"
          fill-opacity="0.2"
          stroke="#7123F6"
          stroke-width="4.47007"
        />
        <path
          d="M46.8281 31.2377C46.8281 29.7601 48.026 28.5623 49.5036 28.5623C50.9812 28.5623 52.1791 29.7601 52.1791 31.2377V67.3566C52.1791 68.8343 50.9812 70.0321 49.5036 70.0321C48.026 70.0321 46.8281 68.8343 46.8281 67.3566V31.2377Z"
          fill="white"
        />
        <path
          d="M31.444 51.9728C29.9664 51.9728 28.7686 50.7749 28.7686 49.2973C28.7686 47.8197 29.9664 46.6218 31.444 46.6218L67.5629 46.6218C69.0406 46.6218 70.2384 47.8197 70.2384 49.2973C70.2384 50.7749 69.0406 51.9728 67.5629 51.9728L31.444 51.9728Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_f_17_848"
            x="49.0592"
            y="43.9761"
            width="41.5955"
            height="41.5957"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="5.58759"
              result="effect1_foregroundBlur_17_848"
            />
          </filter>
        </defs>
      </svg>
      {{ "PLAYLIST.ADD_PLAYLIST" | translate }}
    </button>

    <div class="col-12 my-3">
      <div class="float-right d-flex-center">
        <div
          class="spinner-border spinner-border-sm mr-3"
          role="status"
          *ngIf="isLoading"
        >
          <span class="sr-only">{{ "COMMON.LOADING" | translate }}</span>
        </div>
        <button
          type="button"
          class="btn button-info"
          (click)="openModal(template)"
          *ngIf="!isLoading && playList.length > 0"
        >
          <i
            class="fa fa-plus px-2 mr-2 p-1"
            style="border-right: 1px solid #23242e"
          ></i
          >{{ "PLAYLIST.ADD_PLAYLIST" | translate }}
        </button>
        <button type="button" class="btn button-info ml-3" (click)="onLogout()">
          <i
            class="fa fa-sign-in px-2 mr-2 p-1"
            style="border-right: 1px solid #23242e"
          ></i>
          <span class="links_name">{{ "HOME.LOGOUT" | translate }}</span>
        </button>
      </div>
    </div>
    <div class="col-12">
      <div class="row" *ngIf="playList">
        <div
          class="col-lg-6 col-md-6 col-12 pb-4"
          *ngFor="let item of playList"
        >
          <div
            class="d-flex-center p-3 cursor-pointer pi-play-list-box"
            (click)="onSelectPlaylist(item)"
          >
            <!-- <img src="assets/images/m3u_list.png" class="playlist-img" *ngIf="item.isM3u"> -->
            <img
              src="/assets/images/circle-img.png"
              class="playlist-img"
              *ngIf="item.isXstream"
            />
            <div class="ml-4 name-res">
              <p class="mb-0 fw-500">{{ item.name }}</p>
              <p class="mb-0 fs-14 search-title">{{ item.url }}</p>
            </div>
            <!-- <svg  width="20" height="20" viewBox="0 0 20 20" fill="none" class="ml-auto fs-20 text-white d-none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.47791 14.8287C3.12025 15.3553 2.99804 15.995 3.13816 16.607L3.40961 17.7913L4.65976 17.5342C5.30569 17.4012 5.86939 17.0305 6.22689 16.5038L12.5878 7.13495L9.83879 5.45986L3.47791 14.8287Z"
                fill="white" />
              <path
                d="M14.4429 1.53991C14.2624 1.42979 14.0608 1.35445 13.8495 1.3182C13.6382 1.28196 13.4215 1.28552 13.2117 1.32868C13.0019 1.37184 12.8031 1.45375 12.6267 1.56974C12.4503 1.68572 12.2998 1.8335 12.1838 2.00463L10.5125 4.46697L13.2615 6.14206L14.9335 3.68018C15.0497 3.50916 15.1292 3.31812 15.1675 3.11797C15.2058 2.91783 15.202 2.7125 15.1564 2.51373C15.1109 2.31495 15.0244 2.12664 14.902 1.95954C14.7796 1.79245 14.6236 1.64985 14.4429 1.53991V1.53991Z"
                fill="white" />
              <rect x="7" y="17.1053" width="12" height="1.89474" rx="0.947369" fill="white" />
            </svg> -->
            <!-- <i class="fa fa-trash ml-3 fs-20 ml-auto text-white" aria-hidden="true"
              (click)="deleteItem(item.url,((item.isM3u)?'m3u':'xstream'),$event)"></i> -->
            <svg
              (click)="
                deleteItem(item.url, item.isM3u ? 'm3u' : 'xstream', $event)
              "
              width="50"
              height="50"
              viewBox="0 0 64 63"
              class="ml-3 ml-auto"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <ellipse cx="32" cy="31.5" rx="32" ry="31.5" fill="#232530" />
              <g filter="url(#filter0_d_23_84)">
                <path
                  d="M45.5057 19.7179H40.874C40.5272 18.0317 39.6097 16.5165 38.2761 15.4279C36.9425 14.3392 35.2744 13.7436 33.5529 13.7415L30.5647 13.7415C28.8431 13.7436 27.175 14.3392 25.8414 15.4279C24.5078 16.5165 23.5903 18.0317 23.2435 19.7179H18.6118C18.2155 19.7179 17.8355 19.8753 17.5553 20.1555C17.2751 20.4357 17.1177 20.8157 17.1177 21.212C17.1177 21.6083 17.2751 21.9883 17.5553 22.2685C17.8355 22.5487 18.2155 22.7061 18.6118 22.7061H20.1059V42.1295C20.1083 44.1101 20.8961 46.0089 22.2966 47.4094C23.6971 48.8098 25.5959 49.5977 27.5764 49.6H36.5411C38.5217 49.5977 40.4205 48.8098 41.8209 47.4094C43.2214 46.0089 44.0093 44.1101 44.0116 42.1295V22.7061H45.5057C45.902 22.7061 46.282 22.5487 46.5622 22.2685C46.8424 21.9883 46.9998 21.6083 46.9998 21.212C46.9998 20.8157 46.8424 20.4357 46.5622 20.1555C46.282 19.8753 45.902 19.7179 45.5057 19.7179ZM30.5647 16.7297H33.5529C34.4796 16.7308 35.3833 17.0186 36.1401 17.5536C36.8968 18.0886 37.4696 18.8446 37.7797 19.7179H26.3378C26.648 18.8446 27.2207 18.0886 27.9774 17.5536C28.7342 17.0186 29.6379 16.7308 30.5647 16.7297ZM41.0234 42.1295C41.0234 43.3183 40.5512 44.4584 39.7106 45.299C38.87 46.1396 37.7299 46.6118 36.5411 46.6118H27.5764C26.3876 46.6118 25.2476 46.1396 24.407 45.299C23.5664 44.4584 23.0941 43.3183 23.0941 42.1295V22.7061H41.0234V42.1295Z"
                  fill="white"
                />
                <path
                  d="M29.0708 40.6354C29.467 40.6354 29.8471 40.4779 30.1273 40.1977C30.4075 39.9175 30.5649 39.5375 30.5649 39.1413V30.1766C30.5649 29.7803 30.4075 29.4003 30.1273 29.1201C29.8471 28.8399 29.467 28.6825 29.0708 28.6825C28.6745 28.6825 28.2945 28.8399 28.0143 29.1201C27.7341 29.4003 27.5767 29.7803 27.5767 30.1766V39.1413C27.5767 39.5375 27.7341 39.9175 28.0143 40.1977C28.2945 40.4779 28.6745 40.6354 29.0708 40.6354Z"
                  fill="white"
                />
                <path
                  d="M35.0459 40.6354C35.4421 40.6354 35.8222 40.4779 36.1024 40.1977C36.3826 39.9175 36.54 39.5375 36.54 39.1413V30.1766C36.54 29.7803 36.3826 29.4003 36.1024 29.1201C35.8222 28.8399 35.4421 28.6825 35.0459 28.6825C34.6496 28.6825 34.2696 28.8399 33.9894 29.1201C33.7092 29.4003 33.5518 29.7803 33.5518 30.1766V39.1413C33.5518 39.5375 33.7092 39.9175 33.9894 40.1977C34.2696 40.4779 34.6496 40.6354 35.0459 40.6354Z"
                  fill="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_23_84"
                  x="13.8578"
                  y="13.7415"
                  width="36.4021"
                  height="42.3784"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="3.25987" />
                  <feGaussianBlur stdDeviation="1.62994" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_23_84"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_23_84"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title title-color">
      {{ "PLAYLIST.ADD_PLAYLIST" | translate }}
    </h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6 mb-2">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="loginPlaylist"
            id="inlineRadio1"
            value="fromUrl"
            [(ngModel)]="playListModal.type"
          />
          <label class="form-check-label title-color" for="inlineRadio1">{{
            "PLAYLIST.LOAD_PLAYLIST_FROM_URL" | translate
          }}</label>
        </div>
      </div>
      <div class="col-6 mb-2">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="loginPlaylist"
            id="inlineRadio1"
            [(ngModel)]="playListModal.type"
            value="xstreamUrl"
            required
          />
          <label class="form-check-label title-color" for="inlineRadio1">{{
            "PLAYLIST.LOGIN_WITH_XSTREAM_URL" | translate
          }}</label>
        </div>
      </div>
    </div>
    <form #urlForm="ngForm">
      <div class="row mt-3" *ngIf="playListModal.type == 'fromUrl'">
        <div class="col-12">
          <label for="" class="text-dark mb-0"
            >Playlist Name <sup class="text-danger">*</sup>
          </label>

          <div
            class="form-group"
            [ngClass]="{ 'mb-0': urlForm.submitted && playlistname.invalid }"
          >
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="{{ 'PLACEHOLDER.PLAYLIST_NAME' | translate }}"
              #playlistname="ngModel"
              [(ngModel)]="playListModal.name"
              name="playlistname"
              required
            />
          </div>
          <div
            *ngIf="urlForm.submitted && playlistname.invalid"
            class="float-left"
          >
            <p class="error-msg mb-0 fs-14">
              {{ "FORMERROR.PLAYLIST_NAME" | translate }}
            </p>
          </div>
        </div>
        <div class="col-12">
          <label for="" class="text-dark mb-0"
            >URL<sup class="text-danger">*</sup></label
          >

          <div
            class="form-group"
            [ngClass]="{ 'mb-0': urlForm.submitted && url.invalid }"
          >
            <input
              type="text"
              class="form-control"
              id="url"
              placeholder="http://url_here.com:port"
              required
              [(ngModel)]="playListModal.url"
              name="url"
              #url="ngModel"
              [pattern]="url_pattern"
            />
            <!-- pattern="http(s?)(:\/\/)((www.)?)(([^.]+)\.)?([a-zA-z0-9\-_]+)(\/[^\s]*)?" -->
          </div>

          <div *ngIf="urlForm.submitted && url.invalid" class="float-left">
            <p class="error-msg mb-0 fs-14" *ngIf="url.errors.required">
              {{ "FORMERROR.PLAYLIST_URL" | translate }}
            </p>
            <p
              class="error-msg mb-0 fs-14"
              *ngIf="urlForm.controls.url.errors.pattern"
            >
              {{ "FORMERROR.VALID_URL" | translate }}
            </p>
          </div>
        </div>
        <div class="col-12 mt-3">
          <button
            type="submit"
            class="btn button-info"
            (click)="addPlaylistM3u(urlForm)"
          >
            <i class="fa fa-plus px-2"></i
            >{{ "PLAYLIST.ADD_PLAYLIST" | translate }}
          </button>
        </div>
      </div>
    </form>
    <form #Xform="ngForm">
      <div class="row mt-3" *ngIf="playListModal.type == 'xstreamUrl'">
        <div class="col-12">
          <label for="" class="text-dark mb-0"
            >Playlist Name<sup class="text-danger">*</sup></label
          >
          <div
            class="form-group"
            [ngClass]="{ 'mb-0': Xform.submitted && xplaylist.invalid }"
          >
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="{{ 'PLACEHOLDER.PLAYLIST_NAME' | translate }}"
              #xplaylist="ngModel"
              [(ngModel)]="playListModal.name"
              name=" xplaylist"
              required
            />
          </div>
          <div *ngIf="Xform.submitted && xplaylist.invalid" class="float-left">
            <p class="error-msg mb-0 fs-14">
              {{ "FORMERROR.PLAYLIST_NAME" | translate }}
            </p>
          </div>
        </div>
        <div class="col-12">
          <label for="" class="text-dark mb-0"
            >Username<sup class="text-danger">*</sup></label
          >

          <div
            class="form-group"
            [ngClass]="{ 'mb-0': Xform.submitted && xuname.invalid }"
          >
            <input
              type="text"
              class="form-control"
              id="uname"
              placeholder="{{ 'PLACEHOLDER.USERNAME' | translate }}"
              #xuname="ngModel"
              [(ngModel)]="playListModal.userName"
              name="xuname"
              required
            />
          </div>
          <div *ngIf="Xform.submitted && xuname.invalid" class="float-left">
            <p class="error-msg mb-0 fs-14">
              {{ "FORMERROR.USERNAME" | translate }}
            </p>
          </div>
        </div>
        <div class="col-12">
          <label for="" class="text-dark mb-0"
            >Password<sup class="text-danger">*</sup></label
          >
          <div
            class="form-group"
            [ngClass]="{ 'mb-0': Xform.submitted && xpassword.invalid }"
          >
            <input
              [type]="type"
              class="form-control"
              id="password"
              placeholder="{{ 'PLACEHOLDER.PASSWORD' | translate }}"
              #xpassword="ngModel"
              [(ngModel)]="playListModal.password"
              name="xpassword" maxlength="16"
              required
            />
            <div class="input-append cursor-pointer text-dark" (click)="hideShowPassword()">
              <i class="fas fa-eye-slash " *ngIf="type == 'password'"></i>
              <i class="fas fa-eye " *ngIf="type !== 'password'"></i>
            </div> 
          </div>
          <div *ngIf="Xform.submitted && xpassword.invalid" class="float-left">
            <p class="error-msg mb-0 fs-14">
              {{ "FORMERROR.PASSWORD" | translate }}
            </p>
          </div>
        </div>
        <div class="col-12">
          <label for="" class="text-dark mb-0"
            >URL<sup class="text-danger">*</sup></label
          >
          <div
            class="form-group"
            [ngClass]="{ 'mb-0': Xform.submitted && xurl.invalid }"
          >
            <input
              type="url"
              class="form-control"
              id="url"
              placeholder="http://url_here.com:port"
              #xurl="ngModel"
              [(ngModel)]="playListModal.url"
              name="xurl"
              [pattern]="url_pattern"
              required
            />
            <!-- pattern="http(s?)(:\/\/)((www.)?)(([^.]+)\.)?([a-zA-z0-9\-_]+)(\/[^\s]*)?" -->
          </div>
          <div *ngIf="Xform.submitted && xurl.invalid" class="float-left">
            <p class="error-msg mb-0 fs-14" *ngIf="xurl.errors.required">
              {{ "FORMERROR.PLAYLIST_URL" | translate }}
            </p>
            <p
              class="error-msg mb-0 fs-14"
              *ngIf="Xform.controls.xurl.errors.pattern"
            >
              {{ "FORMERROR.VALID_URL" | translate }}
            </p>
          </div>
        </div>
        <div class="col-12 mt-3">
          <button
            type="submit"
            class="btn button-info"
            (click)="addPlaylistXstream(Xform)"
          >
            <i class="fa fa-plus px-2"></i
            >{{ "PLAYLIST.ADD_PLAYLIST" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
