import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';
@Component({
  selector: 'app-episodes',
  templateUrl: './episodes.component.html',
  styleUrls: ['./episodes.component.css']
})
export class EpisodesComponent implements OnInit {
  id: string;
  rony: any;

  public YT: any;
  public video: any;
  public player: any;
  public reframed: Boolean = false;

  constructor(
    private route: ActivatedRoute, private sanitizer: DomSanitizer, private location: Location) {
    this.id = this.route.snapshot.params['id'];
    const Stream_Url = 'https://www.youtube.com/embed/' + this.id;
    this.rony = this.sanitizer.bypassSecurityTrustResourceUrl(Stream_Url);
    console.log(this.rony);
    
  }

  ngOnInit(): void {
    // this.sourcesObj = {"techOrder":["youtube"], "sources": [{ "type": "video/youtube", "src": "http://www.youtube.com//embed/p_PJbmrX4uk" }]}
    this.video = this.id;
    console.log("video link",this.video);
    
    this.init();
    
  }

  init() {
    if (window['YT']) {
      this.startVideo();
      return;
    }

    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


    /* 3. startVideo() will create an <iframe> (and YouTube player) after the API code downloads. */
    window['onYouTubeIframeAPIReady'] = () => this.startVideo();
    // window['YT'].ready = () => this.startVideo();
  }

  startVideo() {
    const elem = document.getElementById('player')
    this.reframed = false;
    this.player = new window['YT'].Player('player', {
      videoId: this.video,
      width: elem.offsetWidth - 20, height: screen.availHeight - 200,
      playerVars: {
        autoplay: 1,
        modestbranding: 1,
        controls: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 0,
        playsinline: 1
      },
      events: {
        'onStateChange': this.onPlayerStateChange.bind(this),
        'onError': this.onPlayerError.bind(this),
        'onReady': this.onPlayerReady.bind(this),
      }
    });
  }

  /* 4. It will be called when the Video Player is ready */
  onPlayerReady(event) {
    event.target.playVideo();
  }
  /* 5. API will call this function when Player State changes like PLAYING, PAUSED, ENDED */
  onPlayerStateChange(event) {
    switch (event.data) {
      // case window['YT'].PlayerState.PLAYING:
      //   if (this.cleanTime() == 0) {
      //     console.log('started ' + this.cleanTime());
      //   } else {
      //     console.log('playing ' + this.cleanTime())
      //   };
      //   break;
      // case window['YT'].PlayerState.PAUSED:
      //   if (this.player.getDuration() - this.player.getCurrentTime() != 0) {
      //     console.log('paused' + ' @ ' + this.cleanTime());
      //   };
      //   break;
      case window['YT'].PlayerState.ENDED:
        console.log('ended ');
        this.location.back();
        break;
    }
  }
  // cleanTime() {
  //   return Math.round(this.player.getCurrentTime())
  // }
  backToInfo(){
    this.location.back();
  }

  onPlayerError(event) {
    switch (event.data) {
      case 2:
        console.log('' + this.video)
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    }
  }
}
