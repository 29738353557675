<div class="container-fluid movieInfo my-2">
  <div class="row  mx-0 m-lg-1  movieinfoBackground" [ngStyle]="{'background' : 'url('+ seriesinfo?.info.cover +') no-repeat '}"
  class="row  mx-0  m-lg-1 movieinfoBackground">
    <!-- mx-xl-5 -->
    <div class="cursor-pointer fs-16 m-2" style="z-index: 20;">
      <a style="color: white" (click)="back(categoryId)">
        <i class="fas fa-arrow-alt-left"></i> {{ 'COMMON.BACK' | translate }}
      </a>
    </div>

    <div class="text-center col-12 m-auto" *ngIf="isLoading">
      <div class="spinner-border" role="status">
        <span class="sr-only">{{ 'COMMON.LOADING' | translate }}</span>
      </div>
    </div>

    <div class="col-md-12 mt-3" *ngIf="seriesinfo">
      <div class="row mt-0 mt-lg-5 ">
        <!-- <div class="col-xl-4   img-contain mx-auto">
          <img [src]="seriesinfo.info.backdrop_path" class="info-img" alt="No image" loading=lazy>
          <div class="fav-icon icon-color" *ngIf="isFavourit"> <i class="fas fa-heart"></i></div>
        </div> -->

        <div class="col-md-7">
          <div class="row">
            <div class="col-12 text-left">

              <div class="bd-highlight infotitle">{{seriesinfo.info.name}}</div>
              <div class="d-none d-lg-block">
                <div class="d-flex flex-row bd-highlight mb-2">
                  <div class="bd-highlight category">{{seriesinfo.info.releaseDate}} <span
                      class="mx-2 text-purple">|</span>
                  </div>
                  <!-- <div class="bd-highlight category">{{seriesinfo.info.genre | slice:0:15}} 
                  </div> -->
                  <!-- <span class="mx-2 fs-22 text-purple">|</span> -->
                  <div class="bd-highlight category">{{seasonLen}} {{ 'SERIES.SEASONS' | translate }} <span class="mx-2 fs-22 text-purple">|</span>{{episodeLen}}
                    
                    {{ 'SERIES.EPISODES' | translate }}
                  </div>
                    <span class="mx-2 fs-22 text-purple">|</span>

                  <!-- <div type="" class=" mr-lg-4 d-flex fav-center" *ngIf="!isFavourit" (click)="onSave(seriesinfo)"><i
                  class="fa fa-heart  px-3 px-lg-2 py-1"></i>{{ 'COMMON.ADD_TO_FAVOURITE' | translate }}</div>
                  <div type="" class=" mr-lg-4 d-flex fav-center" *ngIf="isFavourit" (click)="deleteFavourite(seriesinfo)"><i
                    class="fa fa-trash  px-3 px-lg-2 py-1"></i>{{ 'COMMON.REMOVE_FROM_FAVOURITE' | translate }}</div> 
                  <div class="d-flex"> -->
                  <div type="" class="ng-star-inserted mb-0 fs-19 fw-700 fav-center" (click)="onSave(seriesinfo)" *ngIf="!isFavourit">
                    <!-- <span class="mx-2 fs-22 text-purple">|</span> -->
                    <i
                    class="fa fa-heart   px-lg-2 py-lg-1 mt-1"></i>{{ 'COMMON.ADD_TO_FAVOURITE' | translate }}</div>
                   <div type="" class="ng-star-inserted  mb-0 fs-19 fw-700 fav-center" (click)="deleteFavourite(seriesinfo)" *ngIf="isFavourit">
                  <!-- <span class="mx-2 fs-22 text-purple">|</span> -->
                  <i class="fa fa-trash px-lg-2 py-lg-1 mt-1"></i>{{ 'COMMON.REMOVE_FROM_FAVOURITE' | translate }}</div>
                </div>
              </div>
              <div class="d-lg-none d-block">
                <div class="fs-14">{{seriesinfo.info.releaseDate}}</div>
                <div class="fs-14">{{seriesinfo.info.genre}}</div>
                <div class="fs-14">{{seasonLen}}{{ 'SERIES.SEASON' | translate }},{{episodeLen}}
                  {{ 'SERIES.EPISODE' | translate }}</div>
                  <p class="fs-16 text-light xs-res" *ngIf="!isFavourit" (click)="onSave(seriesinfo)"><i
                    class="fa fa-heart  pl-0"></i>&nbsp; Add favourits</p>
                 <p class="fs-16 text-light xs-res" *ngIf="isFavourit" (click)="deleteFavourite(seriesinfo)"><i
                  class="fa fa-trash   pl-0"></i>&nbsp; Remove favourits</p>
              </div>
              <div class="mb-3">
                <i class="fa fa-star mx-1" [ngClass]="{'text-purple':(seriesinfo.info.rating/2)>=i+1 }"
                  aria-hidden="true" *ngFor="let item of [1,2,3,4,5];let i=index;"></i>
              </div>
              <div>
                <p class="text-white  content-text  ">{{seriesinfo.info.plot}}</p>
              </div>

              <div class="bd-highlight category">{{seriesinfo.info.genre}} 
              </div> 

              <div class="d-flex-row flex-md-column bd-highlight mt-lg-5 mt-2 mb-2 ml-0">
                <button type="button" class="btn button-info mr-lg-4 mr-2 mb-2"
                  *ngIf="seriesinfo.info.youtube_trailer && seriesinfo.info.youtube_trailer.length > 0;"
                  [routerLink]="['/series/episodes/'+seriesinfo.info.youtube_trailer]"><i
                    class="fa fa-play  px-lg-4 py-lg-1 px-2 res1024"></i>{{ 'COMMON.TRAILER' | translate }}</button>
                <button type="button" class="btn button-info mr-lg-4 mr-2 mb-2" [routerLink]="['/series/list/'+this.id]"
                  [queryParams]="{seriesBy: this.seriesFrom}" *ngIf="seasonLen"><i
                    class="fa fa-film  px-lg-4 py-lg-1 py-1 px-2 res1024"></i>{{ 'SERIES.SEASONS' | translate }}</button>
                <button type="button" class="btn button-info mr-lg-4 mr-2 mb-2" *ngIf="episodeLen"
                  [routerLink]="['/series/episodelist/'+this.id]" [queryParams]="{seriesBy: this.seriesFrom}"><i
                    class="fa fa-video   px-lg-4 py-lg-1 py-1 px-2 res1024"></i>{{ 'SERIES.EPISODES' | translate }}</button>
                <!-- <button type="button" class="btn button-info mb-2 " (click)="onSave(seriesinfo)" *ngIf="!isFavourit"><i
                    class="fa fa-heart   px-lg-4 py-lg-1 py-1 px-2"></i>{{ 'COMMON.ADD_TO_FAVOURITE' | translate }}</button>
                <button type="button" class="btn button-info mb-2 " (click)="deleteFavourite(seriesinfo)" *ngIf="isFavourit"><i class="fa fa-trash   px-lg-4 py-lg-1 py-1 px-2"></i>{{ 'COMMON.REMOVE_FROM_FAVOURITE' | translate }}</button> -->
              </div>
            </div>
            <!-- <div class="col-12 text-left pt-2 pb-4 custom-slider">
              <h4>{{ 'SERIES.SEASONS' | translate }}</h4>

              <ngx-slick-carousel class="carousel custom-slider" #slickModal="slick-carousel" [config]="slideConfig">
                <div ngxSlickItem *ngFor="let item of seriesinfo.seasons;let i=index;" class="slide"
                  (click)="onSelectSeason(item.season_number)">
                  <div class="pr-md-3 pr-4">
                    <a class="cursor-pointer" *ngIf="seriesinfo.episodes">
                      <img class="search-img " [src]="item.cover" loading=lazy
                        [ngClass]="{'border-all':item.season_number==seasonId}" alt="First slide"></a>
                    <a class="cursor-pointer" *ngIf="!seriesinfo.episodes">
                      <img class="search-img " [src]="item.cover" loading=lazy
                        [ngClass]="{'border-all':item.season_number==seasonId}" alt="First slide"
                        [routerLink]="['/series/list/'+this.id]"></a>
                    <p class="episode-title">{{item.name}}</p>
                  </div>
                </div>
              </ngx-slick-carousel>
            </div> -->
            <!-- <div class="col-12 text-left pt-2 pb-4" *ngIf="seriesinfo.episodes && !isLoading">
              <h4>{{ 'SERIES.EPISODES' | translate }}</h4>
              <p *ngIf="!seriesinfo.selectedEpisodes || 
              (seriesinfo.selectedEpisodes && seriesinfo.selectedEpisodes.length == 0)">No Data available</p>
              <ngx-slick-carousel class="carousel custom-slider" #slickModal="slick-carousel" [config]="slideConfig"> -->
                <!--commmented becfore 11-05-23 <div ngxSlickItem *ngFor="let item of seriesinfo.episodes[seasonId];let i=index;" -->
                <!-- <div ngxSlickItem *ngFor="let item of seriesinfo.selectedEpisodes;let i=index;"
                  class="slide cursor-pointer" [routerLink]="['/series/watch/'+item.id+'/'+item.container_extension]">
                  <div class="pr-3">
                    <div class="pr-3">
                      <img class="search-imgseries" [src]="item.info.movie_image" alt="First slide" loading=lazy>
                      <p class="fs-14 search-title " title="{{ item.title }}">{{item.title |slice:0:20}}</p>
                    </div>
                  </div>
                </div>
              </ngx-slick-carousel>
            </div> -->
          </div>
        </div>
        <div class="col-md-5 img-contain mx-auto">
          <div class="image-overlay">

            <img [src]="seriesinfo.info.backdrop_path" class="info-img" alt="No image" loading=lazy>
          </div>
          <div class="fav-icon icon-color" *ngIf="isFavourit"> <i class="fas fa-heart"></i></div>
        </div>
        <div class="col-12 text-left pt-2 pb-4 custom-slider">
          <h4>{{ 'SERIES.SEASONS' | translate }}</h4>

          <ngx-slick-carousel class="carousel custom-slider" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let item of seriesinfo.seasons;let i=index;" class="slide"
              (click)="onSelectSeason(item.season_number)">
              <div class="pr-md-3 pr-4">
                <a class="cursor-pointer" *ngIf="seriesinfo.episodes">
                  <img class="search-img " [src]="item.cover" loading=lazy
                    [ngClass]="{'border-all':item.season_number==seasonId}" alt="First slide">
                  </a>
                <a class="cursor-pointer" *ngIf="!seriesinfo.episodes">
                  <img class="search-img " [src]="item.cover" loading=lazy
                    [ngClass]="{'border-all':item.season_number==seasonId}" alt="First slide"
                    [routerLink]="['/series/list/'+this.id]"></a>
                <p class="episode-title">{{item.name}}</p>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="col-12 text-left pt-2 pb-4" *ngIf="seriesinfo.episodes && !isLoading">
          <h4>{{ 'SERIES.EPISODES' | translate }}</h4>
          <p *ngIf="!seriesinfo.selectedEpisodes || 
          (seriesinfo.selectedEpisodes && seriesinfo.selectedEpisodes.length == 0)">{{'COMMON.DATA_NOT_FOUND' | translate}}</p>
          <ngx-slick-carousel class="carousel custom-slider" #slickModal="slick-carousel" [config]="slideConfig">
            <!-- <div ngxSlickItem *ngFor="let item of seriesinfo.episodes[seasonId];let i=index;" -->
            <div ngxSlickItem *ngFor="let item of seriesinfo.selectedEpisodes;let i=index;"
              class="slide cursor-pointer" [routerLink]="['/series/watch/'+item.id+'/'+item.container_extension]">
              <div class="pr-3">
                <div class="pr-3">
                  <img class="search-imgseries" [src]="item.info.movie_image" alt="First slide" loading=lazy>
                  <p class="fs-14 search-title " title="{{ item.title }}">{{item.title |slice:0:20}}</p>
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
