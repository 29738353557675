import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../../services/category.service';
import { UtilService } from 'src/app/Core/services/util.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  date: any;
  date1: any;
  date2: any;
  date2t: any;
  date3: any;
  date3t: any;
  date4: any;
  date4t: any;
  decode: any;
  decodedata: any;

  constructor(private api: CategoryService,private utilService:UtilService) {
  }

  ngOnInit(): void {
    this.date = new Date();
    this.date1 = this.date;
    this.date2 = this.date.getHours();
    this.date2t = this.date2;
    this.date3 = this.date.getMinutes();
    this.date3t = this.date3;
    this.date4 = this.date.getSeconds();
    this.date4t = this.date4;
    console.log("Date = " + this.date4);

    this.utilService.showSpinner()
    
    this.api.getdecodedata().subscribe(res => {
      this.decode = res;
    this.utilService.hideSpinner()

      //console.log('data response', this.decode);
      const decode1 = atob(this.decode);
      const decode2 = atob(decode1);
      const decode3 = atob(decode2);
      const decode4 = atob(decode3);
      const data = JSON.parse(decode4);
      this.decodedata = data;
    });
  }

}
