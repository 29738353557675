
<div class="container-fluid" style="background: #0D0D0D;height: 950px;">
<!-- <div class="row">
		<app-navbar></app-navbar>
</div> -->
<div class="row" style="position: relative;left: 70px;">
  <div class="col-md-12 col-12">
    
<!--search-->
<div class="row" style="position: relative;left: 20px;">
  <div class="col-md-10">
    <div class="d-flex bd-highlight searchtv">
  <div class="p-2 flex-grow-1 bd-highlight search">{{ 'HOME.SETTINGS' | translate }}</div>
  <div class="p-2 bd-highlight search">{{date2t}}:{{date3t}}:{{date4t}}</div>
  <div class="p-2 bd-highlight search0">{{date1 | date: 'dd'}}-{{date | date:'MM'}}-{{date | date: 'yyyy'}}</div>
</div>
  </div>
</div>
</div>

</div>


 <div class="row wrapper mt-5">
    
    <div class="col-md-12">
       <div class="row">
         <div class="col-md-3 mt-5" style="margin-left: 90px;">
        <app-settings></app-settings>
         </div>
         <div class="col-md-8 ">
           <div class="row">
            <div class="col-md-10 mx-auto">
              <div class="device">
    <h1>{{ 'HOME.CATEGORY3' | translate }}</h1>
  </div>
              

                <div class="d-flex flex-row bd-highlight mb-3 mjm">
  <div class="p-2 bd-highlight refresh livetv">
    <span class="refreshicon"><i class="fa fa-tv"></i></span>
    <h1 class="refreshheader">{{ 'REFRESHINFO.REFRESH1' | translate }}</h1>

</div>

<div class="p-2 bd-highlight refresh movies">
    <span class="refreshicon"><i class="fa fa-film" ></i></span>
    <h1 class="refreshheader">{{ 'REFRESHINFO.REFRESH2' | translate }}</h1>

</div>
</div>


<div class="d-flex flex-row bd-highlight mb-3 mjm1">
  <div class="p-2 bd-highlight refresh livetv">
    <span class="refreshicon"><i class="fa fa-video"></i></span>
    <h1 class="refreshheader">{{ 'REFRESHINFO.REFRESH3' | translate }}</h1>

</div>

<div class="p-2 bd-highlight refresh movies">
    <span class="refreshicon"><i class="fa fa-reply-all"></i></span>
    <h1 class="refreshheader">{{ 'REFRESHINFO.REFRESH4' | translate }}</h1>

</div> 
</div>




            </div>
           </div>
         </div>
       </div>
    </div>
</div>
</div>
