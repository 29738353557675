import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  domain: any = '';
  username: any = '';
  password: any = '';
  url: any = '';
  Xurl: any;

  constructor(public http: HttpClient, public utilService: UtilService) {
  }

  getDataLcal() {
    this.Xurl = this.utilService.getXstreamUrl();
    if (this.Xurl) {
      this.domain = this.utilService.getXstreamUrl();
      this.username = this.utilService.getXstreamUsername();
      this.password = this.utilService.getXstreamPassword();
    }
    else {
      this.domain = this.utilService.getFullUrl();
      this.username = this.utilService.getFullUsername();
      this.password = this.utilService.getFullPassword();
    }
    this.url = this.domain + '/player_api.php?username=' + this.username + '&password=' + this.password + '&action=';    
    return this.url;
  }

  public getLoginUserData() {
    return this.http.get(this.getDataLcal());
  }

}
