import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { UtilService } from 'src/app/Core/services/util.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router, private utilService: UtilService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let currentUser: any        
        (this.utilService.getValueStorage('XUrl')) ? (currentUser = this.utilService.getValueStorage('XUrl')) : (currentUser = this.utilService.getValueStorage('fullUrl'));
        if ((!currentUser)) {        // not logged in so redirect to login page with the return url
            this.router.navigate(['/login']).then(() => { });
        }


        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });

        return true;
    }
}