<div class="container-fluid" style="background: #0D0D0D;">
	<div class="row">
		
			<!-- <app-navbar></app-navbar> -->
		
		<div class="col-md-12">
			 <div class="row" style="position: relative;left: 70px;">
  <div class="col-md-12 col-12">
    
<!--search-->
<div class="row" style="position: relative;">
  <div class="col-md-10">
    <div class="d-flex bd-highlight searchtv">
  <div class="p-2 flex-grow-1 bd-highlight search">{{ 'COMMON.RECENTS' | translate }}</div>
  <div class="p-2 bd-highlight search">{{date2t}}:{{date3t}}:{{date4t}}</div>
  <div class="p-2 bd-highlight search0">{{date1 | date: 'dd'}}-{{date | date:'MM'}}-{{date | date: 'yyyy'}}</div>
</div>
  </div>
</div>
</div>

</div>
			<div class="row" style="position: relative;left: 70px;">
				<div class="col-md-12">
					<div class="row">
						<div class="col-md-12">
							<div class="d-flex flex-wrap" style= "height: 1000px; overflow-y: scroll;">
 						 		
 						 		<div class="p-2 bd-highlight mt-4" *ngFor="let select of recents; index as i">
 						 				
  											<div class="imageContainer" [routerLink]="['/movies/info/'+select.stream_id]">
  												<div type="button" data-toggle="modal" data-target="#exampleModalCenter" data-backdrop="false">
  											<img [src]="select.stream_icon"  width="157px" height="188px" loading=lazy>
  											</div>
  										</div>
  										
  										<div>
  										<h5 class="title">{{select.name | slice:0:22}}</h5><br>
  										<h6 class="date">2016</h6>
                      <!--
  										<span class="duration">‧ 2h 6m
</span>-->
                      <a class="date mjm" [routerLink]="['/movies/watch/'+select.stream_id]"><i class="fas fa-play-circle"></i></a>
  										</div>



  									<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content menulist">


    	<div class="d-flex flex-column bd-highlight mb-3">
  <div class="bd-highlight mt-3 ml-2">
  	<div class="d-flex flex-row bd-highlight mb-3 menudiv">
  <div class="p-2 bd-highlight" class="icon"><i class="fas fa-play-circle"></i></div>
  <div class="p-2 bd-highlight" class="sub"
   [routerLink]="['/movies/watch/'+select.stream_id]"
  >{{ 'COMMON.PLAY' | translate }}</div>
</div>
  </div>
  
   <div class="bd-highlight ml-2">
  	<div class="d-flex flex-row bd-highlight mb-3 menudiv">
  <div class="p-2 bd-highlight" class="icon"><i class="fa fa-info-circle" aria-hidden="true"></i></div>
  <div class="p-2 bd-highlight" class="sub"
  [routerLink]="['/movies/info/'+select.stream_id]"
   >
   {{ 'MOVIE.MOVIEINFO' | translate }}</div>
</div>
  </div>

   <div class="bd-highlight ml-2">
  	<div class="d-flex flex-row bd-highlight mb-3 menudiv">
  <div class="p-2 bd-highlight" class="icon"><i class="fa fa-heart"></i></div>
  <div class="p-2 bd-highlight" class="sub">{{ 'COMMON.ADDTOFAV' | translate }}</div>
</div>
  </div>

   <div class="bd-highlight ml-2">
  	<div class="d-flex flex-row bd-highlight mb-3 menudiv" data-dismiss="modal">
  <div class="p-2 bd-highlight" class="icon"><i class="fas fa-times-circle"></i></div>
  <div class="p-2 bd-highlight" class="sub">{{ 'COMMON.CLOSE' | translate }}</div>
</div>
  </div>

</div>
    	<!--
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
       -->
    </div>
  </div>
</div>

							</div>

  									</div>












						</div>
					</div>
					
				</div>
				
			</div>
			



		</div>
	</div>
</div>




