<div class="container-fluid mb-0 mx-1 pb-0 mt-2 movie">
  <div class="row  mb-0">
    <div class="col-12 px-0">
      <div class="form-group has-search  mr-2 mt-3">
        <span class="fa fa-search form-control-feedback"></span>
        <input type="text" class="form-control" placeholder="{{ 'COMMON.SEARCH_HERE' | translate }}"
          [(ngModel)]="filterTerm" (keyup)="searchSeries()" (keypress)="preventSpace($event)" maxlength="120">
        <button type="button" class="btn bg-transparent searchclear" *ngIf="filterTerm" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <div class="col-12">
      <div class="row movie-box">
        <div class="col-12" *ngIf="filteredList?.length<1">
          <h3 class="d-flex-center mt-5">{{'COMMON.DATA_NOT_FOUND' | translate}}</h3>
        </div>
        <div class="col-md-3 col-lg-3 col-xl-2 col-6 px-2 bd-highlight"
          *ngFor="let select of filteredList|filter:filterTerm; index as i">
          <div>
            <div class="imageContainer-search">
              <div type="button" data-toggle="modal" data-target="#exampleModalCenter" data-backdrop="false">
                <div class="image-overlay" (click)="gotoDetail(select.series_id)">
                  <img [src]="select.backdrop_path" class="search-imgseries" loading=lazy  >
                  <!-- [routerLink]="['/series/info/'+select.series_id]" [queryParams]="{seriesBy: 'series',categoryId:categoryId}"> -->
                </div>
              </div>
              <div class="fav-icon icon-color" (click)="addtoFavourite(select)"> <i class="far fa-heart  cursor-pointer"
                  *ngIf="!select.isFavourit"></i>
              </div>
              <div class="fav-icon icon-color"> <i class="fas fa-heart  cursor-pointer" *ngIf="select.isFavourit"
                  (click)="deleteFavourite(select)"></i>
              </div>
              <div class="d-flex mt-1">
                <p class="search-title text-left" title="{{select.name}}">{{select.name}}</p>
                <i class="fas fa-play-circle fs-18 text-purple ml-auto cursor-pointer" (click)="goToSeasonList(select.series_id)"></i>
                  <!-- [routerLink]="['/series/list/'+select.series_id]" [queryParams]="{seriesBy: 'series-grid',categoryId:categoryId}"></i> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
