<form #Xform="ngForm" class="m-auto auth auth-background">
    <div class="container-fluid" >
        <div class="row pi-login  justify-content-center align-items-center" >
            <div class="col-md-12 d-flex justify-content-center mt-5">
                <div class="header-w3l">
                    <div class="logo ml-2">
                        <h3><a href="#">{{ 'COMMON.TITLE' | translate }}</a></h3>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12 d-flex arrs">
                    <!-- <a [routerLink]="'/add-playlist'" class="d-flex back justify-content-start">
                        <i class="fa fa-arrow-left"></i>
                    </a> -->
                    <div class="m3u-header d-flex justify-content-end">
                        <h3>{{'COMMON.QRCODELOGIN' | translate}}</h3>
                    </div>
                </div>
               <div class="col-md-6 mt-4 border-right">
                    <div class="qr-head">
                        <h4 class="d-flex justify-content-center">Scan QR</h4>
                        <p class="d-flex justify-content-center">to access activation code</p>
                    </div>
                    <div class="img d-flex justify-content-center">
                        <qrcode [qrdata]="QrCodeWithUrl" [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
                    </div>
                    <div class="qrcode">
                        <p class="d-flex justify-content-center">{{myAngularxQrCode}}</p>
                    </div>
               </div>
               <!-- <div class="col-md-2" > -->
                <!-- <div class="hr" style="border: 1px solid green;"></div> -->
               <!-- </div> -->
               <div class="col-md-6 mt-4">
                <div class="head">
                    <div >
                        <ul style="list-style-type: none !important;" class="numbered-list">
                            <li class="mt-4">1 &#41; open https://form.purpletv.app/ in your browser</li>
                            <li class="mt-4">2 &#41; Add six digit code in form</li>
                            <li class="mt-4">3 &#41; Add Xstream or M3u details and 'Submit'</li>
                            <li class="mt-4">4 &#41; Screen will Refresh and Show your playlist profile</li>
                        </ul>
                    </div>
                    <div class="refresh-button mt-5">
                        <button class="btn btn-refresh" (click)="onTimeoutComplete()" [disabled]="disableButton">Refresh</button>
                        <button class="btn btn-skip" (click)="backToLogin()">Skip</button>
                    </div>
                </div>
               </div>
            </div>
        </div>
        <!-- <div class="btn-back d-flex justify-content-center mt-5" routerLink="/add-playlist">
            <button class="btn btn-purple">Back to login</button>
        </div> -->
    </div>
</form>