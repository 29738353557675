import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';
import { AuthService } from 'src/app/Shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {
  url: any = environment.apiUrl;
  m3uUrl: any = 'http://vithani.org/api/';
  curl:string='https://cors.b-cdn.net/'
  loginDetails:any=[]
  urls:any
  list_get:any
  list_m3u_update:any
  list_xstream_update:any
  delete_url:any
  constructor(public http: HttpClient, public utilService: UtilService,private authService:AuthService) {
    this.getUrl()
    this.list_get = localStorage.getItem('list_get')
    this.list_m3u_update = localStorage.getItem('list_m3u_update')
    this.list_xstream_update = localStorage.getItem('list_xstream_update')
    this.delete_url = localStorage.getItem('delete_url')
    console.log(this.list_m3u_update);
    
  }

  // https://endpoint.purpletv.app/purplemain/rb/v7.json
  getUrl(){
    this.http.get('https://endpoint.purpletv.app/purpleweb/main.json').subscribe(res=>{
      this.loginDetails=res
      this.urls=this.loginDetails.login
    })
  }

  getCodeXstream(param:any){
    // const body = new HttpParams()
    // .set(`is_code`,param.true)
    const body= {is_code:true}
    // return this.callURLEncodedApi('https://cloud.purpletv.app/register',body);
    return this.callURLEncodedApi(this.url,body);
  }
  addPlaylistM3u(param: any) {
    const body = new HttpParams()
      .set(`userid`, param.userid)
      .set(`name`, param.name)
      .set(`url`, param.url);

    // return this.callURLEncodedApi(this.url + '/m3u.php', body);
    return this.callURLEncodedApi(this.curl + this.list_m3u_update, body);
  }
  addPlaylistXstream(param: any) {
    const body = new HttpParams()
      .set(`userid`, param.userid)
      .set(`name`, param.name)
      .set(`url`, param.url)
      .set(`pwd`, param.pwd)
      .set(`username`, param.username);
    // return this.callURLEncodedApi(this.url + '/xstream.php', body);
    return this.callURLEncodedApi(this.curl + this.list_xstream_update, body);
  }
  
  // Xstream login post method
  addPlaylistXstreamNew(param:any){
    const body = new HttpParams()
      // .set(`userid`, param.userid)
      .set(`name`, param.name)
      // .set(`url`, param.url)
      .set(`password`, param.password)
      .set(`username`, param.username);
    return this.callURLEncodedApi(`${param.url}/player_api.php`, body);
  }
  addPlaylistXstreamGet(param:any){
    const body = new HttpParams()
      // .set(`userid`, param.userid)
      // .set(`name`, param.name)
      .set(`url`, param.url)
      .set(`password`, param.password)
      .set(`username`, param.username);
    return this.parsem3uList( body);
  }
  getPlayList(param: any) {
    const body = new HttpParams()
      .set(`userid`, param.userid)
    // return this.callURLEncodedApi(this.url + '/getList.php', body);
    return this.callURLEncodedApi(this.curl + this.list_get , body);
  }
  deletePlayListItem(param: any) {
    const body = new HttpParams()
      .set(`userid`, param.userid)
      .set(`playlist_type`, param.type)
      .set(`url`, param.url);
      if(this.delete_url == null){
      
      }
    return this.callURLEncodedApi(this.curl + this.delete_url, body);
  }
  
  callURLEncodedApi(url, body, headers: any = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })) {
    return this.http.post(url, body, { headers })
  }
  parsem3uList(m3uUrl: any) {    
    return this.http.get(m3uUrl,{ responseType: 'text' })
  }
}
