
<form #userForm="ngForm" (ngSubmit)=onSubmit(userForm) class="m-auto auth auth-background" >
  <div class="container-fluid">
    <div class="row pi-login  justify-content-center align-items-center">
      <div class="col-md-12 ">
        <div class="header-w3l">
          <div class="logo ml-2">
            <h3 class="text-center"><a href="#">{{ 'COMMON.TITLE' | translate }}</a></h3>
          </div>
        </div>
      </div>
        
      <div class="col-12  text-center">
        <h3 class="login-label mb-2 ml-2">{{ 'COMMON.LOGIN' | translate }}</h3>
      </div>
      <div class="col col-sm-6 col-md-7 col-lg-3 text-center mt-4" >
        
        <label for="" class="d-flex justify-content-start">  {{'LABEL.USER_NAME' | translate}}</label>
        <div class="form-group mb-2 ">
          <i class="fa fa-user text-white input-icon" aria-hidden="true"></i>
          <input type="text" [(ngModel)]="user.username" class="form-control in" name="username"
          #username="ngModel" placeholder="{{ 'PLACEHOLDER.USERNAME' | translate }}"(keypress)="preventSpace($event)" maxlength="120" required autofocus>
        </div>
        <!--  -->
        <!-- <div class="form-control-feedback text-left pt-0"
          *ngIf="username.errors && (username.dirty || username.touched)">
       <p *ngIf="username.errors.required" class="error-msg">User name is required</p>
     </div> -->
          <div *ngIf="userForm.submitted && username.invalid" class="text-left pt-0">
            <p class="error-msg mb-0">{{ 'FORMERROR.USERNAME' | translate }}</p>
          </div>

        <label for="" class="d-flex justify-content-start mt-3"> {{'LABEL.PASSWORD' | translate}}</label>

        <div class="form-group mb-2 mt-2">
          <i class="fa fa-key text-white input-icon" aria-hidden="true"></i>
          <input [type]="type" [(ngModel)]="user.otp" class="form-control-pass in" name="password" (keyup.enter)="onSubmit(userForm)"
            #password="ngModel" placeholder="{{ 'PLACEHOLDER.PASSWORD' | translate }}" (keypress)="NumOnly($event)"  maxlength="4" required autofocus>
            <div class="input-append cursor-pointer text-white" (click)="hideShowPassword()">
              <i class="fas fa-eye " *ngIf="type == 'password'"></i>
              <i class="fas fa-eye-slash " *ngIf="type !== 'password'"></i>
            </div> 
          </div>
          <div *ngIf="userForm.submitted && password.invalid" class="text-left pt-0">
            <p class="error-msg mb-0">{{ 'FORMERROR.VALIDPIN' | translate }}</p>
          </div>
        <div class="form-group mb-5 d-none">
          <div class="d-flex justify-content-center">
            <h6 class="d-flex-center pl-1">{{ 'COMMON.ENTPIN' | translate }}</h6>
            <ng-otp-input (onInputChange)="otpChange($event)" [config]="config" name="otp" #ngOtpInput required>
            </ng-otp-input>
            <div class="d-flex-center ml-2">
              <i class="fas text-white fs-28 " (click)="show=!show;toggleOtp()"
                [ngClass]="show ?'fa-eye-slash':'fa-eye'"></i>
            </div>
          </div>
          <div *ngIf="userForm.submitted" class="float-left pt-1">
            <p class="error-msg mb-0" *ngIf="!user.otp">{{ 'FORMERROR.PINNUM' | translate }}</p>
            <p class="error-msg mb-0" *ngIf="user.otp && user.otp.length<4">{{ 'FORMERROR.VALIDPIN' | translate }}</p>
          </div>
        </div>
        <div class="form-group mb-lg-5 mb-4 mt-4">
          <div class="d-flex ml-0 justify-content-start">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="customCheck1" (change)="rememberMe($event)">
              <label class="custom-control-label" for="customCheck1">{{ 'COMMON.REMLOGIN' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row justify-content-center align-items-center">
          <div class="col-md-5">
            <button class="login-btn mr-5" type="submit" [disabled]="isLoading">
              {{ 'COMMON.LOG_IN' | translate }}
              <div *ngIf="isLoading" class="spinner-border spinner-border-sm fs-14" role="status">
                <span class="sr-only">{{ 'COMMON.LOADING' | translate }}</span>
              </div>
            </button>
          </div>
          <div class="col-md-6">
            <button class="login-code ml-0 mr-5" (click)="checkCodeLogin()">
              {{ 'LOGIN.LOGINWCODE' | translate }}</button>
          </div>
        </div>
        <div class="col-12 pb-0 d-flex">
          <h5 class="mb-0 mt-5 text-size ">
        <i class="fa fa-arrow-left" aria-hidden="true" routerLink="/add-playlist"></i>
            
            {{ 'LOGIN.DONTACC' | translate }} <a class="ml-1 text-purple"
              routerLink="/register">{{ 'COMMON.SIGNUP' | translate }}</a></h5>
        </div>
      </div>
    </div>
  </div>
</form>
