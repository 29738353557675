import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import videojs from 'video.js';
import { SeriesService } from 'src/app/Core/services/series.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-seriesplayer',
  templateUrl: './seriesplayer.component.html',
  styleUrls: ['./seriesplayer.component.css']
})
export class SeriesplayerComponent implements OnInit {

  id: string;
  id2: string;
  seriesinfo: any;
  stream_Url: any;
  currentUrl:any;
  videoUrl:any;
  isVideoOpened:boolean
  constructor(private router: Router,
    private route: ActivatedRoute, private seriesService: SeriesService, private location:Location) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.id2 = this.route.snapshot.params['id2'];

    if (this.id && this.id2) {
      this.stream_Url = this.seriesService.getStreamSeriesUrl(this.id, this.id2);   
      this.playSeries();   
    }
   

  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    //this.playSeries();
  }
  playSeries() {
    // this.stream_Url = this.seriesService.getStreamSeriesUrl(this.id, this.id2);
    setTimeout(() => {
    videojs("example-videoplay").src([
      { type: "video/mp4", src: this.stream_Url },
    ]);
    var player = videojs('example-videoplay');
    player.on('ended', function () {
      
      this.dispose();
      // window.close();       
  // this.location.back() 
  window.history.back()
});
    console.log( this.stream_Url);
     
    // When you pass text in options it just creates a control text,
    // which is displayed as tooltip when hovered on 
    // this button viz the span in you div,
    // player.bigPlayButton.show();
    console.log(player);
    
    var myButton = player.controlBar.addChild("button");

    // There are many functions available for button component 
    // like below mentioned in this docs 
    // https://docs.videojs.com/button. 
    // You can set attributes and clasess as well.

    // Getting html DOM
    var myButtonDom = myButton.el();
    // Since now you have the html dom element 
    // you can add click events

    // Now I am setting the text as you needed.
    myButtonDom.innerHTML = "EXIT";

    myButtonDom.onclick = function () {
      // alert("Are you sure?");
      window.location.href = "/series"
    }
  }, 200);

  }
backToInfo(){
  // this.location.back();
  const previousPage = localStorage.getItem('previousPage');
if (previousPage) {
  localStorage.removeItem('previousPage');
  window.location.href = previousPage;
} else {
 this.router.navigate(['/series'])
}
}
}
