<!DOCTYPE html>
<html>

<head>
  <title></title>

</head>

<body>




  <div class="container-fluid" style="background: #0D0D0D;height: 950px;">
    <div class="row">
      <!-- <app-navbar></app-navbar> -->
    </div>
    <div class="row" style="position: relative;left: 70px;">
      <div class="col-md-12 col-12">

        <!--search-->
        <div class="row" style="position: relative;left: 20px;">
          <div class="col-md-10">
            <div class="d-flex bd-highlight searchtv">
              <div class="p-2 flex-grow-1 bd-highlight search">{{ 'HOME.SETTINGS' | translate }}</div>
              <div class="p-2 bd-highlight search">{{date2t}}:{{date3t}}:{{date4t}}</div>
              <div class="p-2 bd-highlight search0">{{date1 | date: 'dd'}}-{{date | date:'MM'}}-{{date | date: 'yyyy'}}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="row wrapper mt-5">

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3 mt-5 hii" style="margin-left: 90px;">
            <app-settings></app-settings>
          </div>
          <div class="col-md-8 ">
            <div class="row">
              <div class="col-md-10 mx-auto">


                <div class="container-contact100">
                  <div class="wrap-contact100">
                    <form class="contact100-form validate-form">
                      <span class="contact100-form-title">
                        {{ 'HOME.CATEGORY2' | translate }}
                      </span>
                    </form>
                  </div>
                </div>

                <div class="d-flex flex-row bd-highlight mb-3 ct1">
                  <div class="p-2 bd-highlight email">
                    <div class="ccircle"><i class="fa fa-envelope ctheader"></i></div>
                    <h1 class="eheader" style="position: relative;top: -60px;left: 68px;">{{ 'CONTACTINFO.CONTACT1' |
                      translate }}</h1>
                  </div>
                  <div class="p-2 bd-highlight email fb">
                    <div class="ccircle"><i class="fab fa-facebook-f ctheader"></i></div>
                    <h1 class="eheader" style="position: relative;top: -60px;left: 68px;">{{ 'CONTACTINFO.CONTACT2' |
                      translate }}</h1>
                  </div>
                </div>

                <div class="d-flex flex-row bd-highlight mb-3 mt-4 ct1">
                  <div class="p-2 bd-highlight email">
                    <div class="ccircle"><i class="fab fa-twitter ctheader"></i></div>
                    <h1 class="eheader" style="position: relative;top: -60px;left: 68px;">{{ 'CONTACTINFO.CONTACT3' |
                      translate }}</h1>
                  </div>
                  <div class="p-2 bd-highlight email fb">
                    <div class="ccircle"><i class="fab fa-skype ctheader"></i></div>
                    <h1 class="eheader" style="position: relative;top: -60px;left: 68px;">{{ 'CONTACTINFO.CONTACT4' |
                      translate }}</h1>
                  </div>
                </div>

                <div class="d-flex flex-row bd-highlight mb-3 mt-4 ct1">
                  <div class="p-2 bd-highlight email">
                    <div class="ccircle"><i class="fab fa-whatsapp ctheader"></i></div>
                    <h1 class="eheader" style="position: relative;top: -60px;left: 68px;">{{ 'CONTACTINFO.CONTACT5' |
                      translate }}</h1>
                  </div>
                  <div class="p-2 bd-highlight email fb">
                    <div class="ccircle"><i class="fab fa-telegram-plane ctheader"></i></div>
                    <h1 class="eheader" style="position: relative;top: -60px;left: 68px;">{{ 'CONTACTINFO.CONTACT6' |
                      translate }}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>