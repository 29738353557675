import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Constant } from 'src/app/Core/services/data';
import { UtilService } from 'src/app/Core/services/util.service';

@Component({
  selector: 'app-series-grid',
  templateUrl: './series-grid.component.html',
  styleUrls: ['./series-grid.component.css']
})
export class SeriesGridComponent implements OnInit {
  @Input() selectedcategory: any;
  @Input() isLoading: any;
  categoryId: any;

  sortType: any;
  subscription: Subscription;
  isSort: boolean = true;
  seriesList: any;
  defaultList: any;
  JSONDatas: any = [];
  filterTerm: string;
  filteredList:any =[]
  constructor(private translate:TranslateService,private utilService: UtilService, private cdRef: ChangeDetectorRef, private route: Router) {
    this.subscription = this.utilService.getSorting().subscribe((res: any) => {
      this.sortType = res;
      if (this.sortType.type == 'Series') {
        this.sortSeries('name', this.sortType.sub_category);
      }
    });
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.selectedcategory) {
      this.seriesList = this.selectedcategory;
      this.filteredList=this.seriesList;
      this.defaultList = this.selectedcategory;
      this.categoryId = this.selectedcategory[0].category_id;

      let index, savedArray;
      savedArray = JSON.parse(localStorage.getItem(Constant.SERIES_FAV)) || [];
      savedArray.map((ele: any) => {
        index = _.findIndex(this.seriesList, { series_id: ele.id });
        if (index >= 0) {
          this.seriesList[index].isFavourit = true;
        }
      });
    }
  }

  sortSeries(property, subtype: any) {
    // this.isSort = !this.isSort;
    // this.utilService.commonSort(this.isSort, 'name', this.seriesList);
    let ascDesc;
    if (subtype == 'default') {
      this.seriesList = this.defaultList;
      this.filteredList=this.seriesList;
    } else {
      (subtype == 'atoz') ? ascDesc = 'asc' : ascDesc = 'desc';
    }
    if (ascDesc) {
      this.utilService.sortCommonCategorywise(property, ascDesc, this.seriesList, (callback) => {
      this.seriesList = callback;
      this.filteredList=this.seriesList;

      });
    }
  }
  addtoFavourite(item: any) {
    let savedArray = [];
    let isExist: any;
    const msg =this.translate.instant('COMMON.ADDED_SUCCESS_MSG')
    savedArray = JSON.parse(localStorage.getItem(Constant.SERIES_FAV)) || [];
    if (savedArray.length > 0) {
      isExist = _.findIndex(savedArray, { id: item.series_id });
      if (isExist == -1) {
        item.isList = true;
        savedArray.push({ id: item.series_id, img: item.backdrop_path, name: item.name, fullUrl: localStorage.getItem(Constant.FULLURL_KEY) });
        localStorage.setItem(Constant.SERIES_FAV, JSON.stringify(savedArray));
        this.utilService.showErrorSuccess(`${msg}`);
        let index = _.findIndex(this.seriesList, { series_id: item.series_id });
        if (index >= 0) {
          this.seriesList[index].isFavourit = true;
        }
      }
      if (isExist >= 0) {
        this.utilService.showErrorInfo('Item alreadey added to favourite')
      }

    }
    else { //first
      item.isList = true;
      savedArray.push({ id: item.series_id, img: item.backdrop_path, name: item.name, fullUrl: localStorage.getItem(Constant.FULLURL_KEY) });
      localStorage.setItem(Constant.SERIES_FAV, JSON.stringify(savedArray));
      let index = _.findIndex(this.seriesList, { series_id: item.series_id });
      if (index >= 0) {
        this.seriesList[index].isFavourit = true;
      }
      this.utilService.showErrorSuccess(`${msg}`);
    }
  }

  deleteFavourite(item: any) {
    item.id = item.series_id;
    const msg = this.translate.instant('COMMON.REMOVE_SUCCESS_MSG')
    this.utilService.deleteFavourit(Constant.SERIES_FAV, item, (result: any) => {
      if (result) {
        this.utilService.showErrorSuccess(`${msg}`);
        let index = _.findIndex(this.seriesList, { series_id: item.id });
        if (index >= 0) {
          this.seriesList[index].isFavourit = false;
        }
      }
    });
  }
  gotoDetail(id) {

    let passModal: any = {
      type: 'Series',
      sub_category: 'default'
    };
    this.utilService.addSorting(passModal);
    this.route.navigate(['/series/info/' + id], { queryParams: { seriesBy: 'series', categoryId: this.categoryId } });
  }
  goToSeasonList(id: any) {
    let passModal: any = {
      type: 'Series',
      sub_category: 'default'
    };
    this.utilService.addSorting(passModal);
    this.route.navigate(['/series/list/' + id], { queryParams: { seriesBy: 'series-grid', categoryId: this.categoryId } });
  }
  searchSeries(){
    this.filteredList= this.seriesList.filter((item:any)=>item.name.toLowerCase().includes(this.filterTerm.toLowerCase()))  

    // this.filteredList = this.seriesList.filter((item:any)=>item.name.toLowerCase().include(this.filterTerm.toLowerCase()))
    console.log(this.filteredList,'series list');  
  }

  clearSearch(){
    this.filterTerm = ''
    this.filteredList=this.seriesList
  }

  preventSpace(event){
    this.utilService.preventSpace(event)
  }
}