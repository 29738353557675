<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h4>{{'COMMON.SEARCH_RESULTS' | translate }}</h4>
      <div [hidden]="isLoading" class="col-12 text-center mt-5" *ngIf="!totalMovies">
        <h5>{{'COMMON.DATA_NOT_FOUND' | translate }}</h5>
      </div>
      <div class="row mt-2" *ngFor="let item of searchedMovies">
        <div class="col-12 d-flex mb-1" *ngIf="item.data.length > 0">
          <h6 class="mr-auto">{{ item.category_name }}</h6>
          <!-- <a class="mr-2 text-purple" routerLink="/movies" [queryParams]="{ category: item.category_id}">View All <i
              class="fas fa-angle-right"></i></a> -->
        </div>
        <div class="col-12 mx-3 pr-4">
          <ngx-slick-carousel class="carousel custom-slider" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let select of item.data let i=index;" class="slide">
              <div class="imageContainer-search">
                <img [routerLink]="['/movies/info/'+select.stream_id]" [queryParams]="{movieBy: 'search'}"
                  class="search-img cursor-pointer" loading=lazy [src]="select.stream_icon" alt="{{select.name}}">

                <div class="fav-icon text-purple" *ngIf="select.isFavourit"> <i
                    class="fas fa-heart  cursor-pointer"></i></div>
              </div>
              <div class="d-flex mt-1">
                <p class="search-title text-left " title="{{select.name}}">{{select.name}}</p>
                <a class="ml-auto" [routerLink]="'/movies/watch/' + select.stream_id" target="_blank"> <i
                    class="fas fa-play-circle fs-18 text-purple ml-auto mr-3 cursor-pointer"></i></a>
              </div>
            </div>

          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
