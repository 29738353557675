import { NgZone } from '@angular/core';
import { OnChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { map, filter } from 'lodash';
import { Subscription } from 'rxjs';
import { Constant } from 'src/app/Core/services/data';
import { LivestreamService } from 'src/app/Core/services/livestream.service';
import { UtilService } from 'src/app/Core/services/util.service';


@Component({
  selector: 'app-search-livetv',
  templateUrl: './search-livetv.component.html',
  styleUrls: ['./search-livetv.component.css']
})
export class SearchLivetvComponent implements OnInit {
  @Input() liveStream: any = [];
  isChannelLoading: boolean = false;
  @Input() isLoading: any;
  liveData: any;
  @Input() liveCategoryList: any = [];
  @Input() searchLiveTv: any;
  live: any = {
    category_name: '',
    data: []
  };
  all: any = [];
  arrayAll: any;
  allLive: any = [];
  slideConfig = {
    "slidesToShow": 6, "slidesToScroll": 6, responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  searchedLiveTv: any;
  subscription: Subscription;
  sortType: any;
  isSort: boolean = true;
  ascDesc: string = 'asc';
  isSearching = false;
  totalMovies = 0;
  constructor(private liveStreamService: LivestreamService, private utilService: UtilService, private changeDetectRef: ChangeDetectorRef, private ngZone: NgZone) {
    // this.getLiveCategory();
  }

  ngOnInit(): void {
  }

  searchByLiveTv() {
    this.isLoading = true;
    // this.utilService.showSpinner()
    this.isSearching = true;
    let indexfind, savedArray;
    this.all = [];
    this.totalMovies = 0;
    this.liveStream.map((ele: any) => {
      this.liveCategoryList.map((category: any) => {
        if (ele.category_id == category.category_id) {
          ele.category_name = category.category_name;
          
        }
      })
      console.log(this.liveCategoryList,'-->categories name');
    });
    this.arrayAll = _.groupBy(this.liveStream, 'category_name');

    let a = [];
    for (let result in this.arrayAll) {
      console.log(result,'result');
      
      a = [];
      this.arrayAll[result].map(element => {
        a.push(element);
        console.log(element,'element');
        
      });
      const existingCategory = this.all.find(item => item.category_name === result);
      console.log(this.all,'-->all');
      if (!existingCategory) {
      this.all.push({ 'category_name': result, 'category_id': a[0].category_id, data: a });
    }
  }


    // this.searchedLiveTv.map((movie_data, index) => {
    //   movie_data.data = _.filter(movie_data.data, (search => {
    //     return search.name.toLowerCase().indexOf(this.searchLiveTv.toLowerCase()) > -1
    //   }));
    // })
    // this.changeDetectRef.detectChanges();


    if (this.all && this.searchLiveTv) {
      // this.searchedLiveTv = [];
      let temp = _.cloneDeep(this.all);
      this.searchedLiveTv = (temp.map((movie_data) => {
        console.log(this.searchLiveTv,'livetv search');
        console.log(movie_data.data,'Movie data');
        
        movie_data.data = _.filter(movie_data.data, ((search: any) => {
          return search.name.toLowerCase().indexOf(this.searchLiveTv.toLowerCase()) > -1
        }));
        // movie_data.data.category_name = movie_data.category_name;
        // movie_data.data.category_id = movie_data.category_id;

        savedArray = JSON.parse(localStorage.getItem(Constant.LIVETV_FAV)) || [];

        this.totalMovies = this.totalMovies + (movie_data.data ? movie_data.data.length : 0);
        savedArray.map((ele: any) => {
          if (ele) {
            indexfind = _.findIndex(movie_data.data, { stream_id: ele.id });
            if (indexfind != -1) {
              movie_data.data[indexfind].isFavourit = true;
            }
          }
        });


        return movie_data;
      })).filter((data) => data.data.length > 0);



      setTimeout(() => {
        // this.utilService.hideSpinner()
        this.isSearching = false;
        this.isLoading = false;
      }, 1000);
    }
  }

  sortLiveTv() {
    this.isLoading = true
    this.isSort = !this.isSort;
    (this.isSort) ? this.ascDesc = 'desc' : this.ascDesc = 'asc';
    this.utilService.tabWiseSearchSorting(this.ascDesc, 'category_name', this.searchedLiveTv, (callback) => {
      this.isLoading=false
      this.searchedLiveTv = callback;

    });
  }
}
