



<div class="dropdown">
  <button class="dropbtn"><a href="javascript:void(0)">&#9776; {{ 'HOME.SETTINGS' | translate }}</a></button>
  <div class="dropdown-content">
    <div class="listContainer">
              <div class="d-flex flex-column bd-highlight mb-3">
                <div class="p-2 bd-highlight circle mt-4 ml-2">
                  <a class="d-flex flex-row bd-highlight mb-3" routerLink="/settings/user">
                  <div class="p-2 bd-highlight icon"><i class="fa fa-user" aria-hidden="true"></i></div>
                  <div class="p-2 bd-highlight menuname">{{ 'HOME.CATEGORY1' | translate }}</div>
                  </a>
                </div>
               <div class="p-2 bd-highlight circle mt-4 ml-2">
                  <a class="d-flex flex-row bd-highlight mb-3" routerLink="/settings/contact">
                  <div class="p-2 bd-highlight icon"><i class="fa fa-phone-volume"></i></div>
                  <div class="p-2 bd-highlight menuname">{{ 'HOME.CATEGORY2' | translate }}</div>
                  </a>
                </div>
                <div class="p-2 bd-highlight circle mt-4 ml-2">
                  <a class="d-flex flex-row bd-highlight mb-3" routerLink="/settings/language">
                  <div class="p-2 bd-highlight icon"><i class="fa fa-language" aria-hidden="true"></i></div>
                  <div class="p-2 bd-highlight menuname"> {{ 'HOME.SELECT' | translate }}</div>
                  </a>
                </div>
                
                ]<!--
                <div class="p-2 bd-highlight circle mt-4 ml-2">
                  <a class="d-flex flex-row bd-highlight mb-3">
                  <div class="p-2 bd-highlight icon"><i class="fa fa-shield-check"></i></div>
                  <div class="p-2 bd-highlight menuname">Privacy Policy</div>
                  </a>
                </div>
                  <div class="p-2 bd-highlight circle mt-4 ml-2">
                  <a class="d-flex flex-row bd-highlight mb-3" routerLink="/settings/timestamp">
                  <div class="p-2 bd-highlight icon"><i class="fas fa-clock"></i></div>
                  <div class="p-2 bd-highlight menuname">Time Shift</div>
                  </a>
                </div>
                <div class="p-2 bd-highlight circle mt-4 ml-2">
                  <a class="d-flex flex-row bd-highlight mb-3" routerLink="/settings/cache">
                  <div class="p-2 bd-highlight icon"><i class="fas fa-trash-alt"></i></div>
                  <div class="p-2 bd-highlight menuname">Clear Cache</div>
                  </a>
                </div>
               
                <div class="p-2 bd-highlight circle mt-4 ml-2">
                  <a class="d-flex flex-row bd-highlight mb-3" routerLink="/settings/acc">
                  <div class="p-2 bd-highlight icon"><i class="fa fa-cog"></i></div>
                  <div class="p-2 bd-highlight menuname">General Settings</div>
                  </a>
                </div>
                <div class="p-2 bd-highlight circle mt-4 ml-2">
                  <a class="d-flex flex-row bd-highlight mb-3" routerLink="/settings/device">
                  <div class="p-2 bd-highlight icon"><i class="fa fa-phone-laptop"></i></div>
                  <div class="p-2 bd-highlight menuname">Device Type</div>
                  </a>
                </div>
              -->
              </div>
           </div>
  </div>
</div>


  