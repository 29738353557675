import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Constant } from '../../services/data';
import { UtilService } from '../../services/util.service';
import { MessageService } from '../../services/message.service';
import { constant, uniqBy } from 'lodash';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.css']
})
export class FavouritesComponent implements OnInit {
  moviesData: any = [];
  seriesData: any = [];
  liveTvData: any = [];
  sortType: any;

  savedMovie: any;
  movieinfo: any;
  seriesinfo: any;
  fullUrl = '';
  subscription: Subscription;
  fromInit = true;

  constructor(private translate:TranslateService,private router: Router, private utilService: UtilService, private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private messageService: MessageService,

  ) {
    this.fullUrl = localStorage.getItem(Constant.FULLURL_KEY);
    this.subscription = this.utilService.getSorting().subscribe((res: any) => {
      this.sortType = res;
      if (!this.fromInit) {
        this.sortSeries('name', res.sub_category);
      }
    });
  }

  ngOnInit(): void {
    this.getMetaData();
  }

  getMetaData() {
    this.getMovieFav();
    this.getSeriesFav();
    this.getLivetvFav();
    setTimeout(() => {
      this.fromInit = false;
    }, 500);
  }

  getMovieFav() {
    this.savedMovie = JSON.parse(localStorage.getItem(Constant.MOVIES_FAV));
    for (let i = 0; i < this.savedMovie?.length; i++) {
      if (!this.savedMovie[i].fullUrl || this.fullUrl === this.savedMovie[i].fullUrl) {
        if (this.savedMovie[i].isList) {
          this.moviesData.push({ img: this.savedMovie[i].stream_icon, name: this.savedMovie[i].name, id: this.savedMovie[i].id });
        } else {
          this.moviesData.push(this.savedMovie[i]);
        }
      }
    }
    this.moviesData = uniqBy(this.moviesData, 'id');
  }
  getLivetvFav() {
    this.seriesData = JSON.parse(localStorage.getItem(Constant.SERIES_FAV));
    this.seriesData = this.seriesData.filter(series => {
      return !series.fullUrl || this.fullUrl === series.fullUrl;
    });
  }
  getSeriesFav() {
    this.liveTvData = JSON.parse(localStorage.getItem(Constant.LIVETV_FAV));
    if (this.liveTvData) {
      this.liveTvData = this.liveTvData.filter(liveTv => {
        return !liveTv.fullUrl || this.fullUrl === liveTv.fullUrl;
      });
    }
  }

  deletefav(item: any, type: any) {
    const logoutTranslation = this.translate.instant('COMMON.REMOVE_FAV');
    const confirmationTranslation = this.translate.instant('COMMON.FAVOURITE_CONFIRMATION');
    const yes = this.translate.instant('HOME.YES')
    const no = this.translate.instant('HOME.NO')
    const msg=this.translate.instant('COMMON.REMOVE_SUCCESS_MSG')
    this.messageService.confirm(logoutTranslation,confirmationTranslation )
      .subscribe((answer) => {
        if (answer === yes) {
          let a = [];
          let array: any;
          let deledIndex: any;
          if (type == 'livetv') {
            array = this.liveTvData;
          }
          else if (type == 'movie') {
            array = this.moviesData
          }
          else {
            array = this.seriesData
          }
          // (isMovie) ? (array = this.moviesData) : (array = this.seriesData);

          array.map((ele: any, index) => {
            if (ele.id == item.id) {
              deledIndex = index;
            }
            else {
              a.push(ele);
            }
          });

          if (a.length > 0 || deledIndex == 0) {
            if (type == 'movie') {
              this.moviesData.splice(deledIndex, 1);
              localStorage.setItem(Constant.MOVIES_FAV, JSON.stringify(a));
            }
            else if (type == 'livetv') {
              this.liveTvData.splice(deledIndex, 1);
              localStorage.setItem(Constant.LIVETV_FAV, JSON.stringify(a));
            }
            else {
              this.seriesData.splice(deledIndex, 1);
              localStorage.setItem(Constant.SERIES_FAV, JSON.stringify(a));
            }

            this.utilService.showErrorSuccess(msg);
          }
          this.cdRef.detectChanges();
        }
      })
  }

  sortSeries(property, subtype: any) {
    let ascDesc;
    if (subtype == 'default') {
      this.getMetaData();
    } else {
      (subtype == 'atoz') ? ascDesc = 'asc' : ascDesc = 'desc';
    }
    if (ascDesc) {
      this.utilService.sortCommonCategorywise(property, ascDesc, this.liveTvData, (callback) => {
        this.liveTvData = callback;
      });
      this.utilService.sortCommonCategorywise(property, ascDesc, this.moviesData, (callback) => {
        this.moviesData = callback;
      });
      this.utilService.sortCommonCategorywise(property, ascDesc, this.seriesData, (callback) => {
        this.seriesData = callback;
      });
    }
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.moviesData = [];
    this.seriesData = [];
    this.liveTvData = [];
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
