import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/Core/services/theme.service';
// import chroma from 'chroma-js';

@Component({
  selector: 'app-change-color',
  templateUrl: './change-color.component.html',
  styleUrls: ['./change-color.component.css',
  ]
})
export class ChangeColorComponent implements OnInit {
  selectedColor$ = this.themeService.selectedColor$;
  @Input() color: any
  colorTheme = [
    {
      color: 'black'
    },
    {
      color: 'purple'
    },
    {
      color: 'brown'
    },
    {
      color: 'red'
    },
    {
      color: 'green'
    },
    {
      color: 'blue'
    }
  ]
  translations = ['COLOR.BLACK', 'COLOR.PURPLE', 'COLOR.BROWN', 'COLOR.RED', 'COLOR.GREEN', 'COLOR.BLUE']

  
  selectedColor: string
  constructor(private themeService: ThemeService) {
  }

  ngOnInit(): void {
    this.selectedColor = localStorage.getItem('selectedColor');
    if (!this.isValidColor(this.selectedColor)) {
      this.selectedColor = '#212121';
    }
    this.themeService.setSelectedColor(this.selectedColor);
    this.themeService.selectedColor$.subscribe(color => {
      // this.selectedColor = color;
      this.selectedColor = this.isValidColor(color) ? color : '';
    });
    this.color = localStorage.getItem('newColor')
  }
  onChangeColor(event: any) {
    if (event.target.value) {
      event.target.style.background = event.target.value
      this.color = event.target.style.background
      console.log(this.color);
    }

  }
  changeThemeColor(newColor: string) {
    // const colorName = chroma(newColor).name();
    const confirmation = confirm(`Apply selected color ${newColor}?`); // Show confirmation message
    if (confirmation) {
    this.themeService.themeColor = newColor; // Apply selected color
    localStorage.setItem('newColor', newColor);
    }
  }
  changeColor(color: string): void {
    this.themeService.setSelectedColor(color);
  }
  private isValidColor(color: string): boolean {
    return ['red', 'green', 'blue', 'purple', 'black', 'brown'].includes(color);
  }

  getTranslation(index: number): string {
    return this.translations[index]
  }
}
