import { AfterViewInit, Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Gleap from 'gleap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'webplayer';
  constructor(
    public translate: TranslateService,
    private router: Router
  ) {
    translate.addLangs(['en-US', 'fr-FR', 'chinese', 'german', 'hindi','hebrew','italian', 'ar','dutch','portuguese','turkish', 'spanish','slovak','russian']);
    translate.setDefaultLang('en-US');
    if (localStorage.getItem('language')) {
      translate.use(localStorage.getItem('language'));
    }
    router.events.subscribe((e) => {
      if (e instanceof NavigationCancel || e instanceof NavigationError) {
        this.hideSidebar();
      }

      if (e instanceof NavigationEnd) {
        this.hideSidebar();
      }
    });
  }

  hideSidebar() {
    const elem = document.getElementById('playerSideMenu');
    if (elem && screen.width < 768)
      elem.classList.remove('active');
  }

  ngAfterViewInit(): void {
    Gleap.initialize('vihD40QWL1wBNkndlQL9TeLle9xGGj8q');
  }
}
