<div class="container-fluid mx-lg-1 mx-0 ">
  <div class="row  mb-0 ">
    <div class="col-12">
      <div class="d-flex">
        <div class="mr-auto px-0 mt-2 w-100">
          <div class="form-group has-search  mr-3">
            <span class="fa fa-search form-control-feedback"></span>
            <form [formGroup]="searchForm">
              <input formControlName="search" type="text" class="form-control"
                placeholder="{{ 'COMMON.SEARCH_HERE' | translate }}">
            </form>
            <button type="button" class="btn bg-transparent searchclear" *ngIf="searchForm.value.search"
              (click)="searchForm.setValue({search: ''})">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <div class="px-0 mt-2">
          <div class=" d-flex-center mr-3">
            <ng-template #popTemplate>
              <div>
                <button [ngClass]="{'btn-purple': latestSort}" (click)="sortSearch('sortByNew')" type="button"
                  class="btn sort-btn btn-dark">
                  {{'COMMON.LETEST' | translate}}
                </button>
              </div>
              <div class="mt-2">
                <button [ngClass]="{'btn-purple': nameSort}" (click)="sortSearch('sortByCate')" type="button"
                  class="btn sort-btn btn-dark">
                  {{'COMMON.NAME' | translate}}

                </button>
              </div>
            </ng-template>
            <button type="button" class="btn text-header-backClr header-text border-dark text-white"
              [popover]="popTemplate" placement="left"><i class="fas fa-sort-alt"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row search-contain mx-1" [hidden]="searchForm.value.search">
    <div class="col-12">
      <div class="row">
        <!-- <div class="col-12 text-left py-2" *ngIf="recentSearches.length>0">
          <div class="d-flex space-between" >
          <h5 class="d-flex">{{'COMMON.RECENT_SEARCH' | translate}} </h5>
          <p ><i class="fa fa-times" (click)="removeSearchKeys()"></i></p >
          </div>
          <div class="d-flex search-list">
            <div *ngFor="let item of recentSearches; let i = index">
              <span class="badge badge-secondary p-2 mx-2 cursor-pointer">
                <span (click)="searchByRecentKey(item)">{{item}} </span>
                <i (click)="removeSearchKey(i)" class="fa fa-times"></i>
              </span>
            </div>
          </div>
        </div> -->
      </div>
      <div class="row mt-3" *ngIf="!isLiveLoading && !isSorting">
        <div class="col-12 d-flex">
          <h5 class="mr-auto">{{'NAV.NAV3' | translate}}</h5>
          <a class="mr-2 text-white" routerLink="/livetv">{{'COMMON.VIEW_ALL' | translate}}  <i class="fas fa-angle-right"></i></a>
        </div>
        <div class="col-12 ml-3 mr-4 pr-5">
          <ngx-slick-carousel class="carousel custom-slider" #slickModal="slick-carousel" [config]="slideConfigLive">

            <!-- (click)="updateChannelEpg(channel.stream_id,channel.name)" -->
            <div ngxSlickItem class="slide" *ngFor="let channel of liveStream | slice:0:35; index as i">
              <div class="pr-2">
                <div class="mr-3 over1 d-flex" routerLink="/livetv"
                  [queryParams]="{ category: channel.category_id, stream:channel.stream_id}">
                  <div class="px-1 py-2">
                    <img [src]="channel.stream_icon" width="70px" height="40px" class="mb-0" loading=lazy>
                  </div>
                  <div class="p-2 py-3 search-title cursor-pointer">
                    {{channel.name}}
                  </div>
                </div>
              </div>

            </div>
          </ngx-slick-carousel>
        </div>
      </div>
      <div class="row mt-3" *ngIf="!isLoading && !isSorting">
        <div class="col-12 d-flex">
          <h5 class="mr-auto">{{'NAV.NAV4' | translate}}</h5>
          <a class="mr-2 text-white" routerLink="/movies">{{'COMMON.VIEW_ALL' | translate}}  <i class="fas fa-angle-right"></i></a>
        </div>
        <div class="col-12 mx-3 mr-4 pr-5">
          <ngx-slick-carousel class="carousel custom-slider" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let select of movieShowList | slice: 0:500 let i=index;" class="slide">
              <div class="imageContainer-search">
                <img class="search-img cursor-pointer" [src]="select.stream_icon" alt="{{select.name}}" loading=lazy
                  [routerLink]="['/movies/info/'+select.stream_id]" [queryParams]="{movieBy: 'search'}">
                <p class="search-title text-left" title="{{select.name}}">{{select.name}}</p>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
      <div class="row mt-3" *ngIf="!isLoading && !isSorting">
        <div class="col-12 d-flex">
          <h5 class="mr-auto">{{'NAV.NAV5' | translate}}</h5>
          <a class="mr-2 text-white" routerLink="/series">{{'COMMON.VIEW_ALL' | translate}} <i class="fas fa-angle-right"></i></a>
        </div>
        <div class="col-12 mx-3 mr-4 pr-5">
          <ngx-slick-carousel class="carousel custom-slider" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let select of seriesShowList let i=index;" class="slide">
              <div class="imageContainer-search">
                <img class="search-imgseries cursor-pointer" [src]="select.backdrop_path" alt="{{select.name}}"
                  loading=lazy [routerLink]="['/series/info/'+select.series_id]" [queryParams]="{seriesBy: 'search'}">
                <p class="search-title text-left" title="{{select.name}}">{{select.name}}</p>
              </div>

            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
  <div class="row  ml-0 pl-0" [hidden]="!searchForm.value.search">
    <div class="col-lg-4 mb-2 col-4 text-center my-2 " *ngFor="let item of ['Live Tv','Movies','Series'] ; let i =index">
      <button class="search-menu btn px-lg-4 px-2  " [ngClass]="{'search-menu-borderTop': selectedMenuItem == item}"
        (click)="onMenuselect(item)">{{ getTranslation(i) | translate }}</button>
    </div>
    <div class="col-12" *ngIf="selectedMenuItem=='Live Tv'">
      <app-search-livetv [searchLiveTv]="searchForm.value.search" [liveStream]="liveStream"
        [liveCategoryList]="liveCategoryList" [isLoading]="isLiveLoading" #updateSearchLiveTv>
      </app-search-livetv>
    </div>
    <div class="col-12" *ngIf="selectedMenuItem=='Movies'">
      <app-search-movie [searchMovies]="searchForm.value.search" #updateSearchMovies></app-search-movie>
    </div>
    <div class="col-12" *ngIf="selectedMenuItem=='Series'">
      <app-search-series [searchSeriesTerm]="searchForm.value.search" [seriesShowList]="seriesShowList"
        #updateSearchSeries>
      </app-search-series>
    </div>
  </div>
</div>
