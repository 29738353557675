import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './Core/components/login/login.component';
import { RegisterComponent } from './Core/components/register/register.component';
import { AuthGuard } from './Shared/guards/auth.guard';
import { AccessComponent } from './Core/components/access/access.component';
import { ServerLoginComponent } from './Core/components/server-login/server-login.component';
import { GuestGuard } from './Shared/guards/guest.guard';
import { PlayListComponent } from './Core/components/play-list/play-list.component';
import { LanguageComponent } from './Core/components/all-settings/language/language.component';
import { WatchListComponent } from './Core/components/play-list/watch-list/watch-list.component';
import { WatchPlayerComponent } from './Core/components/play-list/watch-player/watch-player.component';
import { AddPlaylistComponent } from './Core/components/add-playlist/add-playlist.component';
import { Mu3LoginComponent } from './Core/components/mu3-login/mu3-login.component';
import { XstreamLoginComponent } from './Core/components/xstream-login/xstream-login.component';
import { ChangeColorComponent } from './Core/components/all-settings/change-color/change-color.component';
import { QrLoginComponent } from './Core/components/qr-login/qr-login.component';


const routes: Routes = [
  { path: '', redirectTo: 'add-playlist', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [AuthGuard]
  },
  { path: 'language', component: LanguageComponent },
  { path: 'login', component: LoginComponent, canActivate: [GuestGuard] },
  { path:'M3U-Login',component:Mu3LoginComponent,canActivate:[GuestGuard]},
  {path:'Xstream-Login',component:XstreamLoginComponent},
  {path:'Qr-login',component:QrLoginComponent},
  { path:'add-playlist',component:AddPlaylistComponent,canActivate:[GuestGuard]},
  { path: 'register', component: RegisterComponent, canActivate: [GuestGuard] },
  { path: 'code', component: AccessComponent },
  { path: 'server-login', component: ServerLoginComponent, canActivate: [GuestGuard] },
  { path: 'playlist', component: PlayListComponent },
  { path: 'watchlist', component: WatchListComponent },
  { path: 'watchlist/watch', component: WatchPlayerComponent},
  

  // { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  // { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  // { path: 'channel/watch/:id', component: ChannelshowComponent, canActivate: [AuthGuard] },
  // { path: 'about', component: AboutComponent, canActivate: [AuthGuard] },
  // { path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
  // { path: 'search/livetv', component: SearchlivetvComponent, canActivate: [AuthGuard] },
  // { path: 'movies', component: MoviesComponent, canActivate: [AuthGuard] },
  // { path: 'search/movies', component: SearchmoviesComponent, canActivate: [AuthGuard] }, 
  // { path: 'movies/info/:id', component: MovieinfoComponent },
  // { path: 'movies/watch/:id', component: MovieplayerComponent },
  // { path: 'series', component: SeriesComponent, canActivate: [AuthGuard] },
  // { path: 'search/series', component: SearchseriesComponent, canActivate: [AuthGuard] },
  // { path: 'series/info/:id', component: SeriesinfoComponent, canActivate: [AuthGuard] },
  // { path: 'series/watch/:id/:id2', component: SeriesplayerComponent }, 
  // { path: 'favourites', component: FavouritesComponent, canActivate: [AuthGuard] },
  // { path: 'recent', component: RecentComponent, canActivate: [AuthGuard] },
  // { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  // { path: 'settings/user', component: UseraccountComponent, canActivate: [AuthGuard] },
  // { path: 'settings/contact', component: ContactComponent, canActivate: [AuthGuard] },
  // { path: 'settings/language', component: LanguageComponent, canActivate: [AuthGuard] },
  // { path: 'settings/refresh', component: RefreshComponent, canActivate: [AuthGuard] },
  // { path: 'movies/container/:id', component: MoviescontainerComponent, canActivate: [AuthGuard] },
  // { path: 'series/containerlist/:id', component: SeriescontainerlistComponent, canActivate: [AuthGuard] },
  // { path: 'search/list/:id', component: SearchlistComponent, canActivate: [AuthGuard] },
  // { path: 'series/list/:id', component: SerieslistComponent, canActivate: [AuthGuard] },
  // { path: 'series/episodes/:id', component: EpisodesComponent, canActivate: [AuthGuard] },
  // { path: 'series/episodelist/:id', component: EpisodelistComponent, canActivate: [AuthGuard] },
  // { path: 'series/container/:id/:id2', component: SeriescontainerComponent, canActivate: [AuthGuard] },
  // { path: 'live/container/:id', component: ChannelComponent, canActivate: [AuthGuard] },

  // { path: 'playlist', component: PlaylistComponent, canActivate: [AuthGuard] },

];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
