<form #userForm="ngForm" (ngSubmit)=onSubmit(userForm) class="m-auto auth auth-background">
  <div class="container-fluid">
    <div class="row justify-content-center align-items-center h-100">
      <div class="col-12 text-center">
        <div class="header-w3l ">
          <div class="logo" *ngIf="decodedata && decodedata.app_image">
            <img [src]="decodedata.app_image.app_logo" *ngIf="decodedata.app_image.app_logo">
            <h3 *ngIf="!decodedata.app_image.app_logo"><a>{{ 'COMMON.TITLE' | translate }}</a></h3>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <h3 class="login-label mb-5 ">{{ 'SERVERLOGIN.LOGIN' | translate }}</h3>
      </div>
      <div class="col col-sm-6 col-md-7 col-xl-3">
        <div class="form-group mb-lg-2 mb-4">
          <select type="text" [(ngModel)]="user.server" class="form-control-selector" name="server" #server="ngModel"
            required>
            <option value="" class="text-white">{{ 'SERVERLOGIN.SELSER' | translate }}</option>
            <option *ngFor="let item of serverList" [ngValue]="encodeUrl(item.url)" class="text-white w-100" #visibles>{{item.dns_title}}
            </option>
          </select>
          <div *ngIf="userForm.submitted && server.invalid" class="text-left pt-1">
            <p class="error-msg mb-0">{{ 'FORMERROR.SELSER' | translate  }}</p>
          </div>
        </div>
        <div class="form-group mb-lg-2 mb-4">
          <span> <i class="fa fa-user text-white input-icon" aria-hidden="true"></i></span>
          <input type="text" [(ngModel)]="user.username" class="form-control" name="username" #username="ngModel"
            placeholder="{{ 'PLACEHOLDER.USERNAME' | translate }}"  (keypress)="onKeyDown($event)" required>
          </div>
          <div *ngIf="userForm.submitted && username.invalid" class="text-left mt-0">
            <p class="error-msg mb-0">{{ 'FORMERROR.USERNAME' | translate  }}</p>
          </div>
        <div class="form-group mb-5 position-relative">
          <span>
            <i class="fa fa-key text-white input-icon" aria-hidden="true"></i>
          </span>
          <input [type]="!passwordTextType ? 'password': 'text'" [(ngModel)]="user.password" class="form-control-pass"
            name="password" #password="ngModel" placeholder="{{ 'PLACEHOLDER.PASSWORD' | translate }}" (keypress)="onKeyDown($event)" required>
          <span class="cursor-pointer" style="position: absolute; right: 10px; top: 8px;">
            <i class="fa text-white fs-24" [ngClass]="{'fa-eye-slash': passwordTextType,'fa-eye': !passwordTextType}"
              (click)="togglePasswordTextType()"></i>
          </span>
          <div *ngIf="userForm.submitted && password.invalid" class="float-left pt-1 pb-3">
            <p class="error-msg mb-0">{{ 'FORMERROR.PASSWORD' | translate  }}</p>
          </div>
        </div>

        <div class="form-group mb-lg-5 mb-4 text-start">
          <!-- <div class="d-flex ml-1"> -->

          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="customCheck1" (change)="rememberMe($event)">
            <label class="custom-control-label" for="customCheck1">{{ 'COMMON.REMLOGIN' | translate }}</label>
          </div>
          <!-- </div> -->
        </div>
        <div class="col-12 pb-4 text-center">
          <button class="login-btn" type="submit" [disabled]="isLoading">
            {{ 'COMMON.LOG_IN' | translate }}
            <div *ngIf="isLoading" class="spinner-border spinner-border-sm fs-14" style="vertical-align:middle;"
              role="status">
              <span class="sr-only">{{ 'COMMON.LOADING' | translate }}</span>
            </div>
          </button>
        </div>


        <div class="col-12 pb-md-5 pb-2">
          <h5 class="mb-0 mt-4 text-size">{{ 'SERVERLOGIN.DYWCHANGECODE' | translate }} <a
              class="ml-1 text-purple cursor-pointer"
              (click)="changeCode()">{{  'SERVERLOGIN.CHANGECODE' | translate }}</a></h5>
        </div>
      </div>
    </div>
  </div>
</form>
