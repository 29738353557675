<div class="container-fluid">
  <div class="row ml-lg-1 ">
    <div class="col-2 mt-4 d-none d-lg-block pl-0">
      <div class="row">
        <div class="col-4 mb-2 ml-0 mt-1 pr-1 d-flex mr-3 categories-res">
         <h4 class="categories-res">{{'NAV.NAV7' | translate}}</h4>
        </div>
        <div class="col-3 mb-2 ml-auto mt-0  d-flex mr-0">
          
          <button type="button" [popover]="popTemplateCat" class="sort-back border-dark text-white fs-22 px-2 "
            placement="right">
            <i class="fas fa-sort-alt"></i></button>
          <ng-template #popTemplateCat>
            <div *ngFor="let item of sortButton ;let i = index" class="mt-2">
              <button [ngClass]="{'btn-purple': item.selected}" (click)="sort('category_name',item.val)" type="button"
                class="btn sort-btn btn-dark">
                {{translateFilter(i) | translate }} </button>
            </div>
          </ng-template>
        </div>
      </div>
  <div class="row ml-lg-1 mt-1">
      <div class="col-12 text-center category-box p-0">
        <div class="d-flex flex-column bd-highlight" *ngFor="let category of categories; index as i"  [ngClass]="{'bd-highlight-selected': category.select}">
          <a class="p-2 bd-highlight over mt-2" (click)="updateCategory(category.category_id)"
           
            style="cursor: pointer;text-decoration: none;">{{category.category_name}}</a>
        </div>
      </div>
      </div>
      <!-- [routerLink]="['/movies/container/'+category.category_id]" -->
    </div>
    <div class="col-10 mb-0 pb-0 d-none d-lg-block">
      <app-movie-container [selectedcategory]="selectedcategory" [isLoading]="isLoading"></app-movie-container>
    </div>


    <div class="col-12 mt-4 d-lg-none d-block p-res">
      <div class="row">
        <div class="col-12 text-center" (click)="isCategory=!isCategory">
          <p class="sub-text border p-2">{{ 'COMMON.CATEGORIES' | translate }}
            <i class="fa fa-chevron-down ml-2"></i></p>
        </div>
        <div class="col-12 px-0 " *ngIf="isCategory">
          <div class="d-flex mb-2 ml-auto mt-1 mr-3 px-3">
            <button type="button" [popover]="popTemplateCat" class="sort-back border-dark text-white fs-22 ml-auto ">
              <i class="fas fa-sort-alt"></i></button>
          </div>
          <ng-template #popTemplateCat>
            <div *ngFor="let item of sortButton" class="mt-2">
              <button [ngClass]="{'btn-purple': item.selected}" (click)="sort('category_name',item.val)" type="button"
                class="btn sort-btn btn-dark">
                {{ item.label }}
              </button>
            </div>
          </ng-template>

          <div class="col-12">
            <div class="d-flex flex-column bd-highlight1  category-box">
              <a class="p-2 bd-highlight1 over1 over mt-2" (click)="updateCategory(category.category_id)"
                [ngClass]="{'bd-selected': category.select}" *ngFor="let category of categories; index as i"
                style="cursor: pointer;text-decoration: none;">{{category.category_name}}</a>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-12 m-0 p-0 d-lg-none d-block">
      <app-movie-container [selectedcategory]="selectedcategory"></app-movie-container>
    </div>

  </div>
</div>
