<div class="container-fluid">
  <div class="row my-3 mx-md-2 mx-0 ">
    <div class="col-12 px-0">
      <div class="form-group has-search  mr-2">
        <span class="fa fa-search form-control-feedback"></span>
        <input type="text" class="form-control" placeholder="{{ 'COMMON.SEARCH_HERE' | translate }}"
          [(ngModel)]="filterTerm">
        <button type="button" class="btn bg-transparent searchclear" *ngIf="filterTerm" (click)="filterTerm=''">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row mx-md-3 mx-0">
    <!-- <div class="cursor-pointer fs-16 " style="z-index: 1;">
      <a style="color: white" (click)="back()" class="d-flex">
        <i class="fas fa-arrow-alt-left d-flex" style="align-items: center;padding-right: 5px;"></i>   {{ 'COMMON.BACK' | translate }}
      </a>
    </div> -->
    <div class="col-md-12 d-flex">
      <div class="cursor-pointer fs-16 ">
        <a style="color: white" (click)="back()" class="d-flex">
          <i class="fas fa-arrow-alt-left d-flex" style="align-items: center;padding-right: 5px;"></i>   {{ 'COMMON.BACK' | translate }}
        </a>
      </div>
      <div class="ml-auto mr-auto mt-1">
        <h3 class="series-header p-0">{{seriesinfo?.info.name}}</h3>
      </div>
    </div>
    <div class="col-md-12 mb-0 pb-0">
      <h3 class="text-left mb-1 pb-0 series-header">{{ 'SERIES.EPISODES' | translate }}</h3>
    </div>
    <div class="col-lg-4  col-xl-2 col-6 text-left " *ngFor="let select of allepisodes|filter:filterTerm; index as i">
      <div class="mb-4">
        <div class="">
          <div>
            <img [src]="select.info.movie_image" class="search-img" style="max-height: 220px;" loading=lazy>
          </div>
        </div>

        <div class="d-flex mt-2">
          <h6 class=" search-title mb-1" title="{{select.title }}">{{select.title}}</h6>
          <a class="ml-auto" [routerLink]="'/series/watch/' + select.id + '/' + select.container_extension"
            target="_blank"><i class="fas fa-play-circle fs-18 text-purple ml-auto cursor-pointer"></i></a>
        </div>
        <h6 class="text-purple episode-title" *ngIf="select.dur"><span
            class="mr-1">{{ 'SERIES.DURATION' | translate }}:</span><span
            *ngIf="select.dur?.hour>0">{{select.dur?.hour}}
            H</span> <span>{{select.dur?.minute}}m</span> </h6>
      </div>
    </div>

  </div>

</div>
