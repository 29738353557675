import { Inject, Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
// Need to test
// import { Observable } from 'rxjs';
// tslint:disable-next-line:import-blacklist
// import 'rxjs/Rx';
import { catchError, finalize, map } from 'rxjs/operators';
// import { LoggingService } from './logging.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Observable, of  } from 'rxjs';
import { throwError } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class ResponseInterceptor implements HttpInterceptor {

    requestServiceCount = 0;
    constructor(
        private injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        // private loggingService: LoggingService,
        private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const elem = document.getElementById('app-loader');
        if (elem) {
            elem.classList.add('d-flex');
        }
        this.requestServiceCount++;
        return next.handle(req)
            .pipe(
                map(response => response),
                catchError((exception: any) => {
                    this.requestServiceCount = 0;
                    this.hideLoader();
                    // return of(exception);
                    return throwError(exception);
                    // return Observable.throw(exception);
                }),
                finalize(() => {
                    this.requestServiceCount--;
                    if (this.requestServiceCount <= 0) {
                        this.hideLoader();
                        this.requestServiceCount = 0;
                    }
                })

            );
    }

    hideLoader() {
        const elem = document.getElementById('app-loader');
        if (elem) {
            elem.classList.remove('d-flex');
        }
    }

}
