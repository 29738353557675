import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SeriesService } from 'src/app/Core/services/series.service';
import { UtilService } from 'src/app/Core/services/util.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-serieslist',
  templateUrl: './serieslist.component.html',
  styleUrls: ['./serieslist.component.css']
})
export class SerieslistComponent implements OnInit {
  isLoading: boolean = false;
  episodeLen: any;
  seasonLen: any;
  seasonId: any;
  seriesinfo: any;
  filterTerm: string;
  idpath: string;
  seriesFrom: any;
  seasonNoselcted: any;
  categoryId: any;

  constructor(private router: Router, private seriesService: SeriesService, private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute, private utilService: UtilService) {
    this.route.queryParams.subscribe((params: any) => {
      this.seriesFrom = params.seriesBy;
      this.seasonNoselcted = params.season;
      this.categoryId = params.categoryId;
    });
  }

  ngOnInit(): void {
    this.idpath = this.route.snapshot.params['id'];
    this.getSeriesInfo(this.idpath);
  }
  getSeriesInfo(id: any) {
    this.utilService.showSpinner()
    this.isLoading = true;
    this.seriesService.getSeriesDetail(id).subscribe((res: any) => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      this.seriesinfo = res;
      this.episodeLen = _.size(res.episodes);
      this.seasonLen = res.seasons.length;
      this.seriesinfo.seasons.map((ele: any, index) => {
        if (this.seasonNoselcted) {
          if (ele.season_number == this.seasonNoselcted) {
            ele.select = true;
            this.seasonId = this.seasonNoselcted;
            this.durationCalculate();
          }
          else {
            ele.select = false;
          }
        } else {
          if (index == 0) {
            ele.select = true;
            this.seasonId = ele.season_number;
            this.durationCalculate();
          }
          else {
            ele.select = false;
          }
        }
      });

    }, err => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      this.utilService.showErrorCall(err);
    });
  }
  onSelectSeason(seasonId: any) {
    this.seasonId = seasonId;

    this.seriesinfo.seasons?.map((ele: any, index) => {
      (ele.season_number == seasonId) ? ele.select = true : ele.select = false;
    });
    this.durationCalculate();
  }

  durationCalculate() {
    if (this.seriesinfo.episodes) {
      for (let index = 0; index < this.seriesinfo.episodes[this.seasonId].length; index++) {
        const duration: any = {
          hour: 0,
          minute: 0
        };
        const element = this.seriesinfo.episodes[this.seasonId][index];
        let date = moment(element.info.duration, 'HH:mm:ss');
        duration.hour = moment(date).hour();
        duration.minute = moment(date).minute();
        element.dur = duration;
      }
    }
  }
  // playSeries(id, ext) {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['/series/watch/' + id + '/' + ext])
  //   );
  //   window.open(url, '_blank');
  // }

  back(catId) {
    console.log(this.seriesFrom);
    if (this.seriesFrom == 'series') {
      this.router.navigate(['/series/info/' + this.idpath], { queryParams: { seriesBy: this.seriesFrom } });
    }
    if (this.seriesFrom == 'search') {
      this.router.navigate(['/search']);
    }
    if (this.seriesFrom == 'series-grid') {
      this.router.navigate(['/series'], { queryParams: { category: catId } });
    }
    if (!catId) {
      window.history.back();
    }
  }
}
