<nav class="navbar sticky-top text-header-backClr nav-border header">

  <div class="navbar-nav mr-auto">
    <p class="fw-700 header-text mb-0">Playlist</p>
  </div>
  <div class="mr-lg-4 mr-1 ">
    <p class="header-time-text pb-0 mb-0 float-right"><span class="mr-3 fw-500">{{time}}</span><span
        class="text-muted">{{date}}</span></p>
  </div>
  <div>
  </div>

  <div class=" d-flex-center mr-3">
    <button (click)="sort('title')" title="Sort By Title {{ isDesc ? 'Z-A' : 'A-Z'}}" type="button"
      class="btn text-header-backClr header-text border-dark text-white" placement="left"><i
        class="fas fa-sort-alt"></i></button>
  </div>
</nav>

<nav class="mx-md-2 mx-1 my-2">
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a class="nav-item nav-link active text-white mr-3 px-5" id="nav-home-tab" data-toggle="tab" href="#nav-all"
      role="tab" aria-controls="nav-home" aria-selected="true" (click)="isAll=true">All</a>
    <a class="nav-item nav-link text-white px-5 ml-2" id="nav-profile-tab" data-toggle="tab" href="#nav-fav" role="tab"
      aria-controls="nav-profile" aria-selected="false" (click)="getFavouriteList()" (click)="isAll=false">Favourite
      Playlist</a>
    <button type="button" class="btn button-info ml-auto mr-1 my-1" routerLink="/playlist"> <i
        class="fa fa-arrow-left mr-2"></i>Change PlayList</button>
  </div>

</nav>
<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-home-tab">
    <div class="row  mb-0 movie">
      <div class="col-12 px-0">
        <div class="form-group has-search  mx-2 px-2">
          <span class="fa fa-search form-control-feedback"></span>
          <input type="text" class="form-control" placeholder="{{ 'PLACEHOLDER.SEARCHHERE' | translate }}"
            [(ngModel)]="filterTerm">
          <button type="button" class="btn bg-transparent searchclear" *ngIf="filterTerm" (click)="filterTerm=''">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
      <div class="col-12">
        <div class="row movie-box pl-2">
          <div class="col-md-3 col-lg-3 col-xl-2 col-6 px-2 bd-highlight"
            *ngFor="let select of playlistM3u|filter:filterTerm; index as i">
            <div class="mb-2">
              <div class="imageContainer-search">
                <div>
                  <img [src]="" class="search-img" loading=lazy>
                  <!-- "[routerLink]="['/watchlist/watch/',select.url]"" -->
                </div>
                <div class="fav-icon icon-color" title="add to Favourit" (click)="onSave(select)"> <i
                    class="far fa-heart  cursor-pointer" *ngIf="!select.isFavourit"></i>
                </div>
                <div class="fav-icon icon-color"> <i class="fas fa-heart  cursor-pointer" *ngIf="select.isFavourit"
                    (click)="deleteFavourite(select)"></i>
                </div>
                <div class="d-flex mt-1">
                  <p class="search-title text-left" title="{{select.title}}">{{select.title}}</p>
                  <a class="ml-auto" [routerLink]="'/watchlist/watch'" [queryParams]="{id: select.url}"
                    target="_blank"><i class="fas fa-play-circle fs-18 text-purple ml-auto cursor-pointer"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="tab-pane fade" id="nav-fav" role="tabpanel" aria-labelledby="nav-profile-tab">
    <div class="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-home-tab">
      <div class="row  mb-0 movie">
        <div class="col-12 text-center" *ngIf="favPlayList?.length==0">
          <h3 class="my-5">No Data Found</h3>
        </div>
        <div class="col-12 px-0" *ngIf="favPlayList?.length>0">
          <div class="form-group has-search  mx-2 px-2">
            <span class="fa fa-search form-control-feedback"></span>
            <input type="text" class="form-control" placeholder="{{ 'PLACEHOLDER.SEARCHHERE' | translate }}"
              [(ngModel)]="filterTermfav">
            <button type="button" class="btn bg-transparent searchclear" *ngIf="filterTermfav"
              (click)="filterTermfav=''">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <div class="col-12">
          <div class="row movie-box pl-2">
            <div class="col-md-3 col-lg-3 col-xl-2 col-6 px-2 bd-highlight"
              *ngFor="let select of favPlayList|filter:filterTermfav; index as i">
              <div class="mb-2">
                <div class="imageContainer-search">
                  <div>
                    <img [src]="" class="search-img" loading=lazy>
                    <!-- "[routerLink]="['/watchlist/watch/',select.url]"" -->
                  </div>

                  <div class="fav-icon icon-color"> <i class="fas fa-heart  cursor-pointer"
                      (click)="deletefavList(select)"></i>
                  </div>
                  <div class="d-flex mt-1">
                    <p class="search-title text-left" title="{{select.title}}">{{select.title}}</p>
                    <a class="ml-auto" [routerLink]="'/watchlist/watch'" [queryParams]="{id: select.url}"
                      target="_blank"><i class="fas fa-play-circle fs-18 text-purple ml-auto cursor-pointer"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>

</div>
