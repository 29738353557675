import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';


// const domain=localStorage.getItem('playlisturl');
// const username=localStorage.getItem('username');
// const password=localStorage.getItem('password');

const domain = localStorage.getItem('fullUrl');
const username = localStorage.getItem('fullUsername');
const password = localStorage.getItem('fullPassword');
// const domain = 'https://vmp.one';
// const domain ='https://dmdedi.online'
// const domain = 'http://xtremity.tv:2052';

// localStorage.setItem('playlisturl', domain);
// const username = 'jum';
// const password = 'quin';
// const username = 'qyf9ax';
// const password = 'p7au3w';

localStorage.setItem('username', username);
localStorage.setItem('password', password);

const url =   domain + '/player_api.php?username=' + username + '&password=' + password + '&action=';
// console.log(url);

const API_URL1 = url + 'get_live_categories';
const API_URL2 = url + 'get_live_streams&category_id=';
const INITIAL_CATEGORY = url + 'get_live_streams&category_id=131';
const API_URL3 = url + 'get_live_streams';
const API_URL4 = url + 'get_vod_streams';
const API_URL5 = url + 'get_series';

const API_URL6 = url + 'get_vod_categories';
const API_URL7 = url + 'get_vod_streams&category_id=';
const API_URL8 = url + 'get_vod_info&vod_id=';

const API_URL9 = url + 'get_series_categories';
const API_URL10 = url + 'get_series&category_id=';
const API_URL11 = url + 'get_series_info&series_id=';

const API_URL100 = url + 'get_series_info&series_id=';
const API_URL101 = url + 'get_short_epg&stream_id=';

const DECODE_DATA = 'http://appi.purplesmarttv.com/api.php?code=D4F882';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) {
  }

  public getLiveStreams(): Observable<any> {
    return this.http.get(API_URL3).pipe(map(res => res));
  }
  public getLiveMovies(): Observable<any> {
    return this.http.get(API_URL4).pipe(map(res => res));
  }
  public getLiveSeries(): Observable<any> {
    return this.http.get(API_URL5).pipe(map(res => res));
  }

  public getVodCategories(): Observable<any> {
    return this.http.get(API_URL6).pipe(map(res => res));
  }
  public selectedCategory(url): Observable<any> {
    return this.http.get(API_URL7 + url).pipe(map(res => res));
  }
  public getMovieInfo(url): Observable<any> {
    return this.http.get(API_URL8 + url).pipe(map(res => res));
  }

  public getSeriesCategories(): Observable<any> {
    return this.http.get(API_URL9).pipe(map(res => res));
  }
  public selectedSeriesCategory(url): Observable<any> {
    return this.http.get(API_URL10 + url).pipe(map(res => res));
  }
  public getSeriesInfo(url): Observable<any> {
    return this.http.get(API_URL11 + url).pipe(map(res => res));
  }
  public getSeriesInfo1(url): Observable<any> {
    return this.http.get(API_URL100 + url).pipe(map(res => res));
  }

  public get(): Observable<any> {
    return this.http.get(API_URL1).pipe(map(res => res));
  }
  public getc(url): Observable<any> {
    return this.http.get(API_URL2 + url).pipe(map(res => res));
  }

  public getcepg(url): Observable<any> {
    return this.http.get(API_URL101 + url).pipe(map(res => res));
  }
  public getinitial(): Observable<any> {
    return this.http.get(INITIAL_CATEGORY).pipe(map(res => res));
  }
  public getdecodedata(): Observable<any> {
    return this.http.get(DECODE_DATA, { responseType: 'text' }).pipe(map(res => res));
  }
}
