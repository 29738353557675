<form class="m-auto auth auth-background h-100vh">
  <div class="container-fluid">
    <div class="row pi-login  justify-content-center align-items-center">
      <div class="col-md-12 ">
        <div class="header-w3l">
          <div class="logo ml-2">
            <h3 class="text-center"><a href="#" >{{ 'COMMON.TITLE' | translate }}</a></h3>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-12 ">
            <div class="add-playlist d-flex">
              <h5 class="ml-3 pt-1 w-25 media">{{'ADDPLAYLIST.ADD_PLAYLIST_NAME' | translate }}</h5>
              <span class="d-flex w-100 ">
                <hr class="hr-border">
              </span>
            </div>
          </div>
        </div>
       
          <div class="row pleft mt-4">
            <!-- <div class="col-md-4 col-sm-1 resp" *ngIf="playlistData.login_m3u" >
              <div class="card" routerLink="/M3U-Login">
                <div class="col-md-3 d-flex align-items-center res-img ">
                  <div class="img pt-1 pl-0">
                    <img src="/assets/images/load_m3u.svg" width="50px" height="50px" alt="">
                  </div>
                </div>
                <div class="col-md-9  d-flex pl-0 ">
                  <div class="card-head ">
                    {{'ADDPLAYLIST.M3U_URL' | translate }}
                  </div>
                </div>
              
              </div>
            </div> -->
            <!-- <div class="col-md-4 col-sm-4 resp" *ngIf="playlistData.login_xtream"> -->
              <div class="col-md-4 col-sm-4 resp">
              <div class="card" routerLink="/Xstream-Login">
                <div class="col-md-3 d-flex align-items-center res-img ">
                  <div class="img pt-1 pl-0">
                    <img src="/assets/images/load_xstream.svg" width="50px" height="50px" alt="">
                  </div>
                </div>
                <div class="col-md-9  d-flex pl-0">
                  <div class="card-head ">
                    {{'ADDPLAYLIST.Xstream_URL' | translate }}

                    
                  </div>
                </div>
              
              </div>
            </div>

          </div>

        <!-- second card  -->
        <div class="row mt-5">
          <div class="col-md-12">
            <div class="add-playlist d-flex">
              <h5 class="ml-3 pt-1 w-25 media">{{'ADDPLAYLIST.LOGIN_OPTION' | translate}}</h5>
              <span class="d-flex w-100 ">
                <hr class="hr-border">
              </span>
            </div>

          </div>
        </div>
        <div class="row pleft mt-4">
          <!-- <div class="col-md-4 resp" *ngIf="loginDetails.login_id_pin"> -->
            <div class="col-md-4 resp">
            <div class="card" routerLink="/login">
              <div class="col-md-3 d-flex align-items-center res-img ">
                <div class="img pt-1 pl-0">
                  <img src="/assets/images/code.svg" width="50px" height="50px" alt="">
                </div>
              </div>
              <div class="col-md-9  d-flex pl-0">
                <div class="card-head ">
                 {{'ADDPLAYLIST.USER_ID' | translate}}
                </div>
              </div>
             
            </div>
          </div>
          <!-- <div class="col-md-4 resp" *ngIf="loginDetails.login_code_universal"> -->
            <div class="col-md-4 resp" >
            <div class="card" (click)="checkCodeLogin()">
              <div class="col-md-3 d-flex align-items-center res-img ">
                <div class="img pt-1 pl-0">
                  <img src="/assets/images/code.svg" width="50px" height="50px" alt="">
                </div>
              </div>
              <div class="col-md-9  d-flex pl-0">
                <div class="card-head ">
                 {{ 'ADDPLAYLIST.LOGIN_CODE' | translate}}
                </div>
              </div>
             
            </div>
          </div>

          <!-- <div class="col-md-4 resp" *ngIf="loginDetails.login_code_universal"> -->
            <div class="col-md-4 resp" >
            <div class="card"  routerLink="/Qr-login">
              <div class="col-md-3 d-flex align-items-center res-img ">
                <div class="img pt-1 pl-0">
                  <img src="/assets/images/qr_icon.svg" width="50px" height="50px" alt="">
                </div>
              </div>
              <div class="col-md-9  d-flex pl-0">
                <div class="card-head ">
                 {{ 'LOGIN.LOGINWQR' | translate}}
                </div>
              </div>
             
            </div>
          </div>

        </div>

        <!-- third card  -->
        <!-- <div class="row mt-5">
          <div class="col-md-12">
            <div class="add-playlist d-flex">
              <h5 class="ml-3 pt-1 w-25 media">{{'ADDPLAYLIST.DEVICE_ACTIVATE' | translate}}</h5>
              <span class="d-flex w-100 ">
                <hr class="hr-border">
              </span>
            </div>

          </div>
        </div>
        <div class="row pleft mt-4">
          <div class="col-md-4 resp" *ngIf="deviceActivation.login_activate">
            <div class="card">
              <div class="col-md-3 d-flex align-items-center res-img ">
                <div class="img pt-1 pl-0">
                  <img src="/assets/images/code.svg" width="50px" height="50px" alt="">
                </div>
              </div>
              <div class="col-md-9  d-flex pl-0">
                <div class="card-head ">
                 {{'ADDPLAYLIST.ACTIVE_DEVICE' | translate}}
                </div>
              </div>
             
            </div>
          </div>
          <div class="col-md-4 resp" *ngIf="deviceActivation.login_mac_key">
            <div class="card ">
              <div class="col-md-3 d-flex align-items-center res-img ">
                <div class="img pt-1 pl-0">
                  <img src="/assets/images/code.svg" width="50px" height="50px" alt="">
                </div>
              </div>
              <div class="col-md-9  d-flex pl-0">
                <div class="card-head ">
                 {{'ADDPLAYLIST.MAC_KEY' | translate}}
                </div>
              </div>
             
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</form>   