import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';


import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './Core/components/login/login.component';
import { RegisterComponent } from './Core/components/register/register.component';
import { SearchComponent } from './Core/components/search-new/search/search.component';
import { MoviesComponent } from './Core/components/vod/movies/movies.component';
import { SeriesComponent } from './Core/components/vod/series/series.component';
import { MovieinfoComponent } from './Core/components/vod/movies/movieinfo/movieinfo.component';
import { FavouritesComponent } from './Core/components/favourites/favourites.component';
import { RecentComponent } from './Core/components/recent/recent.component';
import { MovieplayerComponent } from './Core/components/vod/movies/movieplayer/movieplayer.component';
import { SeriesplayerComponent } from './Core/components/vod/series/seriesplayer/seriesplayer.component';
import { HomeComponent } from './Core/components/home/home.component';
import { SettingsComponent } from './Core/components/settings/settings.component';
import { SidebarComponent } from './Core/components/settings/sidebar/sidebar.component';
import { SerieslistComponent } from './Core/components/vod/series/serieslist/serieslist.component';
import { ContactComponent } from './Core/components/settings/contact/contact.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';
import { EpisodesComponent } from './Core/components/vod/series/episodes/episodes.component';
import { RefreshComponent } from './Core/components/settings/refresh/refresh.component';
import { EpisodelistComponent } from './Core/components/vod/series/episodelist/episodelist.component';
import { ToastrModule } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { AboutComponent } from './Core/components/about/about.component';
// import { PlaylistComponent } from './Core/components/xstream/playlist/playlist.component';
import { AccessComponent } from './Core/components/access/access.component';
import { ServerLoginComponent } from './Core/components/server-login/server-login.component';
import { CookieService } from 'ngx-cookie-service';
import { CookieModule } from '@gorniv/ngx-universal';
import { NgOtpInputModule } from 'ng-otp-input';
import { AppInitilizeService } from './app-initializer.service';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { MovieContainerComponent } from './Core/components/vod/movies/movie-container/movie-container.component';
import { SeriesGridComponent } from './Core/components/vod/series/series-grid/series-grid.component';
import { SeriesDetailComponent } from './Core/components/vod/series/series-detail/series-detail.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PlayListComponent } from './Core/components/play-list/play-list.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ConfirmDialogComponent } from './Core/components/confirmation-popup/confirmation-popup.component';
import { SearchLivetvComponent } from './Core/components/search-new/search-livetv/search-livetv.component';
import { SearchMovieComponent } from './Core/components/search-new/search-movie/search-movie.component';
import { SearchSeriesComponent } from './Core/components/search-new/search-series/search-series.component';
import { ResponseInterceptor } from './http-intercepter';
import { DirectiveModule } from './Core/components/directives/directive.module';
import { CategoriesComponent } from './Core/components/categories/categories.component';
import { LanguageComponent } from './Core/components/all-settings/language/language.component';
import { AllSettingsComponent } from './Core/components/all-settings/all-settings.component';
import { ContactUsComponent } from './Core/components/all-settings/contact-us/contact-us.component';
import { UseraccountComponent } from './Core/components/all-settings/useraccount/useraccount.component';
import { WatchListComponent } from './Core/components/play-list/watch-list/watch-list.component';
import { WatchPlayerComponent } from './Core/components/play-list/watch-player/watch-player.component';
import { ChangeColorComponent } from './Core/components/all-settings/change-color/change-color.component';
import { PlyrModule } from 'ngx-plyr';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { QRCodeModule } from 'angularx-qrcode';
import { QrLoginComponent } from './Core/components/qr-login/qr-login.component';
import { NgxSpinnerModule } from "ngx-spinner";
// AoT requires an exported function for factories

// export function initApp(appInitilizeService: AppInitilizeService) {
//   return () => appInitilizeService.getAppConfig();
// }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,  
    MovieContainerComponent,
    SeriesGridComponent,
    SeriesDetailComponent,    
    LoginComponent,
    RegisterComponent,
    ServerLoginComponent,
    SearchComponent,
    MoviesComponent,
    SeriesComponent,
    MovieinfoComponent,   
    FavouritesComponent,
    RecentComponent,
    MovieplayerComponent,
    SeriesplayerComponent,
    HomeComponent,
    SettingsComponent, 
    SidebarComponent,  
    SerieslistComponent,   
    ContactComponent,
    LanguageComponent,
    EpisodesComponent,
    RefreshComponent,   
    EpisodelistComponent,
    AboutComponent,   
    AccessComponent,
    PlayListComponent,
    ConfirmDialogComponent,   
    SearchMovieComponent,
    SearchSeriesComponent,
    CategoriesComponent,
    AllSettingsComponent,
    ContactUsComponent,
    UseraccountComponent,
    WatchListComponent,
    WatchPlayerComponent,
    SearchLivetvComponent,
    ChangeColorComponent,
    QrLoginComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgxShimmerLoadingModule,
    HttpClientModule,
    AppRoutingModule,
    Ng2SearchPipeModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatIconModule,
    CookieModule.forRoot(),
    ToastrModule.forRoot(),
    NgOtpInputModule,
    SlickCarouselModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    DirectiveModule,
    PlyrModule,
    NgxSpinnerModule,
    QRCodeModule,
    BrowserModule,
    VirtualScrollerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      }
    }),
  ],
  providers: [
    HttpClient, CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initApp,
    //   multi: true,
    //   deps: [AppInitilizeService]
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
