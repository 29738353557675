import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import videojs from 'video.js';
import { PlyrComponent } from 'ngx-plyr';
import { HlsjsPlyrDriver } from './hlsjs-plyr-driver';
// import "videojs-contrib-hls";

@Component({
  selector: 'app-watch-player',
  templateUrl: './watch-player.component.html',
  styleUrls: ['./watch-player.component.css']
})
export class WatchPlayerComponent implements OnInit {
  url: any;
  @ViewChild('plyr', { static: false }) plyr: PlyrComponent;
  constructor(private route: ActivatedRoute,) {

    this.route.queryParams.subscribe((param: any) => {
      this.url = param.id;
    });
  }

  hlsjsDriver1 = new HlsjsPlyrDriver(true);
  player;
  plyrOptions = {
    volume: 0,
    muted: true,
    autoplay: true,
  };
  poster: any = "assets/images/logo_side_orignal.png"
  sources: Plyr.Source[] = [{
    type: 'video',
    src: null,
  }];

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.url) {
      this.playM3u8();
    }
  }

  playM3u8() {
    this.sources[0].src = this.url;
    setTimeout(() => {
      this.hlsjsDriver1.load(this.url);
    }, 200);
    try {
      this.plyr.player.muted = true;
      this.plyr.player.play();
    }
    catch (error) {
      console.log('not interacted with screen so playing muted', error);
      this.plyr.player.volume = 0;
      this.plyr.player.muted = true;
      this.plyr.player.play();
    }
  }


}
