import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Client } from '../../models/Client';
import { AuthentictionService } from '../../services/authentiction.service';
import { Constant } from '../../services/data';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-server-login',
  templateUrl: './server-login.component.html',
  styleUrls: ['./server-login.component.css']
})
export class ServerLoginComponent implements OnInit {
  public isLoading: boolean = false;
  public serverList: any;
  public decodedata: any;
  inputValue:any
  public user: Client = {
    server: '',
    username: '',
    password: '',
    rememberMe: false,
  };
  public passwordTextType: boolean;


  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  constructor(private router: Router, private authService: AuthentictionService, private utilService: UtilService, private changed: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getServerList();
    this.fillForm();
  }

  encodeUrl(url: string): string {
    return btoa(url); // Base64 encoding
  }
  getSelectedServerUrl(url): string {
    const encodedUrl = url;
    if (encodedUrl) {
      return atob(encodedUrl); // Decode Base64
    }
    return ''; // or handle the case where the encodedUrl is not available
  }

  fillForm() {
    if (this.utilService.getValueCookie(Constant.ISREMEMBER_XKEY)) {
      this.user.username = this.utilService.getValueCookie(Constant.XSTREAMUSERNAME_KEY);
      this.user.password = this.utilService.getValueCookie(Constant.XSTREAMPASSWORD_KEY);
      this.user.server = this.utilService.getValueCookie(Constant.XSTREAMURL_KEY);
      this.changed.detectChanges();
    }
  }
  getServerList() {
    const decode1 = atob(localStorage.getItem('Xtoken'));
    const decode2 = atob(decode1);
    const decode3 = atob(decode2);
    const decode4 = atob(decode3);
    const data = JSON.parse(decode4);
    this.decodedata = data;
    this.serverList = this.decodedata.app_dns;
    
    if (this.decodedata.app_image.back_image) {
      const el: any = document.querySelector('.auth-background');
      if (el) {
        el.style.background = `url('${this.decodedata.app_image.back_image}')`;
      }
    }
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.isLoading = true;
    this.utilService.showSpinner()
    const urls = this.getSelectedServerUrl(this.user.server)
    
      this.authService.serverDnsLogin(urls, this.user.username, this.user.password)
        .subscribe((res: any) => {
          this.isLoading = false;
          this.utilService.hideSpinner()
          //  this.getSelectedServerUrl()

          if (res) {
            if (res.user_info.auth) {
              this.utilService.removeFullModeStorage();
              // this.utilService.saveValueStorage(Constant.XSTREAMUSERID_KEY, res.user_info.userid);
              if (this.user.rememberMe) {
                this.utilService.clearCookieStorage();
                this.utilService.saveValueCookie(Constant.ISREMEMBER_XKEY, true);
                this.utilService.saveValueCookie(Constant.XSTREAMUSERNAME_KEY, this.user.username);
                this.utilService.saveValueCookie(Constant.XSTREAMPASSWORD_KEY, this.user.password);
                this.utilService.saveValueCookie(Constant.XSTREAMURL_KEY, urls);
              }
              else {
                this.utilService.clearCookieStorage();
              }
              this.utilService.saveValueStorage(Constant.XSTREAMUSERNAME_KEY, res.user_info.username);
              this.utilService.saveValueStorage(Constant.XSTREAMPASSWORD_KEY, res.user_info.password);
              this.utilService.saveValueStorage(Constant.XSTREAMURL_KEY, urls);

              if (this.decodedata.app_image.app_logo) {
                this.utilService.saveValueStorage(Constant.APP_LOGO, this.decodedata.app_image.app_logo);
              } else {
                this.utilService.removeLocalStorage(Constant.APP_LOGO);
              }

              if (this.decodedata.app_image.back_image) {
                this.utilService.saveValueStorage(Constant.APP_BACKGROUND, this.decodedata.app_image.back_image);
              } else {
                this.utilService.removeLocalStorage(Constant.APP_BACKGROUND);
              }

              this.router.navigate(['/home']);
              // this.router.navigate(['playlist'], { queryParams: { isRemember: this.user.rememberMe } });
              this.utilService.showErrorSuccess('', "Login Succesfully.");
            } else {
              this.utilService.showErrorInfo('', "Invalid credential.");

            }
          }
        }, err => {
          this.utilService.hideSpinner()
          this.isLoading = false;
          this.utilService.showErrorToast('', 'Authentication failed with server.');
        });
    }
  }
  hide(){
    const hideoption=document.getElementById('#visibles');
  }

  
  rememberMe(event: any) {
    this.user.rememberMe = event.target.checked;
  }
  changeCode() {
    localStorage.removeItem('Xtoken');
    localStorage.removeItem('code');
    this.router.navigate(['/code']);
  }
  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }
  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault(); // Prevent the spacebar key from inserting a space
      this.inputValue = this.inputValue.replace(/\s/g, ''); // Remove all whitespace from the input value
    }
  }
}
