import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class SeriesService {

  domain: any = '';
  username: any = '';
  password: any = '';
  url: any = '';

  Xurl: any;
  

  constructor(public http: HttpClient, public utilService: UtilService) {    
  }
  
  getDataLcal() {
    this.Xurl = this.utilService.getXstreamUrl();
    if (this.Xurl) {
      this.domain = this.utilService.getXstreamUrl();
      this.username = this.utilService.getXstreamUsername();
      this.password = this.utilService.getXstreamPassword();
    }
    else {
      this.domain = this.utilService.getFullUrl();
      this.username = this.utilService.getFullUsername();
      this.password = this.utilService.getFullPassword();
    }
    this.url = this.domain + '/player_api.php?username=' + this.username + '&password=' + this.password + '&action=';
    console.log(this.username, this.password);
    return this.url;
    
  }

  public getSeriesCategories() {
    // console.log(this.username, this.password);
    return this.http.get( this.getDataLcal() + 'get_series_categories');
  }
  public selectedCategoryWiseSeriesList(id: any) {
    return this.http.get(this.getDataLcal() + 'get_series&category_id=' + id);
  }
  public getSeriesList() {
    return this.http.get( this.getDataLcal() + 'get_series');
  }
  public getSeriesDetail(id: any) {
    localStorage.setItem('previousPage', window.location.href);
    return this.http.get( this.getDataLcal() + 'get_series_info&series_id=' + id);
  }
  public getStreamSeriesUrl(id: any, ext: any) {
    this.getDataLcal();
    return (  this.domain + '/series/' + this.username + '/' + this.password + '/' + id + '.' + ext).replace(environment.cdnurl,'');
  }
  
}
