<div class="container-fluid mb-0 mx-1 pb-0 mt-2 movie">
  <div class="row  mb-0">
    <div class="col-12 px-0">
      <div class="form-group has-search mt-3 mr-2">
        <span class="fa fa-search form-control-feedback"></span>
        <input type="text" class="form-control" placeholder="{{ 'COMMON.SEARCH_HERE' | translate }}"
          [(ngModel)]="filterTerm" (keyup)="searchMovies()" (keypress)="preventSpace($event)"  maxlength="120">
        <button type="button" class="btn bg-transparent searchclear" *ngIf="filterTerm" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    
    <div class="col-12">
      <div class="row movie-box">
        <div class="col-12" *ngIf="filteredMovie?.length<1">
          <h3 class="d-flex-center mt-5">{{'COMMON.DATA_NOT_FOUND' | translate}}</h3>
        </div>

        <!-- <div class="col-md-3 col-lg-3 col-xl-2 col-6 px-2 bd-highlight"
          *ngFor="let select of filteredMovie|filter:filterTerm; index as i"> -->
          <div class="col-md-3 col-lg-3 col-xl-2 col-6 px-2 bd-highlight"
          *ngFor="let select of filteredMovie| filter:filterTerm; index as i">
          <div class="mb-2">
            <div class="imageContainer-search">
              <div  type="button" data-toggle="modal" data-target="#exampleModalCenter" data-backdrop="false" [routerLink]="'/movies/watch/' + select.stream_id" target="_self">
                <div class="image-overlay"  (click)="gotoDetail(select.stream_id)">
                  <img [src]="select.stream_icon" class="search-img" loading=lazy>
                  <!-- [routerLink]="['/movies/info/'+select.stream_id]" [queryParams]="{movieBy: 'movies',categoryId:categoryId}"> -->

                </div>
              </div>
              <div class="fav-icon icon-color" (click)="onSave(select)"> <i class="far fa-heart  cursor-pointer"
                  *ngIf="!select.isFavourit"></i>
              </div>
              <div class="fav-icon icon-color"> <i class="fas fa-heart  cursor-pointer" *ngIf="select.isFavourit"
                  (click)="deleteFavourite(select)"></i>
              </div>
              <div class="d-flex mt-1">
                <p class="search-title text-left" title="{{select.name}}">{{select.name}}</p>
                <a class="ml-auto" [routerLink]="'/movies/watch/' + select.stream_id" target="_self" ><i
                    class="fas fa-play-circle fs-18 text-purple ml-auto cursor-pointer"></i></a>
                <!-- <a class="ml-auto" (click)="playvideo(select.stream_id,select.container_extension)"><i
                    class="fas fa-play-circle fs-18 text-purple ml-auto cursor-pointer"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
