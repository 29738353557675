<div class="container-fluid main-category category">
  <div class="row category">
    <!-- <div class="col-lg-3 col-xl-3 col-md-3 col-12  ">
        <div class="border border-white row mt-5">

        <div class="col-12 pt-3 text-center d-flex-center pb-2">
          <p class="sub-text mb-0">{{ 'COMMON.CATEGORIES' | translate }}</p>
        </div>
        
        <div class="col-12">
          <div class="category-box cat-btn">
            <div class="p-2 over cursor-pointer" id="button{{user.category_id}}"
              [ngClass]="{'btn-selected': user.select}" *ngFor="let user of liveCategoryList; index as i"
              (click)="onCategoryChange(user.category_id,true)">

              <div class="btn text-white d-flex">
                <span class="search-title cursor-pointer">{{ user.category_name }}</span>
                <span class="ml-auto" *ngIf="user.channelCount && user.select"> {{ user.channelCount }}</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div> -->


    <div class="col-lg-3 col-xl-3 col-md-3 col-12 d-none d-lg-block ">
      <div class="row mt-5 ml-1">
        <div class="col-12">
          <div class="border border-white row">
            <div class="col-12 pt-2 text-center d-flex-center pb-2">
              <p class="sub-text mb-0">{{'NAV.NAV7' | translate}}</p>
            </div>

            <div class="col-12">
              <div class="category-box cat-btn">
                <div class="p-2 over cursor-pointer" id="button{{user.category_id}}"
                  [ngClass]="{'btn-selected': user.select}" *ngFor="let user of liveCategoryList; index as i"
                  (click)="onCategoryChange(user.category_id,true)">
                  <div class="btn text-white d-flex">
                    <span class="search-title cursor-pointer pl-2 pr-0 py-3" style="min-width:30px">{{ user.category_id
                      }}</span>
                    <span class=" pr-0 pl-2 py-2 catImgResponsive">
                      <img [src]="user.stream_icon" width="50px" height="40px" class="mb-0 object-fit-cover"
                        loading=lazy>
                    </span>
                    <span class="btn text-white d-flex p-0 py-3 catNameResponsive">{{ user.category_name | titlecase
                      }}</span>
                    <!-- <span class="ml-auto" *ngIf="user.channelCount && user.select"> {{ user.channelCount }}</span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-xl-4 col-md-4 col-12 d-none d-lg-block">
      <div class="row mt-5 ml-1">
        <div class="col-12">
          <div class="border border-white row">
            <div class="col-12 d-flex-center">
              <p class="sub-text mb-0 py-2">{{ 'NAV.CHANNEL' | translate }}</p>
              <!-- <div class="spinner-border spinner-border-sm mb-0 ml-3 " role="status" *ngIf="isChannelLoading">
                <span class="sr-only">{{ 'COMMON.LOADING' | translate }}</span>
              </div> -->
            </div>
            <div class="col-12 channelbox" id="channel-box" #channelbox>
              <!-- <div class="card mb-3 d-flex" *ngFor="let channel of channels; index as i">
                <div class="row d-flex">

                  <div class="col-md-2 col-lg-2 d-flex">
                  <span class="pl-2 pr-0 py-3">{{channel.stream_id}}</span>

                  </div>
                  <div class="col-md-2 col-lg-2 d-flex">
                    <span class="pl-2 py-3 pr-0">
                      <img [src]="channel.stream_icon" width="70px" height="40px" class="mb-0 object-fit-cover"
                        loading=lazy>
                    </span>
                  </div>
                  <div class="col-md-4 col-lg-4 d-flex">
                    <span class="p-2 py-3 search-title cursor-pointer" title="{{channel.name}}">
                      {{channel.name}}
  
                    </span>
                  </div>
                  <div class="col-md-2  d-flex">
                    <span class="fav-icon icon-color" (click)="onSave(channel,$event)" *ngIf="!channel.isFavourit"> <i
                      class="far fa-heart  cursor-pointer"></i>
                  </span>

                  <span class="fav-icon icon-color"> <i class="fas fa-heart  cursor-pointer" *ngIf="channel.isFavourit"
                      (click)="deleteFavourite(channel,$event)"></i>
                  </span>
                  </div>
                </div>
              </div> -->

              <div class="over1 mb-3 mx-auto" [ngClass]="{'btn-selected': channel.select}" style="position: relative;"
                *ngFor="let channel of channels | slice: 0:500 let i=index;" (click)="updateChannel(channel.stream_id)">
                <div class="d-flex  mb-3" (click)="updateChannelEpg(channel.stream_id,channel.name);"
                  id="channel{{ channel.stream_id }}">
                  <span class="pl-2 pr-0 py-3 channel_id">{{channel.stream_id}}</span>
                  <span class="pl-2 py-2 pr-0">
                    <img [src]="channel.stream_icon" width="70px" height="40px" class="mb-0 object-fit-cover"
                      loading=lazy>
                  </span>
                  <span class="p-2 py-3 reschan search-title search-title cursor-pointer" title="{{channel.name}}">
                    {{channel.name}}
                  </span>
                  <span class="fav-icon icon-color" (click)="onSave(channel,$event)" *ngIf="!channel.isFavourit"> <i
                      class="far fa-heart  cursor-pointer"></i>
                  </span>
                  <span class="fav-icon icon-color"> <i class="fas fa-heart  cursor-pointer" *ngIf="channel.isFavourit"
                      (click)="deleteFavourite(channel,$event)"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- d-none d-lg-block -->
    <div class="col-lg-5 col-xl-5 col-md-4 col-12">
      <div class="row mt-5">
        <div class="col-12 pt-0">
          <!-- <div id="myElementLive"></div> -->
          <!-- <video controls #videoPlayer autoplay
          preload="none" controls preload="auto" >
            <source src="{{ videoSource }}" type="video/mp4" />
          </video> -->
          <!-- class="video-js  player-video  vjs-fluid vjs-live-control vjs-big-play-centered  vjs-fluid veoplayer-dimensions vjs-controls-enabled vjs-touch-enabled vjs-workinghover vjs-v8 vjs-touch-inactive vjs-playing vjs-has-started  vjs-920 vjs-1080 vjs-live  " autoplay
          preload="none" preload="auto" -->
          <video id="video" controls class="" width="900" height="auto" style="max-width: 100%" >
            <source src="{{url}}" type="application/x-mpegURL"  />
          </video>
          <!-- <button class="btn btn-primary " (click)="openVlc(url)">Open VLC</button> -->
          
          <!-- <button class="btn btn-primary ml-1" (click)="copyUrl(url)">Open in VLC <small><i class="fa fa-copy"></i></small> Copy URL</button> -->
          <!-- <button (click)="downloadRegistryFile()">Download Registry File</button> -->
          <!-- <video id="example-video" (timeupdate)="onTimeUpdate()" class="video-js vjs-default-skin m-3" autoplay
            preload="none" controls preload="auto" width="900" height="400" data-setup='{}'>
            <source src="{{initial_Url}}" type="application/x-mpegURL">
          </video> -->
        </div>
        <div class="col-12 ml-2 d-none d-lg-block">
          <div *ngFor="let mjm of displayEpg; index as i" class="epg-box my-3 p-2 ml-2">
            <div class="d-flex">
              <h5 class="mb-0">{{mjm.title}}</h5>
              <div class="ml-2"><span class="text-purple">{{mjm.start }}</span> <span class="ml-1 text-purple"> -
                  {{ mjm.end }}</span>
              </div>
            </div>
            <div>
              <p class="fs-14 ml-1 mb-0 mt-1 text-muted">{{mjm.desc}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-lg-none d-block category lower">
    <div class="col-12 text-center">
      <div class="row mt-3">
        <div class="col-12 text-center" (click)="isCategory=!isCategory">
          <p class="sub-text border p-2">{{ 'COMMON.CATEGORIES' | translate }}
            <i class="fa fa-chevron-down ml-2"></i>
          </p>
        </div>
        <div *ngIf="isCategory" class="col-12">
          <div class="category-box">
            <div class=" p-2 over" [ngClass]="{'btn-selected': user.select}"
              *ngFor="let user of liveCategoryList; index as i" (click)="onCategoryChange(user.category_id,true)">
              <span class="search-title cursor-pointer pl-2 pr-0 py-3 minWidth">{{ user.category_id}}</span>
              <span class=" pr-0 pl-2 py-2 catImgResponsive">
                <img [src]="user.stream_icon" width="70px" height="40px" class="mb-0 object-fit-cover" loading=lazy>
              </span>
              <span class="">{{ user.category_name | titlecase}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row mt-3">
        <div class="col-12 text-center " style="position: relative;">
          <div class="chanel border border-white">
            <p class="sub-text my-auto py-2" (click)="isChannels=!isChannels">{{ 'COMMON.CHANNELS' | translate }}
              <i class="fa fa-chevron-down ml-2"></i>
            </p>
            <div class="col-12 channelbox" *ngIf="isChannels">
              <div class="over1 mb-3 mx-auto" [ngClass]="{'btn-selected': channel.select}" style="position: relative;"
                *ngFor="let channel of channels; index as i" (click)="updateChannel(channel.stream_id)">
                <div class="d-flex  mb-3" (click)="updateChannelEpg(channel.stream_id,channel.name);"
                  id="channel{{ channel.stream_id }}">
                  <span class="pl-2 pr-0 py-3 channel_id">{{channel.stream_id}}</span>
                  <span class="pl-2 py-2 pr-0">
                    <img [src]="channel.stream_icon" width="70px" height="40px" class="mb-0 object-fit-cover"
                      loading=lazy>
                  </span>
                  <span class="p-0 py-3 reschan search-title search-title cursor-pointer" title="{{channel.name}}">
                    {{channel.name}}
                  </span>
                  <span class="fav-icon icon-color" (click)="onSave(channel,$event)" *ngIf="!channel.isFavourit"> <i
                      class="far fa-heart  cursor-pointer"></i>
                  </span>
                  <span class="fav-icon icon-color"> <i class="fas fa-heart text-danger cursor-pointer" *ngIf="channel.isFavourit"
                      (click)="deleteFavourite(channel,$event)"></i>
                  </span>
                </div>
              </div>
              <!-- <div class="over1 mb-3 mx-auto" [ngClass]="{'btn-selected': channel.select}"
                *ngFor="let channel of channels; index as i" (click)="updateChannel(channel.stream_id)">
                <div class="d-flex  mb-3" (click)="updateChannelEpg(channel.stream_id,channel.name);">
                  <div class="p-2 py-3">{{channel.stream_id}}</div>
                  <div class="p-2">
                    <img [src]="channel.stream_icon" width="90px" height="40px" class="mb-0 object-fit-cover"
                      loading=lazy>
                  </div>
                  <div class="p-2 py-3  search-title cursor-pointer" title="{{channel.name}}">
                    {{channel.name}}
                  </div>
                  <div class="p-2 py-3 ">
                    <span class="fav-icon icon-color" (click)="onSave(channel,$event)" *ngIf="!channel.isFavourit"> <i
                      class="far fa-heart  cursor-pointer"></i>
                  </span>

                  <span class="fav-icon icon-color"> <i class="fas fa-heart  cursor-pointer" *ngIf="channel.isFavourit"
                      (click)="deleteFavourite(channel,$event)"></i>
                  </span>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row mt-3">
        <div class="col-12 p-0">
          <!-- <video id="example-video" (timeupdate)="onTimeUpdate()" class="video-js  m-2" autoplay
            preload="none" controls preload="auto" width="300" height="400" data-setup='{}' style="max-width: 100%;">
            <source src="{{url}}" type="application/x-mpegURL">
          </video> -->
          <!-- <button class="btn btn-primary ml-2" (click)="openVlc(url)">Open VLC</button> -->
        </div>
        <!-- <button class="btn btn-primary m-2" (click)="copyUrl(url)">Open in VLC  <small><i class="fa fa-copy"></i></small> Copy URL</button> -->
        <div class="col-12 ml-2">
          <!-- <div *ngFor="let mjm of displayEpg; index as i" class="epg-box my-3 p-2">
            <div class="d-flex">
              <h5 class="mb-0 epg-title">{{mjm.title}}</h5>
              <div class="ml-2"><span class="text-purple">{{mjm.start }}</span> <span class="ml-1 text-purple"> -
                  {{ mjm.end}}</span>
              </div>
            </div>
            <div>
              <p class="fs-14 ml-1 mb-0 mt-1 text-muted">{{mjm.desc}}</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div id="container">

  <button >
    <i [className]="videoPlaying ? 'fa fa-pause' : 'fa fa-play'" aria-hidden="true"></i>
  </button>
  <div class="progress-bar">
    <span [style.width]="percentage + '%'">

    </span>
  </div>

</div> -->


<!-- <div style="height: 540px;overflow-y: scroll;position: relative;top: -50px;">
  <h1 class="epgheader">{{channelname}}</h1>
  <div class="overepg mb-2 ml-3" *ngFor="let mjm of displayEpg; index as i" style="position: relative;top: -80px;">


    <div class="d-flex flex-column bd-highlight mb-2">
      <div class="bd-highlight">

        <div class="d-flex flex-row bd-highlight" style="position: relative;bottom: 6px;">
          <div class="bd-highlight epgtime">{{mjm.start }} </div>
          <div class="bd-highlight epgtime"> {{ mjm.end}}</div>
          <div class="bd-highlight show">{{mjm.title}}</div>


        </div>
        <div class="bd-highlight descriptionepg">{{mjm.desc}}</div>
      </div>
    </div>

  </div>
</div> -->



<!-- pragna end -->



<!-- <div id="container">

 <button (click)="this.togglevideo()">
     <i [className]="videoPlaying ? 'fa fa-pause' : 'fa fa-play'" aria-hidden="true"></i>
 </button>
<div class="progress-bar">
        <span [style.width]="percentage + '%'">

        </span>
      </div>

    </div>


 <div style="height: 540px;overflow-y: scroll;position: relative;top: -50px;">
      <h1 class="epgheader">{{channelname}}</h1>
      <div class="overepg mb-2 ml-3" *ngFor="let mjm of epgdata; index as i" style="position: relative;top: -80px;">


        <div class="d-flex flex-column bd-highlight mb-2">
          <div class="bd-highlight">

            <div class="d-flex flex-row bd-highlight" style="position: relative;bottom: 6px;">
              <div class="bd-highlight epgtime">{{mjm.rony2 }} </div>
              <div class="bd-highlight epgtime"> {{ mjm.rony3}}</div>
              <div class="bd-highlight show">{{mjm.rony1}}</div>
            </div>
            <div class="bd-highlight descriptionepg">{{mjm.rony4}}</div>
          </div>
        </div>
      </div>
    </div> 

</div>
</div>  -->
<div class="container-fluid">
  <div class="row">
    <div *ngIf="(liveCategoryList?.length<1) && (channels?.length<1)" class="col-12 empty-div d-flex-center ">
      <h5 class="pt-4">{{ 'COMMON.DATA_NOT_FOUND' | translate }} </h5>
      <p></p>
    </div>
  </div>
</div>