<div class="container-fluid">
  <div class="row my-3 mx-2 " *ngIf="seasonId">
    <div class="col-12 px-0">
      <div class="form-group has-search  mr-2">
        <span class="fa fa-search form-control-feedback"></span>
        <input type="text" class="form-control" placeholder="{{ 'COMMON.SEARCH_HERE' | translate }}"
          [(ngModel)]="filterTerm">
        <button type="button" class="btn bg-transparent searchclear" *ngIf="filterTerm" (click)="filterTerm=''">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row mx-md-3 mx-0">
    <div class="col-md-12 d-flex mt-2">
      <div class="cursor-pointer fs-16">
        <a style="color: white;display: flex;" (click)="back(categoryId)"> 
          <i class="fas fa-arrow-alt-left pr-1" style="display: flex;align-items: center;"></i> {{ 'COMMON.BACK' | translate }}
        </a>
      </div>
      <div class="ml-auto mr-auto">
        <h3 class="text-center series-header">{{seriesinfo?.info.name}}</h3>
      </div>
      <div class="spinner-border  mt-2 mr-2 " role="status" *ngIf="isLoading">
        <span class="sr-only">{{ 'COMMON.LOADING' | translate }}</span>
      </div>
    </div>
    <div class="col-md-12 mb-0 pb-0">
      <h3 class="text-left mb-0 pb-0  series-header" *ngIf="seriesinfo">{{ 'SERIES.SEASONS' | translate }}</h3>
    </div>
    <div class="col-lg-3  col-xl-2 col-6 text-left " *ngFor="let select of seriesinfo?.seasons; index as i">
      <div class="imageContainer-search cursor-pointer" (click)="onSelectSeason(select.season_number)">
        <div [ngClass]="{'border-all':select.select}">
          <img [src]="select.cover" class="search-img" loading=lazy>
        </div>
      </div>
      <h6 class="mt-2">{{ 'SERIES.SEASON' | translate }} {{select.season_number}}</h6>
    </div>

  </div>
  <div class="row mx-md-3 mx-0 my-3  border-top" *ngIf="seasonId && seriesinfo?.episodes">
    <div class="col-md-12 mt-3">
      <h3 class="text-center series-header">{{ 'SERIES.SEASONS' | translate }} {{seasonId}}</h3>
    </div>
    <div class="col-md-12 mb-0 pb-0">
      <h3 class="text-left mb-0 pb-0 series-header">{{ 'SERIES.EPISODES' | translate }}</h3>
    </div>
    <div class="col-lg-4  col-xl-2 col-6 text-left "
      *ngFor="let select of seriesinfo?.episodes[seasonId]|filter:filterTerm; index as i">
      <div class="mb-4 imageContainer-search">
        <div>
          <img [src]="select.info.movie_image" class="search-img">
        </div>


        <div class="d-flex mt-2">
          <h6 class=" search-title mb-1" title="{{select.title }}">{{select.title}}</h6>
          <a class="ml-auto" [routerLink]="'/series/watch/' + select.id + '/' + select.container_extension"
            target="_blank"><i class="fas fa-play-circle fs-18 text-purple ml-auto cursor-pointer"></i></a>
        </div>

        <h6 class="text-purple episode-title" *ngIf="select.dur"><span
            class="mr-1">{{ 'SERIES.DURATION' | translate }}:</span><span
            *ngIf="select.dur?.hour>0">{{select.dur?.hour}}
            H</span> <span>{{select.dur?.minute}}m</span> </h6>

      </div>
    </div>
  </div>
</div>
