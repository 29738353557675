<div class="modal-header">
  <h4 class="modal-title pull-left title-color">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <span [innerHTML]="message" class="title-color"></span>
</div>
<div class="modal-footer">
  <ng-container *ngIf="options.length">
    <button *ngFor="let option of options;let last=last" type="button" class="btn btn-default"
      [ngClass]="{'btn-danger': !last, 'btn-dark': last}" (click)="respond(option)">{{option}}</button>
  </ng-container>
</div>
