import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../Shared/services/auth.service';
import { Client } from '../../../models/Client';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from 'src/app/Core/services/util.service';
import { SettingService } from 'src/app/Core/services/setting.service';

@Component({
  selector: 'app-useraccount',
  templateUrl: './useraccount.component.html',
  styleUrls: ['./useraccount.component.css']
})
export class UseraccountComponent implements OnInit {
  time: number;
  time1: number;
  mjm: string;
  mjm1: string;


  constructor(private api: AuthService, private settingService: SettingService,
    private router: Router, private toastr: ToastrService,
    private utilService: UtilService
  ) {
  }
  authuser: any;
  ngOnInit(): void {
    this.getUserInfo();

    // this.api.get().subscribe(res => {
    //   this.authuser = res;
    //   this.time = this.authuser.user_info.created_at;
    //   this.time1 = this.authuser.user_info.exp_date;
    //   const unixTime = this.time;
    //   const date = new Date(unixTime * 1000);
    //   const string = date.toLocaleDateString("en-US");
    //   this.mjm = string;


    //   const unixTime1 = this.time1;
    //   const date1 = new Date(unixTime1 * 1000);
    //   const string1 = date1.toLocaleDateString("en-US");
    //   this.mjm1 = string1;

    // });
  }
  getUserInfo() {
    this.utilService.showSpinner()
    this.settingService.getLoginUserData().subscribe((res: any) => {
    this.utilService.hideSpinner()
      console.log(res);
      this.authuser = res;
      this.time = this.authuser.user_info.created_at;
      this.time1 = this.authuser.user_info.exp_date;
      console.log(this.time1,'Exp time');
      
      const unixTime = this.time;
      const date = new Date(unixTime * 1000);
      const string = date.toLocaleDateString("en-US");
      this.mjm = string;


      if(this.time1 === null ){
        this.mjm1 = 'N/A'
      }else{
        const unixTime1 = this.time1;
        const date1 = new Date(unixTime1 * 1000);
        const string1 = date1.toLocaleDateString("en-US");
        this.mjm1 = string1;
      }

    })
  }
  logout() {
    this.utilService.logout();
  }
}
