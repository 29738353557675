import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';

import { Client } from '../../models/Client';
import { Router } from '@angular/router';

import { AuthentictionService } from '../../services/authentiction.service';
import { UtilService } from '../../services/util.service';
import { Constant } from '../../services/data';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild('ngOtpInput', { static: false }) ngOtpInputRef: any | undefined;

  public isLoading: boolean = false;
  public show: boolean = false;
  public user: Client = {
    username: '',
    password: '',
    otp: '',
    username1: ''
  };
  public config: any = {
    length: 4, allowNumbersOnly: true, disableAutoFocus: true, isPasswordInput: true, inputStyles: {
      'margin': '0px 8px', 'background': '#000000',
      'color': 'white', 'font-size': '20px', 'outline': 'none'
    }
  };
  type='password'
  constructor(
    private router: Router, private authService: AuthentictionService, private utilService: UtilService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  toggleOtp() {    
    this.ngOtpInputRef.config.isPasswordInput = this.show;
    (this.show) ? (this.ngOtpInputRef.inputType = 'tel') : (this.ngOtpInputRef.inputType = 'password'); 
    // this.cdRef.detectChanges();
  }

  onSubmit(form: NgForm) {
    let param: any = {
      userid: this.user.username,
      pwd: this.user.otp
    };

    if (form.valid && this.user.otp.length > 3) {
      this.isLoading = true;
      this.authService.register(param).subscribe((res: any) => {
        this.isLoading = false;
        if (res.http_code == 200)//userid
        {
          this.utilService.showErrorSuccess('', "Register Succesfully.");
          this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, res.userid);
          this.router.navigate(['playlist']);
        }
        if (res.error) {
          this.utilService.showErrorInfo(res.status);
        }
      },(err) =>{
        this.isLoading = false
        if(err && err.error && err.error.detail && err.error.detail.message){
          this.utilService.showErrorToast(err.error.detail.message);
  
        }
        else{
          this.utilService.showErrorToast("Something went wrong!")
        }
  
      })
  }}

  otpChange(value: any) {
    this.user.otp = value;
  }

  gotoCode() {
    let xtoken = localStorage.getItem('Xtoken');

    if (xtoken) {
      this.router.navigate(['/server-login']);

    } else {
      this.router.navigate(['/code']);
    }
  }
  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }
  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  }
  hideShowPassword(){
    if(this.type === 'password'){
      this.type='text'
    }
    else{
      this.type='password'
    }
  }
}
