import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { PlaylistService } from 'src/app/Core/services/playlist.service';
import { UtilService } from 'src/app/Core/services/util.service';
import * as $ from 'jquery';
import { Constant } from 'src/app/Core/services/data';
import { MessageService } from 'src/app/Core/services/message.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-watch-list',
  templateUrl: './watch-list.component.html',
  styleUrls: ['./watch-list.component.css']
})
export class WatchListComponent implements OnInit {
  playlistM3u: any = [];
  favPlayList: any = [];
  url: any;
  date: any;
  time: any;
  filterTerm: string;
  filterTermfav: string;
  isDesc: boolean = false;
  isAll = true;
  constructor(private translate:TranslateService,private route: ActivatedRoute, private playlistService: PlaylistService, private utilService: UtilService, private messageService: MessageService, private cdRef: ChangeDetectorRef, private router: Router,) {
    this.route.queryParams.subscribe((param: any) => {
      if (param) {
        this.url = decodeURIComponent(param.url);
        if (this.url) {
          this.getM3uListParse(this.url);
          // this.getFavouriteList();
        }
      }
    });
  }

  ngOnInit(): void {
    let today = new Date();
    this.date = moment().format("DD-MM-YYYY");
    this.time = moment().format('HH:mm')
    let intervalId;
    intervalId = setInterval(() => {
      this.time = moment().format('HH:mm:ss');
    });
  }

  ngAfterViewChecked(): void {
    let index, savedArray;
    savedArray = JSON.parse(localStorage.getItem(Constant.PLAYLIST_FAV)) || [];


    savedArray.map((ele: any) => {
      index = _.findIndex(this.playlistM3u, { title: ele.title });
      if (index >= 0) {
        this.playlistM3u[index].isFavourit = true;

      }
    });
    this.cdRef.detectChanges();

  }
  favIconManage() {

  }
  getM3uListParse(url: any) {
    this.playlistService.parsem3uList(url).subscribe((res: any) => {
      this.parse(res);

    }, err => {
      this.utilService.showErrorCall(err);
    });
  }

  parse(res: any) {
    this.playlistM3u = [];
    let str = res.replace(/\n+/g, '\n');
    str = str.split('\n');
    str.splice(0, 1);
    let obj = {
      title: '', url: ''
    };

    str = _.filter(str, (dt: any) => {
      return !(dt.indexOf('EXTVLCOPT') > -1)
    });


    for (let index = 0; index < str.length; index++) {
      const ele = str[index];
      if (ele.indexOf('#EXTINF') != -1) {
        obj.title = ele.split(',')[1];
        continue;
      }
      obj.url = ele;
      obj.title = obj.title.replace('\r', '');
      obj.url = obj.url.replace('\r', '');
      if (obj.title && obj.url) {
        this.playlistM3u.push({ 'title': obj.title, 'url': obj.url });
      }

    }
    console.log(this.playlistM3u);




  }

  onSave(item: any) {
    let savedArray = [];
    let isExist: any;
    let index;
    const msg =this.translate.instant('COMMON.ADDED_SUCCESS_MSG')

    savedArray = JSON.parse(localStorage.getItem(Constant.PLAYLIST_FAV)) || [];
    if (savedArray.length > 0) {
      isExist = _.findIndex(savedArray, { title: item.title });
      if (isExist == -1) {
        // item.isList = true;
        // item.id = item.stream_id;
        savedArray.push(item);
        localStorage.setItem(Constant.PLAYLIST_FAV, JSON.stringify(savedArray));
        index = _.findIndex(this.playlistM3u, { title: item.title });
        if (index >= 0) {
          this.playlistM3u[index].isFavourit = true;
        }

        this.utilService.showErrorSuccess(`${msg}`);
      }
      if (isExist >= 0) {
        this.utilService.showErrorInfo('Item alreadey added to favourite')
      }

    }
    else { //first
      // item.isList = true;
      // item.id = item.stream_id;
      savedArray.push(item);
      localStorage.setItem(Constant.PLAYLIST_FAV, JSON.stringify(savedArray));
      this.utilService.showErrorSuccess(`${msg}`);

    }
  }

  deleteFavourite(item: any) {
    let savedArray = [];
    const msg = this.translate.instant('COMMON.REMOVE_SUCCESS_MSG')

    savedArray = JSON.parse(localStorage.getItem(Constant.PLAYLIST_FAV)) || [];
    if (savedArray.length > 0) {
      this.messageService.confirm('Delete!', 'Are you sure you want to remove from favourite?')
        .subscribe((answer) => {
          if (answer === 'Yes') {
            let a = [];
            let deledIndex: any;
            savedArray.map((ele: any, index) => {
              if (ele.title == item.title) {
                deledIndex = index;
              }
              else {
                a.push(ele);
              }
            });

            if (a.length > 0 || deledIndex == 0) {
              localStorage.setItem(Constant.PLAYLIST_FAV, JSON.stringify(a));
              this.utilService.showErrorSuccess(`${msg}`);
              let index = _.findIndex(this.playlistM3u, { title: item.title });
              if (index >= 0) {
                this.playlistM3u[index].isFavourit = false;
              }
            }
          }
        });
    }
  }
  deletefavList(item: any) {
    const msg = this.translate.instant('COMMON.REMOVE_SUCCESS_MSG')

    this.messageService.confirm('Delete!', 'Are you sure you want to remove from favourite?')
      .subscribe((answer) => {
        if (answer === 'Yes') {
          let a = [];
          let deledIndex: any;

          this.favPlayList.map((ele: any, index) => {
            if (ele.title == item.title) {
              deledIndex = index;
            }
            else {
              a.push(ele);
            }
          });

          if (a.length > 0 || deledIndex == 0) {
            this.favPlayList.splice(deledIndex, 1);
            localStorage.setItem(Constant.PLAYLIST_FAV, JSON.stringify(a));

            this.playlistM3u.map((elee: any, i) => {
              if (elee.title == item.title) {
                elee.isFavourit = false;
              }
            });

            this.utilService.showErrorSuccess(`${msg}`);
          }
          this.cdRef.detectChanges();
        }
      })
  }

  // playWatchList(urlid: any) {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['/watchlist/watch/', urlid])
  //   );
  //   window.open(url, '_blank');
  // }
  getFavouriteList() {
    this.favPlayList = JSON.parse(localStorage.getItem(Constant.PLAYLIST_FAV)) || [];
    console.log(this.favPlayList);

  }

  sort(property) {
    let list: any;
    this.isDesc = !this.isDesc; //change the direction  
    (this.isAll) ? list = this.playlistM3u : (list = this.favPlayList)
    this.utilService.commonSort(this.isDesc, property, list);
  }
}
