<div class="container-fluid movieInfo my-2">
  <div
    [ngStyle]="{
      background: 'url(' + movieinfo?.info.movie_image + ') no-repeat '
    }"
    class="row mx-0 m-lg-1 movieinfoBackground"
  >
    <!-- linear-gradient(to right, #000000 15%, #262017 25%), -->

    <div class="cursor-pointer fs-16 m-2" style="z-index: 20">
      <a style="color: white" (click)="back(categoryId)">
        <i class="fas fa-arrow-alt-left"></i> {{ "COMMON.BACK" | translate }}
      </a>
    </div>

    <div class="text-center col-12 m-auto" *ngIf="isLoading">
      <div class="spinner-border" role="status">
        <span class="sr-only fs-35">{{ "COMMON.LOADING" | translate }}</span>
      </div>
    </div>

    <div class="col-md-12 mt-0" *ngIf="movieinfo">
      <div class="row mt-0 ml-0 mr-0">
        <!-- <div class="col-xl-5   img-contain mx-auto">
          <img [src]="movieinfo.info.movie_image" class="info-img" alt="No image" loading=lazy>
          <div class="fav-icon icon-color" *ngIf="isFavourit"> <i class="fas fa-heart  cursor-pointer"
              ></i></div>
        </div> -->
        <div class="col-xl-7 mt-3">
          <div class="row">
            <div class="col-12 text-left">
              <div class="bd-highlight infotitle">
                {{ movieinfo.info.name }}
              </div>
              <div class="d-none d-lg-block">
                <div class="d-flex flex-row bd-highlight mb-2">
                  <div class="category" *ngIf="movieinfo.info.dur">
                    <span class="mr-1">{{ movieinfo.info.dur.hour }}h</span>
                    <span>{{ movieinfo.info.dur.minute }}m</span>
                    <span class="mx-2 text-purple">|</span>
                  </div>

                  <div class="bd-highlight category">
                    {{ movieinfo.info.releasedate }}
                    <span class="mx-2 text-purple">|</span>
                  </div>
                  <div
                    type=""
                    class="mr-lg-4 d-flex fav-center"
                    *ngIf="isFavourit"
                    (click)="deleteFavourite(movieinfo)"
                  >
                    <i class="fa fa-trash px-3 px-lg-2 py-1"></i
                    >{{ "COMMON.REMOVE_FROM_FAVOURITE" | translate }}
                  </div>
                  <div class="d-flex">
                    <div
                      type=""
                      class="mr-lg-4 d-flex fav-center"
                      *ngIf="!isFavourit"
                      (click)="onSave(movieinfo)"
                    >
                      <i class="fa fa-heart px-3 px-lg-2 py-1"></i
                      >{{ "COMMON.ADD_TO_FAVOURITE" | translate }}
                    </div>
                  </div>

                  <!-- <div class="bd-highlight category">{{movieinfo.info.genre}} <span class="mx-2 text-purple">|</span> -->
                  <!-- </div> -->
                </div>
              </div>
              <div class="d-lg-none d-block">
                <div class="fs-14">{{ movieinfo.info.releasedate }}</div>
                <div class="fs-14">{{ movieinfo.info.genre }}</div>
                <div class="fs-14" *ngIf="movieinfo.info.dur">
                  <span class="mr-1">{{ movieinfo.info.dur.hour }}h</span>
                  <span>{{ movieinfo.info.dur.minute }}m</span>
                </div>
                <p
                  class="fs-16 text-light xs-res"
                  *ngIf="!isFavourit"
                  (click)="onSave(movieinfo)"
                >
                  <i class="fa fa-heart pl-0"></i>&nbsp; Add favourits
                </p>
                <p
                  class="fs-16 text-light xs-res"
                  *ngIf="isFavourit"
                  (click)="deleteFavourite(movieinfo)"
                >
                  <i class="fa fa-trash pl-0"></i> &nbsp; Remove favourits
                </p>
              </div>
              <div class="mb-lg-5 mb-3">
                <i
                  class="fa fa-star mx-1"
                  [ngClass]="{
                    'text-purple': movieinfo.info.rating / 2 >= i + 1
                  }"
                  aria-hidden="true"
                  *ngFor="let item of [1, 2, 3, 4, 5]; let i = index"
                ></i>
              </div>
              <div class="mt-1 mt-lg-3 content-text">
                <table class="w-100 row-space">
                  <tr>
                    <td width="11%" class="text-purple fs-15">
                      {{ "MOVIE.DIRECTED_BY" | translate }}
                    </td>
                    <td class="pl-2">
                      <span class="mx-2 text-purple">:</span>
                      <span class="fs-15">
                        {{
                          movieinfo.info.director
                            ? movieinfo.info.director
                            : "N/A"
                        }}</span
                      >
                    </td>
                  </tr>
                  <tr class="pb-2">
                    <td width="10%" class="text-purple fs-15">
                      {{ "MOVIE.ACTORS" | translate }}
                    </td>
                    <td>
                      <span class="mx-2 text-purple">:</span
                      ><span class="fs-15">{{ movieinfo.info.actors }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td width="10%" class="text-purple fs-15">
                      {{ "MOVIE.CAST" | translate }}
                    </td>
                    <td>
                      <span class="mx-2 text-purple">:</span
                      ><span class="fs-15">{{ movieinfo.info.cast }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td width="10%" class="text-purple fs-15">
                      {{ "MOVIE.COUNTRY" | translate }}
                    </td>
                    <td>
                      <span class="mx-2 text-purple">:</span>
                      <span class="fs-15"> {{ movieinfo.info.country }} </span>
                    </td>
                  </tr>
                </table>
                <!-- <button type="button" class="btn button-info mr-lg-4 mr-2 mt-3" *ngIf="movieinfo.info.youtube_trailer"
                [routerLink]="['/movies/'+movieinfo.info.youtube_trailer]"><i
                  class="fa fa-play  px-3 px-lg-4 py-lg-1"></i>{{ 'COMMON.TRAILER' | translate }}</button> -->
              </div>
              <div class="bd-highlight category mt-4">
                {{ movieinfo.info.genre }}
              </div>
              <div class="d-flex flex-row bd-highlight mt-lg-5 mt-2 mb-2">
                <!-- <button type="button" class="btn button-info mr-lg-4 mr-2" *ngIf="movieinfo.info.youtube_trailer"
                  [routerLink]="['/movies/'+movieinfo.info.youtube_trailer]"><i
                    class="fa fa-play  px-3 px-lg-4 py-lg-1"></i>{{ 'COMMON.TRAILER' | translate }}</button> -->
                <!-- <button type="button" class="btn button-info mr-lg-4 mr-2"
                  ><a class="text-white" [routerLink]="'/movies/watch/' + movieinfo.movie_data.stream_id" target="_blank"><i
                    class="fa fa-play  px-lg-4 py-lg-1 px-3"></i>{{ 'COMMON.PLAY' | translate }}</a></button> -->
                <!-- <button type="button" class="btn button-info mr-lg-4" *ngIf="!isFavourit" (click)="onSave(movieinfo)"><i
                    class="fa fa-heart  px-3 px-lg-4 py-1"></i>{{ 'COMMON.ADD_TO_FAVOURITE' | translate }}</button>
                    <button type="button" class="btn button-info mr-lg-4" *ngIf="isFavourit" (click)="deleteFavourite(movieinfo)"><i
                      class="fa fa-trash  px-3 px-lg-4 py-1"></i>{{ 'COMMON.REMOVE_FROM_FAVOURITE' | translate }}</button>                    -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 img-contain mx-auto">
          <!-- <div class="btn play-btn">
            <button type="button" class="btn button-info mr-lg-4 mr-2"
            ><a class="text-white" [routerLink]="'/movies/watch/' + movieinfo.movie_data.stream_id" target="_blank"><i
              class="fa fa-play  px-lg-4 py-lg-1 px-3"></i>{{ 'COMMON.PLAY' | translate }}</a></button>
          </div> -->
          <div class="image-overlay">

            <img [src]="movieinfo.info.movie_image" class="info-img"  alt="No image" loading="lazy" />
          </div>

          <a
            [routerLink]="'/movies/watch/' + movieinfo.movie_data.stream_id"
            target="_blank"
          >
            <img
              src="/assets/images/mdi_play_circle_filled.png"
              alt=""
              class="play-movie-icon"
            />
          </a>

          <div class="fav-icon icon-color" *ngIf="isFavourit">
            <i class="fas fa-heart cursor-pointer like-heart"></i>
          </div>

          <div class="d-flex mt-1">
            <button type="button" class="btn button-info mr-lg-4 mr-2"  [routerLink]="'/movies/watch/' + movieinfo.movie_data.stream_id" target="_blank">
              <a
                class="text-white"
                [routerLink]="'/movies/watch/' + movieinfo.movie_data.stream_id"
                target="_blank"
                ><i class="fa fa-play px-lg-4 py-lg-1 px-3"></i
                >{{ "COMMON.PLAY" | translate }}</a
              >
            </button>

            <button
              type="button"
              class="btn button-info"
              
              *ngIf="movieinfo.info.youtube_trailer"
              [routerLink]="['/movies/' + movieinfo.info.youtube_trailer]"
            >
              <i class="fa fa-play px-3 px-lg-4 py-lg-1"></i
              >{{ "COMMON.TRAILER" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="row mt-5 info-plot">
        <div class="col-12 text-left">
          <p class="text-white content-text">{{ movieinfo.info.plot }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
