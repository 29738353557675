import { Directive, Input } from '@angular/core';

@Directive({
  selector: 'img',
  host: {
    '(error)': 'updateUrl()',
    '[src]': 'src',
  },
})
export class DefaultImageDirective {
  @Input() src: string;
  // @Input() imageType: string;
  // @Input() isFreshUser: any;
  ngOnInit(): void {
    // if (this.isFreshUser) {
    // this.src =
    //   this.imageType == 'company' ? 'assets/images/company-2.jpg' : 'assets/images/avatar.png';
    // }
  }
  updateUrl() {
    this.src = 'assets/images/no_poster.png';
  }
}
