import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent } from '../components/confirmation-popup/confirmation-popup.component';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class MessageService {
    bsModalRef: BsModalRef;
    Yes:any
    No:any
    constructor(
        private bsModalService: BsModalService,
        private translate: TranslateService
        ) { 
            this.translate.onLangChange.subscribe(() => {
                this.initializeOptions();
              });
          
              this.initializeOptions();
        }
        private initializeOptions() {
            this.translate.get('HOME.YES').subscribe(yesTranslation => {
              this.Yes = yesTranslation;
              
            });
        
            this.translate.get('HOME.NO').subscribe(noTranslation => {
              this.No = noTranslation;
            });
          }
        
        confirm(title: string, message: string, options: string[] = [this.Yes, this.No]): Observable<string> {

       
        const initialState = {
            title: title,
            message: message,
            options: options,
            answer: ''
        };

        this.bsModalRef = this.bsModalService.show(ConfirmDialogComponent, { initialState });
        return new Observable<string>(this.getConfirmSubscriber());
    }

    private getConfirmSubscriber() {
        return (observer) => {
            const subscription = this.bsModalService.onHidden.subscribe((reason: string) => {
                observer.next(this.bsModalRef.content.answer);
                observer.complete();
            });

            return {
                unsubscribe() {
                    subscription.unsubscribe();
                }
            };
        }
    }

}
