import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {
  label: any;
  auth: boolean = false;
  languages = [];
  translates = ["LANGUAGE.ENGLISH","LANGUAGE.FRENCH", "LANGUAGE.CHINESE","LANGUAGE.GERMAN","LANGUAGE.HINDI","LANGUAGE.HEBREW","LANGUAGE.ITALIAN","LANGUAGE.ARABICS","LANGUAGE.DUTCH","LANGUAGE.PORTUGUESE","LANGUAGE.ROMANIAN","LANGUAGE.TURKISH","LANGUAGE.SPANICH", "LANGUAGE.RUSSIAN"]
  constructor(public translate: TranslateService, private router: Router) {
    translate.getLangs().map(lang => {
      let label = lang;
      if (lang === `en-US`) {
        label = 'english';
      } else if (lang === `fr-FR`) {
        label = 'french';
      }
      else if (lang === `arabics`) {
        label = 'arbic';
      }
      this.languages.push({
        label: label.charAt(0).toUpperCase() + label.slice(1),
        value: lang
      });
    });

    this.router.events.subscribe((res: any) => {
      // console.log(res);

      if (res instanceof ActivationEnd) {
        if (res.snapshot) {
          if (res.snapshot.routeConfig && res.snapshot.routeConfig.path) {
            this.label = res.snapshot.routeConfig.path;
            if (this.label == 'language') {
              this.auth = true;
            }
          }
        }
      }
    });
  }

  ngOnInit(): void {
  }

  changeLanguage(event) {
    this.translate.use(event.target.value);
    localStorage.setItem('language', event.target.value);

    if (this.auth) {
      this.router.navigate(['/login']);
    }
    else {
      this.router.navigate(['/settings']);
    }

  }

  getTraslation(index:number):string{
    return this.translates[index]
  }
}
