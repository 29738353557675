import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MovieService } from 'src/app/Core/services/movie.service';
import { UtilService } from 'src/app/Core/services/util.service';
import * as moment from 'moment';
import { Constant } from 'src/app/Core/services/data';
import * as _ from 'lodash';
import { param } from 'jquery';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-movieinfo',
  templateUrl: './movieinfo.component.html',
  styleUrls: ['./movieinfo.component.css']
})
export class MovieinfoComponent implements OnInit {
  public isLoading: boolean = false;
  id: string;
  movieinfo: any;
  isFavourit: boolean = false;
  favMovie: any;
  JSONDatas = [];
  movieFrom: any;
  categoryId: any;

  constructor(private translate:TranslateService,private location: Location, private router: Router, private movieService: MovieService, private utilService: UtilService,
    private route: ActivatedRoute, private toastr: ToastrService) {
    this.route.queryParams.subscribe((param: any) => {
      this.movieFrom = param.movieBy;
      console.log(param);

      this.categoryId = param.categoryId;
    });
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getMovieInfo(this.id);

    if (this.id) {
      let isExist: any;
      this.favMovie = JSON.parse(localStorage.getItem(Constant.MOVIES_FAV)) || [];
      if (this.favMovie.length > 0) {
        isExist = _.findIndex(this.favMovie, { id: Number(this.id) });
        if (isExist >= 0) {
          this.isFavourit = true;
        }
      }
    }
  }
  getMovieInfo(id: any) {
    this.utilService.showSpinner()
    this.isLoading = true;
    this.movieService.getMovieDeatil(id).subscribe((res: any) => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      this.movieinfo = res;
      if (this.movieinfo) {
        let date = moment(this.movieinfo.info.duration, 'HH:mm:ss');
        const duration: any = {
          hour: 0,
          minute: 0
        };
        duration.hour = moment(date).hour();
        duration.minute = moment(date).minute();
        this.movieinfo.info.dur = duration;
      }
    }, err => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      this.utilService.showErrorCall(err);
    });

  }

  onSave(item: any) {
    let savedArray = [];
    let isExist: any;
    const msg =this.translate.instant('COMMON.ADDED_SUCCESS_MSG')
    savedArray = JSON.parse(localStorage.getItem(Constant.MOVIES_FAV)) || [];
    if (savedArray.length > 0) {
      isExist = _.findIndex(savedArray, { id: item.movie_data.stream_id });
      if (isExist == -1) {
        item.isList = false;
        savedArray.push({ id: item.movie_data.stream_id, img: item.info.movie_image, name: item.movie_data.name, fullUrl: localStorage.getItem(Constant.FULLURL_KEY) });
        localStorage.setItem(Constant.MOVIES_FAV, JSON.stringify(savedArray));
        this.utilService.showErrorSuccess(`${msg}`);
        this.isFavourit = true;
      }
      if (isExist >= 0) {
        this.utilService.showErrorInfo('Item alreadey added to favourite')
      }

    }
    else { //first
      item.isList = false;
      this.isFavourit = true;
      savedArray.push({ id: item.movie_data.stream_id, img: item.info.movie_image, name: item.movie_data.name, fullUrl: localStorage.getItem(Constant.FULLURL_KEY) });
      localStorage.setItem(Constant.MOVIES_FAV, JSON.stringify(savedArray));
      this.utilService.showErrorSuccess(`${msg}`);
    }
  }

  deleteFavourite(item: any) {
    item.id = item.movie_data.stream_id;
    const msg = this.translate.instant('COMMON.REMOVE_SUCCESS_MSG')

    this.utilService.deleteFavourit(Constant.MOVIES_FAV, item, (result: any) => {
      if (result) {
        this.utilService.showErrorSuccess(`${msg}`);
        this.isFavourit = false;
      }
    });
  }

  // playMovie(movieId) {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['/movies/watch/' + movieId])
  //   );
  //   window.open(url, '_blank');
  // }
  back(catId: any) {
    // this.location.back(); 

    if (this.movieFrom == 'search') {
      this.router.navigate(['search']);
    } else if (this.movieFrom == 'movies') {
      this.router.navigate(['/movies'], { queryParams: { category: catId } });
    } else if (this.movieFrom == 'favourites') {
      this.router.navigate(['/favourites']);
    } else {
      this.router.navigate(['/movies'], { queryParams: { category: catId } });
    }
  }
}
