import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.css'],
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  options: string[];
  answer: string = '';

  constructor(public bsModalRef: BsModalRef) {}

  respond(answer: string) {
    this.answer = answer;
    this.bsModalRef.hide();
  }
}
