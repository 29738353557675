import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { SeriesService } from 'src/app/Core/services/series.service';
import { UtilService } from 'src/app/Core/services/util.service';

@Component({
  selector: 'app-episodelist',
  templateUrl: './episodelist.component.html',
  styleUrls: ['./episodelist.component.css']
})
export class EpisodelistComponent implements OnInit {
  isLoading: any;
  idpath: string;
  seriesinfo: any;
  episodes: string;
  filterTerm: string;
  episodeCount: any;
  allepisodes: any = [];
  isSort: boolean = true;
  sortType: any;
  subscription: Subscription;
  mjm: any;
  seriesFrom: any;

  constructor(private router: Router,
    private route: ActivatedRoute, private utilService: UtilService, private seriesService: SeriesService) {
    this.route.queryParams.subscribe((params: any) => {
      this.seriesFrom = params.seriesBy;
    });
    this.subscription = this.utilService.getSorting().subscribe((res: any) => {
      this.sortType = res;
      if (this.sortType == 'Series') {
        this.sort();
      }
    });
  }

  ngOnInit(): void {
    this.episodes = 'Episodes';
    this.idpath = this.route.snapshot.params['id'];
    this.getSeriesInfo(this.idpath);
  }

  getSeriesInfo(id: any) {
    this.isLoading = true;
    this.utilService.showSpinner()
    
    this.seriesService.getSeriesDetail(id).subscribe((res: any) => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      this.seriesinfo = res;
      this.episodeCount = _.size(res.episodes);
      Object.keys(res.episodes).forEach(key => {
        res.episodes[key].forEach(element => {
          let date = moment(element.info.duration, 'HH:mm:ss');
          const duration: any = {
            hour: 0,
            minute: 0
          };

          duration.hour = moment(date).hour();
          duration.minute = moment(date).minute();
          element.dur = duration;
          this.allepisodes.push(element)

        });
      });

    }, err => {
      this.isLoading = false;
    this.utilService.hideSpinner()

      this.utilService.showErrorCall(err);
    });
  }

  sort() {
    this.isSort = !this.isSort;
    this.utilService.commonSort(this.isSort, 'title', this.allepisodes);
  }

  // playEpisode(id, ext) {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['/series/watch/' + id + '/' + ext])
  //   );
  //   window.open(url, '_blank');
  // }

  back() {
    this.router.navigate(['/series/info/' + this.idpath], { queryParams: { seriesBy: this.seriesFrom } });
  }

  ngOnDestroy() {
    if (this.sortType) {
      this.subscription.unsubscribe();
    }
  }
}
