import { Injectable ,Renderer2, RendererFactory2} from '@angular/core';
import { Subject,BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private renderer: Renderer2;
  public isDarkTheme: boolean = true;

  private _themeColor: string = 'blue';
  themeColorChanged = new Subject<string>();
  private selectedColorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public selectedColor$ = this.selectedColorSubject.asObservable();
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setSelectedColor(color: string): void {
    this.selectedColorSubject.next(color);
     // Store the color in localStorage
     localStorage.setItem('selectedColor', color);
  }
  getStoredColor(): string {
    return localStorage.getItem('selectedColor');
  }

  get themeColor(): string {
    return this._themeColor;
  }

  set themeColor(color: string) {
    this._themeColor = color;
    this.themeColorChanged.next(color);
  }

  // dark light 
  toggleTheme(): void {
    this.isDarkTheme = !this.isDarkTheme;

    if (this.isDarkTheme) {
      this.renderer.addClass(document.body, 'dark-theme');
      this.renderer.removeClass(document.body, 'light-theme');
    } else {
      this.renderer.addClass(document.body, 'light-theme');
      this.renderer.removeClass(document.body, 'dark-theme');
    }
    localStorage.setItem('theme', this.isDarkTheme ? 'dark' : 'light');
  }
  initializeTheme(): void {
    // Retrieve the theme state from localStorage
    const storedTheme = localStorage.getItem('theme');
  
    if (storedTheme === 'dark') {
      this.isDarkTheme = true;
      this.renderer.addClass(document.body, 'dark-theme');
    } else {
      this.isDarkTheme = false;
      this.renderer.addClass(document.body, 'light-theme');
    }
  }
  
}
