import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppInitilizeService {
    constructor(private http: HttpClient) { }

    getAppConfig() {
        // return this.http.get('https://endpoint.purpletv.app/purplemain/main.json').toPromise()
        return this.http.get('https://endpoint.purpletv.app/purpleweb/main.json').toPromise()
    }
}