import { Input, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MovieService } from 'src/app/Core/services/movie.service';
import { UtilService } from 'src/app/Core/services/util.service';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Constant } from 'src/app/Core/services/data';
import { MessageService } from 'src/app/Core/services/message.service';
import videojs from 'video.js';
import { TranslateService } from '@ngx-translate/core';
declare var Hls;
@Component({
  selector: 'app-movie-container',
  templateUrl: './movie-container.component.html',
  styleUrls: ['./movie-container.component.css']
})
export class MovieContainerComponent implements OnInit {
  @Input() selectedcategory: any;
  @Input() isLoading: any;

  movieList: any;
  defaultList: any;
  sortType: any;
  subscription: Subscription;
  isSort: boolean = true;

  filterTerm: string;
  JSONDatas = [];
  categoryId: any;
  stream_Url: any;
  filteredMovie:any =[]

  constructor(private router: Router, private movieService: MovieService, private utilService: UtilService, private messageService: MessageService,private translate:TranslateService) {
    this.subscription = this.utilService.sortingType.subscribe((res: any) => {
      this.sortType = res;
      if (this.sortType.type == 'Movies') {
        this.sortMovie('name', this.sortType.sub_category);
      }
    });

  }


  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.  
    if (this.selectedcategory) {
      this.movieList = this.selectedcategory;
      this.filteredMovie=this.movieList

      this.defaultList = this.selectedcategory;

      this.categoryId = this.selectedcategory[0]?.category_id;

      let index, savedArray;
      savedArray = JSON.parse(localStorage.getItem(Constant.MOVIES_FAV)) || [];
      savedArray.map((ele: any) => {
        index = _.findIndex(this.movieList, { stream_id: ele.id });
        if (index >= 0) {
          this.movieList[index].isFavourit = true;
        }
      });
    }
  }
  
 preventSpace(event){
  this.utilService.preventSpace(event)
 }

  playMovie(movieId) {
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree(['/movies/watch/' + movieId])
    // );
    // window.open(url, '_blank');
  }

  onSave(item: any) {
    let savedArray = [];
    let isExist: any;
    let index;
    const msg =this.translate.instant('COMMON.ADDED_SUCCESS_MSG')
    savedArray = JSON.parse(localStorage.getItem(Constant.MOVIES_FAV)) || [];
    if (savedArray.length > 0) {
      isExist = _.findIndex(savedArray, { id: item.stream_id });
      if (isExist == -1) {
        item.isList = true;
        item.id = item.stream_id;
        item.fullUrl = localStorage.getItem(Constant.FULLURL_KEY)
        savedArray.push(item);
        localStorage.setItem(Constant.MOVIES_FAV, JSON.stringify(savedArray));
        index = _.findIndex(this.movieList, { stream_id: item.stream_id });
        if (index >= 0) {
          this.movieList[index].isFavourit = true;
        }
        this.utilService.showErrorSuccess(`${msg}`);
      }
      if (isExist >= 0) {
        this.utilService.showErrorInfo('Item alreadey added to favourite')
      }

    }
    else { //first
      item.isList = true;
      item.id = item.stream_id;
      item.fullUrl = localStorage.getItem(Constant.FULLURL_KEY)
      savedArray.push(item);
      localStorage.setItem(Constant.MOVIES_FAV, JSON.stringify(savedArray));
      index = _.findIndex(this.movieList, { stream_id: item.stream_id });
      if (index >= 0) {
        this.movieList[index].isFavourit = true;
      }
      this.utilService.showErrorSuccess(`${msg}`);


    }
  }

  deleteFavourite(item: any) {
    item.id = item.stream_id;
    const msg = this.translate.instant('COMMON.REMOVE_SUCCESS_MSG')
    this.utilService.deleteFavourit(Constant.MOVIES_FAV, item, (result: any) => {
      if (result) {
        this.utilService.showErrorSuccess(`${msg}`);
        let index = _.findIndex(this.movieList, { stream_id: item.id });
        if (index >= 0) {
          this.movieList[index].isFavourit = false;
        }
      }
    });
  }

  sortMovie(property, subtype: any) {
    // this.isSort = !this.isSort;
    // this.utilService.commonSort(this.isSort, 'name', this.movieList);
    let ascDesc;
    if (subtype == 'default') {
      this.movieList = this.defaultList;
      this.filteredMovie=this.movieList

    } else {
      (subtype == 'atoz') ? ascDesc = 'asc' : ascDesc = 'desc';
    }

    if (ascDesc) {
      this.utilService.sortCommonCategorywise(property, ascDesc, this.movieList, (callback) => {
        this.movieList = callback;
        this.filteredMovie=this.movieList
      });
    }

  }
  gotoDetail(id) {

    let passModal: any = {
      type: 'Movies',
      sub_category: 'default'
    };
    this.utilService.addSorting(passModal);
    // [routerLink]="['/movies/info/'+select.stream_id]" [queryParams]="{movieBy: 'movies',categoryId:categoryId}"
    this.router.navigate(['/movies/info/' + id], { queryParams: { movieBy: 'movies', categoryId: this.categoryId } });
  }

  searchMovies(){
    this.filteredMovie = this.movieList.filter((item:any)=>item.name.toLowerCase().includes(this.filterTerm.toLowerCase()));
    console.log(this.filteredMovie);
  }
  clearSearch(){
    this.filterTerm =''
    this.filteredMovie = this.movieList

  }

  ngOnDestroy() {
    if (this.sortType) {
      this.subscription.unsubscribe();
    }
  }

}
