
<!-- 
<div class="liveContainer">
  <div class="container-fluid" style="background: #0D0D0D;"> -->
    <div  class="cursor-pointer " style="z-index: 999;position: absolute;">
      <div class="back">
        <i class="fas fa-arrow-alt-left" (click)="backToInfo()"><span class="fs-16 fw-500"> {{'COMMON.BACK' |translate}} </span> </i>
      </div>
    </div>
    <div class="mx-auto player-contain" *ngIf="stream_Url">
      <video id="example-videoplay" class="video-js player-video vjs-big-play-centered" autoplay controls preload="none" 
        data-setup="{}">
        <source [src]="stream_Url" type="video/mp4" />
        <p class="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a
          web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
        </p>
      </video>
    </div>
  <!-- </div>
</div> -->
