import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.css']
})
export class RefreshComponent implements OnInit {
  date: any;
  date1: any;
  date2: any;
  date2t: any;
  date3: any;
  date3t: any;
  date4: any;
  date4t: any;
  constructor() {

  }

  ngOnInit(): void {

    this.date = new Date();
    this.date1 = this.date;
    this.date2 = this.date.getHours();
    this.date2t = this.date2;
    this.date3 = this.date.getMinutes();
    this.date3t = this.date3;
    this.date4 = this.date.getSeconds();
    this.date4t = this.date4;
    console.log("Date = " + this.date4);
  }

}
