<div class="container-fluid auth auth-background">
  <div class="row pi-access justify-content-center align-items-center">
    <div class="col-12 text-start">
      <div class="header-w3l">
        <div class="logo mb-5">
          <h3 class="text-center"><a>{{ 'COMMON.TITLE' | translate }}</a></h3>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-3 col-12 ">
      <div class="mx-1 mb-5 access mr-md-5">
        <div class="box">
          <div class="codeheader mb-5 text-center">
            <h3 class="mb-5">{{ 'ACCESS.ACCCODE' | translate }}</h3>
          </div>
          <form #userForm="ngForm" (ngSubmit)=onSubmit(userForm) class="mb-5">
            <div class=" ">
              <div class="">
               <label for="" class="d-flex justify-content-start mt-3">Access Code</label>
                <div class="form-group">
                  <!-- <i class="fa fa-lock text-white " aria-hidden="true"></i>
                  <input type="text" [(ngModel)]="user.code" class="form-control  " name="userCode" #userCode="ngModel"
                    placeholder="{{ 'PLACEHOLDER.ENTCODE' | translate}}" required autocomplete="off"> -->
                  <i class="fa fa-lock text-white input-icon" aria-hidden="true"></i>
                  <input type="text" [(ngModel)]="user.code" class="form-control in" name="userCode" #userCode="ngModel"
                    placeholder="{{ 'PLACEHOLDER.ENTCODE' | translate }}" (keypress)="numAndCharOnly($event)" maxlength="64" required autofocus>
                </div>
                <div *ngIf="userForm.submitted && userCode.invalid" class="text-left pt-0">
                  <p class="error-msg mb-0" *ngIf="userCode.errors">{{ 'FORMERROR.ENTCODE' | translate }}</p>
                </div>
              </div>
            </div>
            <div class="row py-5 text-center">
              <div class="col-12">
                <button class="login-btn col-md-3" type="submit" [disabled]="isLoading">
                  <div *ngIf="isLoading" class="spinner-border spinner-border-sm fs-14"
                    role="status">
                    <span class="sr-only">{{ 'COMMON.LOADING' | translate }}</span>
                  </div>
                  {{ 'COMMON.SUBMIT' | translate }}
                </button>
              </div>
            </div>
            <div class="col-12 text-start text-center">
              <h5 class="mb-0 text-size"><a class="ml-1 text-white cursor-pointer"
                  routerLink="/login">Go to <span class="text-purple cursor-pointer"> Login </span></a>
              </h5>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>
