import { Component, OnInit, TemplateRef } from '@angular/core';
import { PlaylistService } from '../../services/playlist.service';
import { UtilService } from '../../services/util.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { MessageService } from '../../services/message.service';
import { Constant } from '../../services/data';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-play-list',
  templateUrl: './play-list.component.html',
  styleUrls: ['./play-list.component.css']
})
export class PlayListComponent implements OnInit {
  playListModal: any = {
    type: 'fromUrl',
    name: '',
    userName: '',
    password: '',
    url: ''
  };

  playList: any = [];
  xstreamList: any;
  isLoading: boolean = false;
  bsModalRef: BsModalRef;
  url_pattern = "([a-z0-9-]+\:\/+)([^\/\s]+)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#\r\n]*)#?([^ \#\r\n]*)";
  // XuserId: any;
  userid: any
  type='password'
  constructor(private utilService: UtilService, private playlistService: PlaylistService, private modalService: BsModalService, private messageService: MessageService, private router: Router) {
  }

  ngOnInit(): void {
    this.userid = this.utilService.getFullUserId();
    if (this.userid) {
      this.getPlayList();
    }
    else {
      this.router.navigate(['/home'])
    }
  }

  getPlayList() {
    this.playList = [];
    let passModel: any = {
      userid: ''
    };
    // this.XuserId = this.utilService.getFullUserId();
    // if (this.XuserId) {
    //   passModel.userid = this.XuserId;
    // } else {
    passModel.userid = this.utilService.getFullUserId();
    // }
    this.utilService.showSpinner()

    this.isLoading = true;
    this.playlistService.getPlayList(passModel).subscribe((res: any) => {
      this.isLoading = false;
      this.utilService.hideSpinner()

      if (res.http_code == 200) {
        if (res.m3u) {
          res.m3u.map((ele: any) => {
            if (ele._id) {
              ele.isM3u = true;
              this.playList.push(ele);
            }
          });
        }
        if (res.xstream) {
          res.xstream.map((ele: any) => {
            ele.isXstream = true;
            this.playList.push(ele);
          });

        }
        console.log(this.playList);
      }
    }, err => {
      this.isLoading = false;
      this.utilService.hideSpinner()

      this.utilService.showErrorCall(err);
    });

  }

  checkDuplicateName(name) {
    let found = false;
    for (let index = 0; index < this.playList.length; index++) {
      if (this.playList[index].name === name) {
        found = true;
        this.utilService.showErrorToast('', 'Playlist name must be unique');
        break;
      }
    }
    return !found;
  }

  addPlaylistM3u(form: NgForm) {
    if (form.valid && this.checkDuplicateName(this.playListModal.name)) {
      let passModel: any = {
        url: this.playListModal.url,
        name: this.playListModal.name,
        userid: this.utilService.getFullUserId()
      };
      this.isLoading = true;
      this.utilService.showSpinner()

      this.playlistService.addPlaylistM3u(passModel).subscribe((res: any) => {
        this.isLoading = false;
        this.utilService.hideSpinner()

        if (res.http_code == 200) {
          this.utilService.showErrorSuccess('m3u playlist is added successfully');
          this.getPlayList();
          this.playListModal.url = '';
          this.playListModal.name = '';
          this.closeModal();
        }
      }, err => {
        this.isLoading = false;
        this.utilService.hideSpinner()

        this.utilService.showErrorCall(err);
      });
    }
  }

  addPlaylistXstream(form: NgForm) {
    if (form.valid && this.checkDuplicateName(this.playListModal.name)) {
      let passModel: any = {
        url: this.playListModal.url,
        name: this.playListModal.name,
        userid: this.utilService.getFullUserId(),
        pwd: this.playListModal.password,
        username: this.playListModal.userName
      };

      this.isLoading = true;
      this.utilService.showSpinner()

      this.playlistService.addPlaylistXstream(passModel).subscribe((res: any) => {
        this.isLoading = false;
        this.utilService.hideSpinner()

        if (res.http_code == 200) {
          // , res.status
          this.utilService.showErrorSuccess('xstream playlist is added successfully');
          this.getPlayList();
          this.closeModal();
          this.playListModal = {
            type: 'fromUrl',
            name: '',
            userName: '',
            password: '',
            url: ''
          };
        }
      }, err => {
        this.isLoading = false;
        this.utilService.hideSpinner()

        this.utilService.showErrorCall(err);
      });
    }
  }

  deleteItem(url: any, type: any, ev: any) {
    ev.stopPropagation();
    ev.preventDefault();
    this.messageService.confirm('Delete!', 'Are you sure you want to delete?')
      .subscribe((answer) => {
        if (answer === 'Yes') {
          let passModel: any = {
            url: url,
            type: type,
            userid: this.utilService.getFullUserId()
          };
          this.isLoading = true;
          this.utilService.showSpinner()

          this.playlistService.deletePlayListItem(passModel).subscribe((res: any) => {
            this.isLoading = false;
            this.utilService.hideSpinner()

            if (res.http_code == 200) {
              this.utilService.showErrorSuccess('', res.status);
              this.getPlayList();
              // this.closeModal();
            }
            if(res.detail.http_code == 403){
              this.utilService.hideSpinner()
              this.utilService.showErrorToast(res.detail.status)
            }

          },(err) =>{
            this.isLoading = false
            if(err && err.error && err.error.detail && err.error.detail.status){
              this.utilService.showErrorToast(err.error.detail.status);
              this.utilService.hideSpinner()
            }
            else{
              this.utilService.showErrorToast("Something went wrong!")
            }
          });

        }
      });


  }

  closeModal() {
    this.bsModalRef.hide();
  }
  openModal(template: any) {
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }

  onLogout() {
    this.utilService.logout(true);
  }
  onSelectPlaylist(item: any) {
    let decodeUrl;
    this.utilService.removeXsreamStorage();
    this.utilService.removeFullModeStorage();
    this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, item.userid);
    this.utilService.saveValueStorage(Constant.FULLUSERNAME_KEY, item.username);
    this.utilService.saveValueStorage(Constant.FULLPASSWORD_KEY, item.pwd);
    this.utilService.saveValueStorage(Constant.FULLURL_KEY, environment.cdnurl + item.url);

    if (item.isXstream) {
      this.router.navigate(['/home']);
    } else {
      decodeUrl = encodeURIComponent(item.url);
      this.router.navigate(['/watchlist'], { queryParams: { url: decodeUrl } });
    }
  }

  hideShowPassword() {
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  

}