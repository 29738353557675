<div class="container-fluid">
<!-- <h4>{{'COMMON.SEARCH_RESULTS' | translate }}</h4> -->
  <div class="row" *ngIf="filteredSeriesDetailList.length==0">
    <div class="col-12 text-center mt-5">
      <h5>{{'COMMON.DATA_NOT_FOUND' | translate }}</h5>
    </div>
  </div>

  <div class="row mr-1" *ngIf="filteredSeriesDetailList.length!=0">
    <div class="col-12">
      <h4>{{'COMMON.SEARCH_RESULTS' | translate }}</h4>
    </div>

  </div>
  <div class="row">

    <!-- <div class="col-12 border-top border-bottom mb-2 ">
      <h5 class="text-center mt-3">{{ item.info.name }}</h5>
    </div> -->

    <div class="col-lg-2 col-md-3 col-12 text-left" *ngFor="let item of filteredSeriesDetailList">
      <div (click)="gotoDetail(item.info.series_id)">
        <div class="imageContainer-search">
          <img class="search-img cursor-pointer" [src]="item.info.cover" loading=lazy>
          <div class="fav-icon text-purple" *ngIf="item.info.isFavourit"> <i class="fas fa-heart  cursor-pointer"></i>
          </div>
        </div>
        <h6 class="mt-2 cursor-pointer">{{ item.info.name }}</h6>
      </div>
    </div>


    <!-- <div class="col-md-2 col-6  text-left "
      *ngFor="let select of item.seasons; index as i">
      <div [routerLink]="['/series/list/'+item.series_id]"  [queryParams]="{seriesBy: 'search',season:select.season_number}">
        <div class="imageContainer-search">
          <img [src]="select.cover" class="search-img cursor-pointer" loading=lazy
            [ngClass]="{'border-all':select.season_number ==seasonId}">
          <div class="fav-icon text-purple" *ngIf="select.isFavourit"> <i class="fas fa-heart  cursor-pointer"></i>
          </div>
        </div>
      </div>
      <h6 class="mt-2 cursor-pointer" [routerLink]="['/series/list/'+item.series_id]"  [queryParams]="{seriesBy: 'search',season:select.season_number}">{{ 'SERIES.SEASON' | translate }}
        {{select.season_number}} </h6>
    </div> -->




    <!-- <div class="col-12 border-top  mb-3" *ngIf="item?.episodes[seasonId]?.length>0">
      <div class="row mt-3">
        <div class="col-12 border-bottom">
          <h5 class="text-center ">Episodes</h5>
        </div>
        <div class="col-12 d-flex mb-1 mt-2" *ngIf="seasonId">
          <h5 class="mr-auto series-header">{{ 'SERIES.SEASON' | translate }} {{seasonId}}</h5>
          <a class="mr-2 text-purple" routerLink="/series/episodelist/{{ item.series_id }}">View All <i
              class="fas fa-angle-right"></i></a>
        </div>

      </div>
      <ngx-slick-carousel class="carousel custom-slider" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let item of item.episodes[seasonId];let i=index;" class="slide">
          <div class="pr-3">
          <img class="search-img cursor-pointer" [src]="item.info.movie_image" alt="First slide" loading=lazy
            [routerLink]="['/series/watch/'+item.id+'/'+item.container_extension]">
          <p class="fs-14 search-title" title="{{ item.title }}">{{item.title}}</p>
          </div>
        </div>
      </ngx-slick-carousel>

    </div> -->

  </div>
