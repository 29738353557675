<div class="container-fluid mt-4" >
    <div class="language-main">
      <div class="row">
        <div class="col-12 responsive-color">
          <h3 class="d-flex text-left header-label">
            {{ 'HOME.COLOR' | translate }}
          </h3>
          <!-- <select name="" id="" class="form-control" (change)="onChangeColor($event)" class="w-100">
            <option *ngFor="let color of colorTheme" [value]="color.color" class="text-capitalize">{{color.color}}</option>
          </select> -->
          <input type="color" [value]="color" class="form-control cursor-pointer" (input)="changeThemeColor($event.target.value)">
          <div class="mjm">
            <!-- <select (change)="changeColor($event.target.value)" [(ngModel)]="selectedColor" class="w-100">
              <option *ngFor="let item of colorTheme ;let i =index" [value]="item.color" class="form-control"  [selected]="selectedColor === item.color">
                {{getTranslation(i) | translate}}
              </option> -->
              <!-- <option value="red" [selected]="selectedColor === 'red'">Red</option>
                <option value="green" [selected]="selectedColor === 'green'">Green</option>
                <option value="blue"[selected]="selectedColor === 'blue'">Blue</option>
                <option value="purple" [selected]="selectedColor === 'purple'">Puple</option> -->
              <!-- </select> -->
            </div>
        </div>
        
    </div>
</div>

