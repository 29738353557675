

export class Constant {
   static FULLURL_KEY = 'fullUrl';
   static FULLUSERNAME_KEY = 'fullUsername';
   static FULLUSERID_KEY = 'fullUserId';
   static FULLPASSWORD_KEY = 'fullPassword';

   static XSTREAMURLs_KEY = 'XUrls';
   static XSTREAMURL_KEY = 'XUrl';
   static XSTREAMUSERNAME_KEY = 'XUsername';
   static XSTREAMPASSWORD_KEY = 'XPassword';
   // static XSTREAMUSERID_KEY = 'XUserId';
   static XTOKEN = 'Xtoken';

   static APP_BACKGROUND = 'appBackground';
   static APP_LOGO = 'appLogo';

   static SERIES_FAV = 'series-Fav';
   static MOVIES_FAV = 'movies-Fav';
   static PLAYLIST_FAV = 'playlist-Fav';
   static LIVETV_FAV = 'livetv-Fav';

   static ISREMEMBER_XKEY = 'isXRemember';
   static ISREMEMBER_FULLKEY = 'isFULLRemember';

   static PLAYLIST = 'playList';

}



