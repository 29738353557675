import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MovieService } from '../../services/movie.service';
import {HttpClient} from '@angular/common/http'
import { SeriesService } from '../../services/series.service';
import { UtilService } from '../../services/util.service';
import { map } from 'rxjs/operators';

// declare var jwplayer: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  isLoading: boolean = false;
  trendshowList: any;
  movieShowList:any;
  HomeCardData:any=[]
  
  slideConfig = {
    "slidesToShow": 7, "slidesToScroll": 7, responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  categories: any;
  selectedcategory: any;

  isLocalData: any;
  //  jwplayer: any;


  constructor(
    private seriesService: SeriesService,
    private utilService: UtilService,
    private router: Router,
    private movieservice: MovieService,
    private http:HttpClient
  ) {
  }
  // /trendshowList
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.getAllMovie();
    this.getSeriesCategory();
    // this.getHomeData()
  }

  getHomeData()
{
  this.utilService.showSpinner()
  this.http.get('assets/homeCard/home.json').subscribe(res=>{
    this.utilService.hideSpinner()
   this.HomeCardData=res
    console.log(this.HomeCardData);
    
  })
}
  getSeriesCategory() {
    // this.utilService.showSpinner()
    this.isLoading = true;
    this.seriesService.getSeriesCategories().subscribe((res: any) => {
    // this.utilService.hideSpinner()

      this.isLoading = false;
      this.categories = res;
      if (this.categories.length > 0) {
        this.categories.map((ele: any, index) => {
          if (index == 0) {
            this.updateCategory(ele.category_id)
          }
        });
      }
    }, err => {
      this.isLoading = false;
      this.utilService.showErrorCall(err);
    });
  }

  updateCategory(id) {
    // this.isLoading = true;
    // this.utilService.showSpinner()
    this.seriesService.selectedCategoryWiseSeriesList(id).subscribe((res: any) => {
    // this.utilService.hideSpinner()
      // this.isLoading = false;
      if (res) {
        this.selectedcategory = res;
      }
    }, err => {
      this.isLoading = false;
      this.utilService.showErrorCall(err);
    });

  }

  getAllMovie() {
    this.isLoading = true;
    this.utilService.showSpinner()
    this.movieservice.getMovieStreamList().subscribe((res: any) => {
      this.utilService.hideSpinner()
      this.isLoading = false;
      this.movieShowList = res;
      if(this.movieShowList.user_info.auth === 0){
        this.utilService.showErrorToast("Something Went Wrong!")
       this.router.navigate(['/Qr-login'])
       localStorage.clear()
      }
    }, err => {
    this.utilService.hideSpinner()
    this.isLoading = false;
      this.utilService.showErrorCall(err);
    });

  }



    /////////////////////////
  // playMovie(movieId) {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['/movies/watch/' + movieId])
  //   );
  //   window.open(url, '_blank');
  // }
  // playMovie(id: any, extension: any) {
  //   let stream_Url = this.movieService.getStremMovieUrl(id, extension);
  //   console.log(stream_Url);

  //   const player = jwplayer("myElement").setup({
  //     "playlist": [{
  //       // "file": "http://techslides.com/demos/sample-videos/small.mp4"
  //       "file": stream_Url
  //     }]
  //   });
  // }


}
