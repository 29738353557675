import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthentictionService } from '../../services/authentiction.service';
import { UtilService } from '../../services/util.service';
import { Constant } from '../../services/data';
import { AppInitilizeService } from 'src/app/app-initializer.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-playlist',
  templateUrl: './add-playlist.component.html',
  styleUrls: ['./add-playlist.component.css']
})

export class AddPlaylistComponent implements OnInit {
  @ViewChild('ngOtpInput', { static: false }) ngOtpInputRef: any | undefined;
  public isLoading: boolean = false;
  public hidelogin:boolean =true
  public show: boolean = false;
  public user: any = {
    username: '',
    otp: '',
    rememberMe: false,
  };
  playlistData:any=[
    {
      id:1,
      name:"Load M3U From URL",
      login_m3u:true
    },{
      id: 2,
      name: "Load Xstream From URL",
      login_xtream:true
    }
   
  ]

  loginDetails:any =[{
    
    id:1,
    name:"User ID & Password",
    login_id_pin:true
  },{
    id: 2,
    name: "Login with code",
    login_code_universal:true
  
}]
// deviceActivation:any=[{
//   id:1,
//   name:"Load M3U From URL",
//   login_activate:false
// },{
//   id: 2,
//   name: "Load Xstream From URL",
//   login_mac_key:false
// }]
public passwordTextType: boolean;
  public config: any = {
    length: 4, allowNumbersOnly: true, isPasswordInput: true, disableAutoFocus: true, inputStyles: {
      'margin': '0px 8px', 'background': '#000000',
      'color': 'white', 'font-size': '32px', 'outline': 'none'
    }
  };

  toggleOtp() {
    this.ngOtpInputRef.config.isPasswordInput = this.show;
    (this.show) ? (this.ngOtpInputRef.inputType = 'tel') : (this.ngOtpInputRef.inputType = 'password');
    // this.cdRef.detectChanges();
  }
  
 
  
  allPlayData:any=[]
  constructor(
    private http:HttpClient,      private router: Router, private authService: AuthentictionService, private utilService: UtilService, private changed: ChangeDetectorRef ,private appInitService:AppInitilizeService) {
  }

  ngOnInit(): void {
  this.newPlayList()
  }

  newPlayList(){
    // this.http.get('https://cors.b-cdn.net/https://endpoint.purpletv.app/purplesmarttv/samsung/v1.json').subscribe(res=>{
      this.http.get('https://cors.b-cdn.net/https://endpoint.purpletv.app/purpleweb/main.json').subscribe(res=>{
      this.loginDetails=res
      this.loginDetails.login_id_pin=this.loginDetails.login_id_pin
      this.loginDetails.login_code_universal=this.loginDetails.login_code_universal
      this.playlistData.login_xtream=this.loginDetails.login_xtream
      console.log(this.playlistData,'xtreame login');
      
      this.playlistData.login_m3u=this.loginDetails.login_m3u
      // this.deviceActivation.login_activate=this.loginDetails.login_activate
      // this.deviceActivation.login_mac_key=this.loginDetails.login_mac_key
      // https://cors.b-cdn.net/https://endpoint.purpletv.app/purplesmarttv/samsung/v1.json
      // https://cors.b-cdn.net/https://endpoint.purpletv.app/purpleweb/main.json
    })
  }


  // getPlayList(){
  // this.allPlayData=this.appInitService.getAppConfig().then(
  //   value =>{
  //     value = Object.values(value).map((item)=>item)
  //     console.log(value)
      // this.playlistData.isActive=value
      // this.loginDetails.login_id_pin=value
      // this.deviceActivation.isActive=value
    // }
  // ).catch(error=>{
  // })
// }


  fillForm() {
    if (this.utilService.getValueCookie(Constant.ISREMEMBER_FULLKEY)) {
      this.user.username = this.utilService.getValueCookie(Constant.FULLUSERNAME_KEY);
      this.user.otp = this.utilService.getValueCookie(Constant.FULLPASSWORD_KEY);
      this.ngOtpInputRef.setValue(this.user.otp);
      this.changed.detectChanges();
    }
  }
  ngAfterViewInit(): void {
    this.fillForm();
  }
  checkCodeLogin() {
    let xtoken = localStorage.getItem('Xtoken');

    if (xtoken) {
      this.router.navigate(['/server-login']);

    } else {
      this.router.navigate(['/code']);
    }

  }
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }
  onSubmit(form: NgForm) {
    if (form.valid && this.user.otp.length > 3) {
      this.isLoading = true;
    this.utilService.showSpinner()
      
      let param: any = {
        userid: this.user.username,
        pwd: this.user.otp
      };

      this.authService.login(param).subscribe((res: any) => {
      this.utilService.hideSpinner()
        
        this.isLoading = false;
        if (res.http_code === 200) // userid
        {
          console.log("login data",res);


          if (res.xstream || res.m3u) {
            if (res.xstream)
              this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, res.xstream[0].userid);
            else
              this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, res.m3u[0].userid);
          }
          if (this.user.rememberMe) {
            this.utilService.clearCookieStorage();
            this.utilService.saveValueCookie(Constant.ISREMEMBER_FULLKEY, true);
            this.utilService.saveValueCookie(Constant.FULLUSERNAME_KEY, this.user.username);
            this.utilService.saveValueCookie(Constant.FULLPASSWORD_KEY, this.user.otp);
          }
          else {
            this.utilService.clearCookieStorage();
          }

          if (!res.xstream && !res.m3u) {
            this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, this.user.username);
          }

          this.router.navigate(['playlist'], { queryParams: { isRemember: this.user.rememberMe } });
          this.utilService.showErrorSuccess('', "Login Succesfully.");


          // let uid = res.xstream[0].userid;
          // let uname = res.xstream[0].username;
          // let pin = res.xstream[0].pwd;
          // let url = res.xstream[0].url;
          // this.utilService.removeXsreamStorage();



          // this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, uid);
          // this.utilService.saveValueStorage(Constant.FULLUSERNAME_KEY, uname);
          // this.utilService.saveValueStorage(Constant.FULLPASSWORD_KEY, pin);
          // this.utilService.saveValueStorage(Constant.FULLURL_KEY, url);
          // this.router.navigate(['/home']);
          // }
          // else {
          //  // this.utilService.saveValueStorage(Constant.FULLUSERID_KEY, this.user.username);
          //   this.router.navigate(['playlist']);
          // }
          // this.router.navigate(['playlist']);
          // this.utilService.showErrorSuccess('', "Login Succesfully.");

        }
        if (res.error) {
          this.utilService.showErrorInfo(res.status);
        }
      }, err => {
        this.isLoading = false;
        this.utilService.hideSpinner()

        this.utilService.showErrorCall(err);
      });

    }
  }

  otpChange(value: any) {
    this.user.otp = value;
  }
  rememberMe(event: any) {
    this.user.rememberMe = event.target.checked;
  }

}
