<form #userForm="ngForm" (ngSubmit)=onSubmit(userForm) class="m-auto auth auth-background">
  <div class="container-fluid">
    <div class="row pi-register h-100 justify-content-center align-items-center">
      <div class="col-12 text-center">
        <div class="header-w3l">
          <div class="logo" style="position: relative;">
            <h3><a href="index.html">{{ 'COMMON.TITLE' | translate }}</a></h3>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <h3 class="login-label">{{ 'COMMON.SIGNUP' | translate }}</h3>
      </div>
      <div class="col col-sm-6 col-md-7 col-xl-3  mt-4">
        <label for="" class="d-flex justify-content-start"> {{'LABEL.USER_NAME' | translate}}</label>

        <div class="form-group mb-2">
          <i class="fa fa-user text-white input-icon" aria-hidden="true"></i>
          <input type="text" [(ngModel)]="user.username" class="form-control in  border-0" name="username"
            #username="ngModel" placeholder="{{ 'PLACEHOLDER.USERNAME' | translate }}" (keypress)="preventSpace($event)" maxlength="120" required autofocus>
          </div>
          <div *ngIf="userForm.submitted && username.invalid" class="text-left pt-1">
            <p class="error-msg mb-0">{{ 'FORMERROR.USERNAME' | translate }}</p>
          </div>

        <label for="" class="d-flex justify-content-start mt-4">{{'LABEL.PASSWORD' | translate}}</label>
        <div class="form-group mb-3 mt-2">
          <i class="fa fa-key text-white input-icon" aria-hidden="true"></i>
          <input [type]="type" [(ngModel)]="user.otp" class="form-control-pass in" name="password" #password="ngModel"
            placeholder="{{ 'PLACEHOLDER.PASSWORD' | translate }}" (keypress)="NumOnly($event)" maxlength="4" required autofocus>
            <div class="input-append cursor-pointer text-white" (click)="hideShowPassword()">
              <i class="fas fa-eye " *ngIf="type == 'password'"></i>
              <i class="fas fa-eye-slash " *ngIf="type !== 'password'"></i>
            </div> 
          </div>
          <div *ngIf="userForm.submitted && password.invalid" class="text-left pt-0">
            <p class="error-msg mb-0">{{ 'FORMERROR.PINNUM' | translate }}</p>
          </div>

        <div class="form-group mb-5 d-none">
          <div class="d-flex  ml-1 justify-content-center align-items-center">
            <h6 class="d-flex-center">{{ 'COMMON.ENTPIN' | translate }}</h6>
            <ng-otp-input (onInputChange)="otpChange($event)" [config]="config" class="ml-3" name="otp" required
              #ngOtpInput>
            </ng-otp-input>
            <div class="mt-2 ml-3">
              <i class="fas text-white fs-28 " (click)="show=!show;toggleOtp()"
                [ngClass]="show ?'fa-eye-slash':'fa-eye'"></i>
            </div>
          </div>
          <div *ngIf="userForm.submitted" class="float-left pt-1">
            <p class="error-msg" *ngIf="!user.otp">{{ 'FORMERROR.PINNUM' | translate }}</p>
            <p class="error-msg" *ngIf="user.otp && user.otp.length<4">{{ 'FORMERROR.VALIDPIN' | translate }}</p>
          </div>
        </div>

      </div>
      <div class="col-12 pb-4 pt-4 text-center ">
        <button class="login-btn mr-4" type="submit" [disabled]="isLoading">
          {{ 'COMMON.SIGNUP' | translate }}
          <div *ngIf="isLoading" class="spinner-border spinner-border-sm fs-14" style="vertical-align:middle;"
            role="status">
            <span class="sr-only">{{ 'COMMON.LOADING' | translate }}</span>
          </div>
        </button>
        <button class="login-code" type="button" (click)="gotoCode()" [disabled]="isLoading">
          {{ 'Login With Code' | translate }}
        </button>
      </div>
      <div class="col-12 pb-3 text-center">
        <h5 class="mb-0 mt-4 text-size">{{ 'SIGNUP.ALRACC' | translate}} <a class="ml-1 text-purple"
            routerLink="/login">{{ 'COMMON.LOGIN' | translate }}</a></h5>
      </div>
    </div>
  </div>
</form>
