<!-- <div class="container-fluid"> -->
<h4>{{'COMMON.SEARCH_RESULTS' | translate }}</h4>
<div [hidden]="isLoading" class="col-12 text-center mt-5" *ngIf="!totalMovies">
  <h5>{{'COMMON.DATA_NOT_FOUND' | translate}}</h5>
</div>
<virtual-scroller #scroll [items]="searchedLiveTv" *ngIf="!isSearching">
  <ng-container *ngFor="let item of scroll.viewPortItems">

    <div class="row" *ngIf="item.data?.length > 0">

      <div class="col-12 d-flex mb-1">
        <h6 class="mr-auto">{{ item.category_name }} </h6>
        <a class="mr-2 text-purple" routerLink="/livetv" [queryParams]="{ category: item.category_id}">View All
          <i class="fas fa-angle-right"></i></a>
      </div>



      <div class="col-12 " style=" height: 130px; width: 100%;" *ngIf="item.data?.length > 0">
        <!-- <div class="col-12 mx-3" *ngIf="item && item.data?.length > 0"> -->
        <div class="mr-5 pr-5 ml-4">
          <div class="mr-1">
            <ngx-slick-carousel class="carousel custom-slider " #slickModal="slick-carousel" [config]="slideConfig">
              <div ngxSlickItem class="slide" *ngFor="let channel of item.data ; index as i"
                style="position: relative;">
                <div class="pr-2 mb-2">
                  <div class="mr-3 over1 d-flex">
                    <div class="px-1 py-2">
                      <img [src]="channel.stream_icon" width="70px" height="40px" class="mb-0" loading=lazy>
                    </div>
                    <div class="p-2 py-3 search-title cursor-pointer">
                      {{channel.name}}
                    </div>
                    <div class="d-flex">
                      <span class="fav-icon1 text-purple" *ngIf="channel.isFavourit"> <i
                          class="fas fa-heart  cursor-pointer"></i>
                      </span>
                      <i class="fas fa-play-circle fs-18 text-purple ml-auto mr-1 cursor-pointer" routerLink="/livetv"
                        [queryParams]="{ category: item.category_id,stream:channel.stream_id}"></i>
                    </div>
                  </div>
                </div>

              </div>
              <!-- <div ngxSlickItem class="over1 d-flex mb-3 mx-2 slide"
              *ngFor="let channel of item.data | slice:0:15; index as i">
              <div class="p-2">
                <img [src]="channel.stream_icon" width="80px" height="40px" class="mb-0">
              </div>
              <div class="p-2 py-3  cursor-pointer">
                {{channel.name | slice: 0:18}}
              </div>
            </div> -->
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
</virtual-scroller>
<!-- </div> -->
